"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.timeline = [{
      timestamp: '2019/4/20',
      title: 'Update Github template',
      content: 'Armour committed 2019/4/20 20:46'
    }, {
      timestamp: '2019/4/21',
      title: 'Update Github template',
      content: 'Armour committed 2019/4/21 20:46'
    }, {
      timestamp: '2019/4/22',
      title: 'Build Template',
      content: 'Armour committed 2019/4/22 20:46'
    }, {
      timestamp: '2019/4/23',
      title: 'Release New Version',
      content: 'Armour committed 2019/4/23 20:46'
    }];
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Timeline'
})], default_1);
var _default = exports.default = default_1;