"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSubMenu = exports.getSubMenus = exports.getSubMenuById = exports.fileTransferHeaders = exports.deleteSubMenu = exports.defaultSubMenuData = exports.createSubMenu = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultSubMenuData = exports.defaultSubMenuData = {
  id: 0,
  enabled: true,
  menuId: null,
  menu: {
    id: undefined
  },
  label: '',
  linkType: '',
  link: '',
  slug: '',
  priority: 0,
  categoryId: null,
  category: {
    id: undefined
  },
  productId: null,
  product: {
    id: undefined
  }
};
const getSubMenus = params => (0, _request.default)({
  url: '/sub-menus',
  method: 'get',
  params
});
exports.getSubMenus = getSubMenus;
const getSubMenuById = id => (0, _request.default)({
  url: `/sub-menus/${id}`,
  method: 'get'
});
exports.getSubMenuById = getSubMenuById;
const updateSubMenu = (id, data) => (0, _request.default)({
  url: `/sub-menus/${id}`,
  method: 'patch',
  data
});
exports.updateSubMenu = updateSubMenu;
const deleteSubMenu = id => (0, _request.default)({
  url: `/sub-menus/${id}`,
  method: 'delete'
});
exports.deleteSubMenu = deleteSubMenu;
const createSubMenu = data => (0, _request.default)({
  url: '/sub-menus/',
  method: 'post',
  data
});
exports.createSubMenu = createSubMenu;