"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSize = exports.getSizes = exports.getSizeById = exports.fileTransferHeaders = exports.deletesize = exports.defaultSizeData = exports.createSize = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultSizeData = exports.defaultSizeData = {
  id: 0,
  enabled: true,
  name: '',
  priority: 0,
  startValue: 0,
  endValue: 0,
  colour: [],
  clarity: [],
  cut: [],
  polish: [],
  symmetry: [],
  fluorescence: []
};
const getSizes = params => (0, _request.default)({
  url: '/sizes',
  method: 'get',
  params
});
exports.getSizes = getSizes;
const getSizeById = id => (0, _request.default)({
  url: `/sizes/${id}`,
  method: 'get'
});
exports.getSizeById = getSizeById;
const updateSize = (id, data) => (0, _request.default)({
  url: `/sizes/${id}`,
  method: 'patch',
  data
});
exports.updateSize = updateSize;
const deletesize = id => (0, _request.default)({
  url: `/sizes/${id}`,
  method: 'delete'
});
exports.deletesize = deletesize;
const createSize = data => (0, _request.default)({
  url: '/sizes/',
  method: 'post',
  data
});
exports.createSize = createSize;