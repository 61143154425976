"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'building': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1 22h2V0h18v22h2v2H1v-2zm7-3v4h3v-4H8zm5 0v4h3v-4h-3zm-6-5H5v2h2v-2zm8 0h-2v2h2v-2zm-4 0H9v2h2v-2zm8 0h-2v2h2v-2zM7 10H5v2h2v-2zm8 0h-2v2h2v-2zm-4 0H9v2h2v-2zm8 0h-2v2h2v-2zM7 6H5v2h2V6zm8 0h-2v2h2V6zm-4 0H9v2h2V6zm8 0h-2v2h2V6zM7 2H5v2h2V2zm8 0h-2v2h2V2zm-4 0H9v2h2V2zm8 0h-2v2h2V2z"/>'
  }
});