"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ordersSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const ordersSubMenu = exports.ordersSubMenu = [{
  path: '/order/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/list.vue'))),
  name: 'Orders',
  meta: {
    title: 'orderManagement.orders.title',
    noCache: true,
    icon: 'Order'
  }
}, {
  path: '/order/edit/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'))),
  name: 'orderManagement.orders.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.orders.edit',
    noCache: true,
    activeMenu: '/order/list',
    hidden: true
  }
}, {
  path: '/order/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'))),
  name: 'orderManagement.orders.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.orders.add',
    noCache: true,
    activeMenu: '/order/list',
    hidden: true
  }
}, {
  path: '/order/view/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/view.vue'))),
  name: 'orderManagement.orders.view',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.orders.view',
    noCache: true,
    activeMenu: '/order/list',
    hidden: true
  }
}];