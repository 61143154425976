import { render, staticRenderFns } from "./SellerDetails.vue?vue&type=template&id=6b58bcdf&scoped=true"
import script from "./SellerDetails.vue?vue&type=script&lang=ts"
export * from "./SellerDetails.vue?vue&type=script&lang=ts"
import style0 from "./SellerDetails.vue?vue&type=style&index=0&id=6b58bcdf&lang=scss"
import style1 from "./SellerDetails.vue?vue&type=style&index=1&id=6b58bcdf&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b58bcdf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yaambs/Desktop/SGL-Admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b58bcdf')) {
      api.createRecord('6b58bcdf', component.options)
    } else {
      api.reload('6b58bcdf', component.options)
    }
    module.hot.accept("./SellerDetails.vue?vue&type=template&id=6b58bcdf&scoped=true", function () {
      api.rerender('6b58bcdf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sellers/components/SellerDetails.vue"
export default component.exports