"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _colours = require("@/api/colours");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _colours.defaultColourData);
    this.loading = false;
    this.image = [];
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 1,
        max: 150,
        message: 'Length should be greter than or equal to 1',
        trigger: 'blur'
      }],
      image: [{
        required: true,
        message: 'Please upload image',
        trigger: 'blur'
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _colours.defaultColourData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _colours.getColourById)(id);
      this.formData = data;
      // Just for test
      const title = 'Edit Colour';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (error) {
      // this.$message.error(error.message || error)
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title} - ${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  imageUploaded(file) {
    this.formData.image = file.response;
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _colours.updateColour)(this.formData.id, this.formData);
      } else {
        await (0, _colours.createColour)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'Colour saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/colour/list');
    } catch (error) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ColourDetail',
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;