"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMenu = exports.getMenus = exports.getMenuById = exports.fileTransferHeaders = exports.deleteMenu = exports.defaultMenuData = exports.createMenu = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultMenuData = exports.defaultMenuData = {
  id: 0,
  enabled: true,
  label: '',
  linkType: '',
  link: '',
  slug: '',
  priority: 0,
  categoryId: null,
  category: {
    id: undefined
  },
  productId: null,
  product: {
    id: undefined
  },
  visibility: []
};
const getMenus = params => (0, _request.default)({
  url: '/menus',
  method: 'get',
  params
});
exports.getMenus = getMenus;
const getMenuById = id => (0, _request.default)({
  url: `/menus/${id}`,
  method: 'get'
});
exports.getMenuById = getMenuById;
const updateMenu = (id, data) => (0, _request.default)({
  url: `/menus/${id}`,
  method: 'patch',
  data
});
exports.updateMenu = updateMenu;
const deleteMenu = id => (0, _request.default)({
  url: `/menus/${id}`,
  method: 'delete'
});
exports.deleteMenu = deleteMenu;
const createMenu = data => (0, _request.default)({
  url: '/menus/',
  method: 'post',
  data
});
exports.createMenu = createMenu;