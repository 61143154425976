import { render, staticRenderFns } from "./ProductInventoryTab.vue?vue&type=template&id=1c81f270&scoped=true"
import script from "./ProductInventoryTab.vue?vue&type=script&lang=ts"
export * from "./ProductInventoryTab.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c81f270",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yaambs/Desktop/SGL-Admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c81f270')) {
      api.createRecord('1c81f270', component.options)
    } else {
      api.reload('1c81f270', component.options)
    }
    module.hot.accept("./ProductInventoryTab.vue?vue&type=template&id=1c81f270&scoped=true", function () {
      api.rerender('1c81f270', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/ProductInventoryTab.vue"
export default component.exports