"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-descriptions", {
    attrs: {
      border: "",
      size: "mini",
      column: 2
    }
  }, [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Full Name ")]), _vm._v(" " + _vm._s(_vm.data.fullName) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-key"
  }), _vm._v(" Role ")]), _vm._v(" " + _vm._s(_vm.data.adminsRole.name) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-message"
  }), _vm._v(" Email Address ")]), _vm._v(" " + _vm._s(_vm.data.emailAddress) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-phone"
  }), _vm._v(" Mobile Number ")]), _vm._v(" " + _vm._s(_vm.data.mobileNumber) + " ")], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;