"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sizesMarkupsSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const sizesMarkupsSubMenu = exports.sizesMarkupsSubMenu = [{
  path: '/sizes-markup/list/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/sizes-markup/list.vue'))),
  name: 'Sizes Markup',
  meta: {
    title: 'productManagement.sizesMarkup.title',
    noCache: true,
    hidden: true,
    icon: 'sizes-markup'
  }
}, {
  path: '/sizes-markup/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/sizes-markup/manage.vue'))),
  name: 'productManagement.sizesMarkup.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.sizesMarkup.manage',
    noCache: true,
    activeMenu: '/sizes-markup/list',
    hidden: true
  }
}, {
  path: '/sizes-markup/add/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/sizes-markup/manage.vue'))),
  name: 'productManagement.sizesMarkup.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.sizesMarkup.manage',
    noCache: true,
    activeMenu: '/sizes-markup/list',
    hidden: true
  }
}];