"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'diamond-fluorescence': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M381.3 216.2c-1.4-2.6-4.1-4.3-7.1-4.3H137.8c-3 0-5.7 1.6-7.1 4.3l-33.8 63.5c-1.5 2.8-1.2 6.1.7 8.6L239.8 477h-23.4c-4.5 0-8.1 3.6-8.1 8.1s3.6 8.1 8.1 8.1h79.1c4.5 0 8.1-3.6 8.1-8.1s-3.6-8.1-8.1-8.1h-23.4l142.3-188.6c1.9-2.5 2.2-5.9.7-8.6l-33.8-63.6zm-182.1 59.3L156 228.1h200l-43.2 47.4H199.2zm106.3 16.1L256 457l-49.5-165.4h99zm-115.9 0l45.8 152.8-115.3-152.8h69.5zm132.8 0h69.5L276.6 444.4l45.8-152.8zm72.2-16.1h-60l37.9-41.6 22.1 41.6zm-255.1-41.6l37.9 41.6h-60l22.1-41.6zm108.4-64.1V27c0-4.4 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v142.8c0 4.4-3.6 8.1-8.1 8.1-4.5-.1-8.1-3.7-8.1-8.1zm255.8-71.3l-101 101c-1.6 1.6-3.6 2.4-5.7 2.4s-4.1-.8-5.7-2.4a8.15 8.15 0 010-11.4l101-101c3.1-3.1 8.3-3.1 11.4 0 3.2 3.1 3.2 8.2 0 11.4h0zm-394.5 101l-101-101c-3.1-3.1-3.1-8.2 0-11.4 3.1-3.1 8.3-3.1 11.4 0l101 101c3.1 3.1 3.1 8.2 0 11.4-1.6 1.6-3.6 2.4-5.7 2.4-2-.1-4.1-.9-5.7-2.4h0zm209.6-33.3l15.2-56.7c1.2-4.3 5.6-6.9 9.9-5.7 4.3 1.1 6.9 5.6 5.7 9.9l-15.2 56.7c-1 3.6-4.2 6-7.8 6-.7 0-1.4-.1-2.1-.3-4.3-1.2-6.9-5.6-5.7-9.9h0zm-156.4-52.5c-1.1-4.3 1.4-8.7 5.7-9.9 4.3-1.2 8.7 1.4 9.9 5.7l15.2 56.7c1.1 4.3-1.4 8.7-5.7 9.9-.7.2-1.4.3-2.1.3-3.6 0-6.8-2.4-7.8-6l-15.2-56.7zm335.5 96c2.2 3.9.9 8.8-3 11L444.2 250c-1.3.7-2.7 1.1-4 1.1-2.8 0-5.5-1.4-7-4-2.2-3.9-.9-8.8 3-11l50.8-29.3c3.8-2.3 8.7-1 10.9 2.9h0zM78.8 247c-1.5 2.6-4.2 4-7 4-1.4 0-2.7-.3-4-1.1L17 220.7c-3.9-2.2-5.2-7.2-2.9-11 2.2-3.9 7.2-5.2 11-2.9L75.9 236c3.8 2.3 5.1 7.2 2.9 11h0z" _stroke="#000" stroke-width="10" stroke-miterlimit="10"/>'
  }
});