"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
var _attributes = require("@/api/attributes");
var _index2 = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.loading = false;
    this.rules = {};
    this.attributesAndValues = [];
    this.productsVariations = [];
    this.showDelete = false;
    this.previousPriceUpdatedFlag = false;
    this.bulkStepper = 10;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  activated() {
    this.getAttributesAndValues();
    this.productsVariations = this.formData.productsVariation;
    if (this.productsVariations && this.productsVariations.length) {
      this.productsVariations.sort(function (a, b) {
        return a.id - b.id;
      });
    }
  }
  handleProductTypeChange() {
    this.$emit('updated', this.formData);
  }
  async getAttributesAndValues() {
    try {
      let selectedAttributeIds = [];
      selectedAttributeIds = this.formData.attribute.filter(attribute => attribute).map(attribute => attribute.id);
      selectedAttributeIds = await (0, _attributes.getAttributesTree)({
        attributes: JSON.stringify(selectedAttributeIds)
      });
      // add selectedValueId
      selectedAttributeIds.forEach(attributesAndValue => {
        attributesAndValue.selectedValueId = null;
      });
      this.attributesAndValues = selectedAttributeIds;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async saveForm() {
    this.loading = true;
    try {
      // console.log(this.productsVariations);
      if (this.productsVariations && this.productsVariations.length) {
        this.productsVariations.forEach((variation, index) => {
          if (variation.manageStock && variation.stockQuantity > 0) {
            variation.stockStatus = 'In Stock';
          }
          if (variation.manageStock && variation.stockQuantity <= 0) {
            variation.stockStatus = 'Out Of Stock';
          }
          // calculate discount percentage
          let discountPercentage = 0;
          let discountAmount = 0;
          if (variation.salePrice > 0) {
            discountAmount = variation.unitPrice - variation.salePrice;
            discountPercentage = discountAmount / variation.unitPrice * 100;
            variation.discountPercentage = discountPercentage.toFixed(2);
            variation.discountAmount = discountAmount;
          } else {
            variation.discountPercentage = discountPercentage;
            variation.discountAmount = discountAmount;
          }
        });
      }
      const variation = await (0, _products.updateProductVariations)(this.formData.id, this.productsVariations);
      this.$notify({
        title: 'Success',
        message: 'Variations saved',
        type: 'success',
        duration: 2000
      });
      this.$emit('added', true);
      this.$emit('reload', {});
    } catch (err) {
      this.$notify.error({
        title: 'Failed to save',
        message: err + ''
      });
    } finally {
      this.loading = false;
    }
  }
  fileUploaded(file) {}
  resetForm() {
    this.$refs.formData.resetFields();
  }
  handleAttribute(attributesValueId, attributeId, index) {
    this.attributesAndValues[index].selectedValueId = attributesValueId;
  }
  addVariation() {
    const productId = this.formData.id;
    const attributesAndValues = [];
    this.attributesAndValues.forEach(attributeValue => {
      console.log(attributeValue);
      if (attributeValue.selectedValueId) {
        attributesAndValues.push({
          productId: productId,
          attributeId: attributeValue.id,
          attributesValueId: attributeValue.selectedValueId
        });
      }
    });
    if (attributesAndValues.length !== this.attributesAndValues.length) {
      this.$message({
        type: 'error',
        message: 'Select attributes'
      });
      return;
    }
    const addedIndex = this.productsVariations.push({
      enabled: true,
      name: '',
      image: [],
      manageStock: this.formData.manageStock,
      productId: productId,
      productsVariationsAttribute: attributesAndValues,
      mrp: 0,
      unitPrice: 0,
      salePrice: 0,
      sku: '',
      stockQuantity: 0,
      stockStatus: this.formData.stockStatus
    }) - 1;
    this.productsVariations[addedIndex].name = this.getVariationTitle(addedIndex);
  }
  getVariationTitle(index) {
    const variationAttributesValueIds = this.productsVariations[index].productsVariationsAttribute.map(variationAttributesValue => variationAttributesValue.attributesValueId);
    const title = [];
    this.attributesAndValues.forEach(attribute => {
      attribute.attributesValues.forEach(attributesValue => {
        if (attributesValue.id === 1) {
          return true;
        }
        if (variationAttributesValueIds.indexOf(attributesValue.id) > -1) {
          title.push(attributesValue.name);
        }
      });
    });
    return title.join(' ');
  }
  editName(event, index) {
    event.preventDefault();
    this.productsVariations[index].edit = true;
  }
  saveName(event, index) {
    event.preventDefault();
    this.productsVariations[index].edit = false;
  }
  async handleDelete(event, index) {
    event.preventDefault();
    event.stopPropagation();
    const variation = this.productsVariations[index];
    try {
      await this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
        name: variation.name
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      });
      if (variation.id) {
        await (0, _products.deleteProductVariation)(variation.id);
      }
      this.productsVariations.splice(index, 1);
    } catch (error) {
      this.$message({
        type: 'info',
        message: this.$tc('table.deleteCanceled')
      });
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductVariationTab',
  components: {
    HelpText: _index.default,
    FileUpload: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;