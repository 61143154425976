"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.topProducts = exports.topPincodes = exports.topPerformingStates = exports.topPerformingCategories = exports.orderedStonesCount = exports.orderSalesAndCount = exports.orderRevenueByDate = exports.orderRevenue = exports.orderExport = exports.orderCount = exports.incartStonesCount = exports.getOrders = exports.getOrderById = exports.fileTransferHeaders = exports.fetchOrderById = exports.deleteOrdersProduct = exports.deleteOrder = exports.defaultOrderData = exports.createOrder = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultOrderData = exports.defaultOrderData = {
  id: 0,
  orderReference: '',
  customerIP: '',
  status: '',
  orderedBy: '',
  comments: '',
  total: 0,
  shippingCharges: 0,
  shippingId: 0,
  taxedAmount: 0,
  discountAmount: 0,
  couponId: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: '',
  lastLocation: '',
  platform: '',
  version: '',
  uuid: '',
  model: '',
  deviceId: '',
  device: '',
  OSVersion: '',
  uniqueID: '',
  manufacturer: '',
  appVersion: '',
  userId: 0,
  user: {
    id: null
  },
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: 0,
  pincode: {
    id: null
  },
  purchaseOrderNumber: undefined,
  purchaseOrderDate: undefined,
  purchaseOrderFile: undefined,
  awbDetails: undefined,
  proformaInvoiceNumber: undefined,
  proformaInvoiceDate: undefined,
  proformaInvoiceFile: undefined,
  orderPickupType: undefined,
  certificateNumber: '',
  courierName: undefined,
  awbNumber: undefined,
  outForDeliveryTimestamp: undefined,
  deliveredTimestamp: undefined,
  paymentStatus: undefined,
  inProcessTimestamp: undefined,
  invoiceFile: ''
};
const getOrders = params => (0, _request.default)({
  url: '/orders',
  method: 'get',
  params
});
exports.getOrders = getOrders;
const getOrderById = id => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'get'
});
exports.getOrderById = getOrderById;
const updateOrder = (id, data) => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'patch',
  data
});
exports.updateOrder = updateOrder;
const deleteOrder = id => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'delete'
});
exports.deleteOrder = deleteOrder;
const createOrder = data => (0, _request.default)({
  url: '/orders/',
  method: 'post',
  data
});
exports.createOrder = createOrder;
const orderCount = params => (0, _request.default)({
  url: '/orders/order-count',
  method: 'get',
  params
});
exports.orderCount = orderCount;
const orderExport = data => (0, _request.default)({
  url: '/orders/export',
  method: 'post',
  data
});
exports.orderExport = orderExport;
const orderRevenueByDate = data => (0, _request.default)({
  url: '/orders/revenue-by-date',
  method: 'post',
  data
});
exports.orderRevenueByDate = orderRevenueByDate;
const topProducts = params => (0, _request.default)({
  url: '/orders-products/top-product',
  method: 'get',
  params
});
exports.topProducts = topProducts;
const topPincodes = params => (0, _request.default)({
  url: '/orders/top-pincode/sale',
  method: 'get',
  params
});
exports.topPincodes = topPincodes;
const orderRevenue = data => (0, _request.default)({
  url: '/orders/revenue',
  method: 'post',
  data
});
exports.orderRevenue = orderRevenue;
const fetchOrderById = id => (0, _request.default)({
  url: `/orders/fetch/${id}`,
  method: 'get'
});
exports.fetchOrderById = fetchOrderById;
const topPerformingCategories = params => (0, _request.default)({
  url: '/orders-products/top-performing-categories',
  method: 'get',
  params
});
exports.topPerformingCategories = topPerformingCategories;
const topPerformingStates = params => (0, _request.default)({
  url: '/orders/top-state/sale',
  method: 'get',
  params
});
exports.topPerformingStates = topPerformingStates;
const orderedStonesCount = data => (0, _request.default)({
  url: '/orders-products/stones-order-count',
  method: 'post',
  data
});
exports.orderedStonesCount = orderedStonesCount;
const incartStonesCount = data => (0, _request.default)({
  url: '/orders-products/stones-in-cart-count',
  method: 'post',
  data
});
exports.incartStonesCount = incartStonesCount;
const orderSalesAndCount = params => (0, _request.default)({
  url: '/orders/sales-by-date',
  method: 'get',
  params
});
exports.orderSalesAndCount = orderSalesAndCount;
const deleteOrdersProduct = id => (0, _request.default)({
  url: `/orders-products/${id}`,
  method: 'delete'
});
exports.deleteOrdersProduct = deleteOrdersProduct;