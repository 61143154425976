"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const settingRouter = [
// {
//   path: '/setting/list',
//   component: () => import('@/views/setting/list.vue'),
//   name: 'settings',
//   meta: {
//     title: 'settingManagement.settings.title',
//     noCache: true,
//     icon: 'setting'
//   }
// },
{
  path: '/setting/info',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/setting/info.vue'))),
  name: 'settings',
  meta: {
    title: 'settingManagement.settings.title',
    noCache: true,
    icon: 'lock'
  }
}, {
  path: '/setting/edit/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/setting/manage.vue'))),
  name: 'settingManagement.settings.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'settingManagement.settings.edit',
    noCache: true,
    activeMenu: '/setting/list',
    hidden: true
  }
}];
var _default = exports.default = settingRouter;