"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStaticPage = exports.getStaticPages = exports.getStaticPageById = exports.deleteStaticPage = exports.defaultStaticPageData = exports.createStaticPage = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultStaticPageData = exports.defaultStaticPageData = {
  id: 0,
  enabled: true,
  title: '',
  slug: '',
  description: '',
  priority: 0,
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  canonicalTag: ''
};
const getStaticPages = params => (0, _request.default)({
  url: '/static-pages',
  method: 'get',
  params
});
exports.getStaticPages = getStaticPages;
const getStaticPageById = id => (0, _request.default)({
  url: `/static-pages/${id}`,
  method: 'get'
});
exports.getStaticPageById = getStaticPageById;
const updateStaticPage = (id, data) => (0, _request.default)({
  url: `/static-pages/${id}`,
  method: 'patch',
  data
});
exports.updateStaticPage = updateStaticPage;
const deleteStaticPage = id => (0, _request.default)({
  url: `/static-pages/${id}`,
  method: 'delete'
});
exports.deleteStaticPage = deleteStaticPage;
const createStaticPage = data => (0, _request.default)({
  url: '/static-pages',
  method: 'post',
  data
});
exports.createStaticPage = createStaticPage;