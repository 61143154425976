"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _banner = require("@/api/banner");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.role = _admin.AdminModule.roles;
    this.listQuery = {
      page: 1,
      limit: 10,
      enabled: undefined,
      sort: 'priority,ASC',
      filter: {
        enabled: 'eq'
      }
    };
    this.value = [];
    this.options = [{
      value: 'createdDate',
      label: 'Created Date'
    }];
    this.columns = {
      createdDate: false
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _banner.getBanners)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleColumn() {
    for (const c in this.columns) {
      this.columns[c] = this.value.includes(c);
    }
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc('table.deleteConfirmation', 0, {
        name: data.id
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$tc('table.cancel'),
        type: 'error'
      }).then(() => {
        (0, _banner.deleteBanner)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'BannerList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;