"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countriesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const countriesSubMenu = exports.countriesSubMenu = [{
  path: '/countries/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "countries-list" */'@/views/locations/countries/list.vue'))),
  name: 'Countries',
  meta: {
    title: 'locations.countries.title',
    noCache: true,
    icon: 'globe'
  }
}, {
  path: '/countries/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "countries-list" */'@/views/locations/countries/manage.vue'))),
  name: 'Edit Country',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.countries.edit',
    noCache: true,
    activeMenu: '/countries/list',
    hidden: true
  }
}, {
  path: '/countries/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/countries/manage.vue'))),
  name: 'Add Country',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.countries.add',
    noCache: true,
    activeMenu: '/countries/list',
    hidden: true
  }
}];