"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'hp': {
    width: 16,
    height: 16,
    viewBox: '0 0 300.145 300.145',
    data: '<path pid="0" d="M49.126 150.126c0-42.346 26.212-78.68 63.26-93.643l18.904-37.971C66.847 27.667 17.126 83.194 17.126 150.126c0 60.895 41.157 112.355 97.113 128.035l3.636-32.354c-39.923-13.47-68.749-51.272-68.749-95.681zM283.019 150.126c0-60.883-41.139-112.333-97.076-128.025l-3.718 32.33c39.946 13.457 68.794 51.27 68.794 95.695 0 42.099-25.907 78.255-62.613 93.379l-19.428 38.217c64.383-9.207 114.041-64.707 114.041-131.596z"/><path pid="1" d="M226.169 134.015c1.26-2.479 1.141-5.202-.314-7.572-1.454-2.371-4.036-3.316-6.818-3.316h-60.821L171.309 9.3c.446-3.859-1.946-7.857-5.672-8.963A8.14 8.14 0 00163.32 0c-2.954 0-5.746 1.681-7.121 4.442L73.946 169.718c-1.234 2.479-1.098 5.78.36 8.136 1.459 2.355 4.031 4.273 6.802 4.273h60.019L128.823 291.67c-.43 3.844 1.951 7.077 5.657 8.185.761.228 1.532.29 2.293.29a8.003 8.003 0 007.133-4.378l82.263-161.752z"/>'
  }
});