"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTransaction = exports.getTransactions = exports.getTransactionById = exports.fileTransferHeaders = exports.deleteTransaction = exports.defaultTransactionData = exports.createTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultTransactionData = exports.defaultTransactionData = {
  id: 0,
  txnid: '',
  mihpayid: '',
  status: '',
  unmappedstatus: '',
  cardCategory: '',
  amount: undefined,
  net_amount_debit: undefined,
  payment_source: '',
  orderId: undefined,
  order: undefined,
  orderReference: ''
};
const getTransactions = params => (0, _request.default)({
  url: '/transactions',
  method: 'get',
  params
});
exports.getTransactions = getTransactions;
const createTransaction = data => (0, _request.default)({
  url: '/transactions',
  method: 'post',
  data
});
exports.createTransaction = createTransaction;
const getTransactionById = id => (0, _request.default)({
  url: `/transactions/${id}`,
  method: 'get'
});
exports.getTransactionById = getTransactionById;
const updateTransaction = (id, data) => (0, _request.default)({
  url: `/transactions/${id}`,
  method: 'patch',
  data
});
exports.updateTransaction = updateTransaction;
const deleteTransaction = id => (0, _request.default)({
  url: `/transactions/${id}`,
  method: 'delete'
});
exports.deleteTransaction = deleteTransaction;