"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'admin': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M44.5 50.3c2.7-1.7 6-2.3 9.2-1.6 35.3 8.2 72 8.2 107.2 0 17.4-4.1 34.2-10 50.3-17.8 3.3-1.6 7.2-1.6 10.5 0 48.9 23.7 104.5 30 157.5 17.8 6.5-1.4 12.9 2.6 14.4 9.1 12.8 56.8 14.4 115.6 4.7 173-1.3 6.5-7.6 10.7-14.1 9.5-6.4-1.2-10.6-7.3-9.5-13.7 8.6-50.4 7.9-101.9-2.1-152-35.1 6.5-71.2 5.6-106-2.5-17.2-4-34-9.8-50.1-17.1-16.1 7.3-32.9 13-50.1 17.1-34.8 8.1-70.8 9-105.9 2.5-26.3 143.7 10.6 304.7 153.4 349.6 6.3 2.1 9.7 8.9 7.6 15.2-2 6.2-8.6 9.6-14.8 7.7C48.4 400-2.8 200.7 44.5 50.3zm440 419.4c.3 6.6-4.8 12.2-11.4 12.6-6.6.3-12.2-4.8-12.6-11.4-5.9-101.6-189.8-101.6-195.8 0-.3 6.6-6 11.7-12.6 11.4-6.6-.3-11.7-5.9-11.4-12.5 2.4-50.1 36.9-80 77.4-92.2-46.4-42.4-13.1-117.1 44.4-115.4 58.1-1.6 90.6 73.1 44.4 115.4 40.7 12.2 75.2 42.1 77.6 92.1zM362.6 286.1c-55.7 0-56.6 83.7 0 83.8 56.1 0 56.1-83.7 0-83.8z"/>'
  }
});