"use strict";

var _interopRequireWildcard = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array-buffer.detached.js");
require("core-js/modules/es.array-buffer.transfer.js");
require("core-js/modules/es.array-buffer.transfer-to-fixed-length.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.to-reversed.js");
require("core-js/modules/es.typed-array.to-sorted.js");
require("core-js/modules/es.typed-array.with.js");
require("core-js/modules/web.dom-exception.stack.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _address = require("@/api/address");
var XLSX = _interopRequireWildcard(require("xlsx"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = {
      file: ''
    };
    this.acceptableFormats = ['application/vnd.ms-excel.sheet.binary.macroenabled.12', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    this.fieldsMap = {
      emailAddress: 'emailAddress',
      city: 'city',
      company: 'companyName',
      fax: 'fax',
      firstname: 'firstName',
      lastname: 'lastName',
      middlename: 'middleName',
      postcode: 'pincode',
      region: 'region',
      street: 'line1',
      telephone: 'mobileNumber'
    };
    this.file = null;
    this.fileTemp = null;
    this.fileExcelToJsonProgressing = false;
    this.fileRead = false;
    this.productData = [];
    this.groupData = [];
    this.processingProduct = false;
    this.currentlyProcessingIndex = -1;
    this.processedProducts = 0;
    this.failedProducts = [];
    this.pauseProcessingProduct = false;
    this.isLessThanHundred = true;
  }
  reset() {
    this.fileExcelToJsonProgressing = false;
    this.fileRead = false;
    this.productData = [];
    this.processingProduct = false;
    this.currentlyProcessingIndex = -1;
    this.processedProducts = 0;
    this.failedProducts = [];
    this.pauseProcessingProduct = false;
  }
  handleChange(file, fileList) {
    this.reset();
    this.fileTemp = file.raw;
    if (this.fileTemp) {
      if (this.acceptableFormats.indexOf(this.fileTemp.type) > -1) {
        this.importfxx(this.fileTemp);
      } else {
        this.$message({
          type: 'warning',
          message: 'Attachment format error, please delete and upload again!'
        });
      }
    } else {
      this.$message({
        type: 'warning',
        message: 'Please upload the attachment!'
      });
    }
  }
  // Processing method when exceeding the maximum number of uploaded files
  handleExceed() {
    this.$message({
      type: 'warning',
      message: 'Exceed the maximum number of uploaded files limit!'
    });
  }
  // How to remove files
  handleRemove(file, fileList) {
    this.fileTemp = null;
  }
  importfxx(obj) {
    this.fileRead = false;
    this.fileExcelToJsonProgressing = true;
    const _this = this;
    const inputDOM = this.$refs.inputer;
    // Retrieving file data through DOM
    this.file = this.fileTemp;
    const rABS = false; // Read the file as a binary string
    const f = this.file;
    const reader = new FileReader();
    // if (!FileReader.prototype.readAsBinaryString) {
    FileReader.prototype.readAsBinaryString = function (f) {
      let binary = '';
      const rABS = false; // Read the file as a binary string
      const pt = this;
      let wb; // Read completed data
      let outdata;
      const reader = new FileReader();
      reader.onload = function (e) {
        const result = reader.result;
        const bytes = new Uint8Array(result);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        // If not introduced in main.js, you need to introduce it here to parse excel
        // var XLSX = require("xlsx");
        if (rABS) {
          wb = XLSX.read(btoa(_this.fixdata(binary)), {
            // Manual conversion
            type: 'base64'
          });
        } else {
          wb = XLSX.read(binary, {
            type: 'binary'
          });
        }
        _this.fileRead = true;
        _this.fileExcelToJsonProgressing = false;
        _this.productData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          defval: null
        });
        _this.clearData();
      };
      reader.readAsArrayBuffer(f);
    };
    if (rABS) {
      reader.readAsArrayBuffer(f);
    } else {
      reader.readAsBinaryString(f);
    }
  }
  fixdata(data) {
    let o = '';
    let l = 0;
    const w = 10240;
    for (; l < data.byteLength / w; ++l) {
      const sliced = new Uint8Array(data.slice(l * w, l * w + w));
      o += String.fromCharCode.apply(null, sliced);
    }
    const sliced = new Uint8Array(data.slice(l * w));
    o += String.fromCharCode.apply(null, sliced);
    return o;
  }
  clearData() {
    // jeff
    for (let index = 0; index < this.productData.length; index++) {
      const product = this.getProductToBeProcessed(index);
      if (product) {
        // check for enabled
        product.enabled = product.enabled !== 'Closed';
        // since Naresh messed with ids, clearing it here
        if (product.amenities) {
          product.amenities = product.amenities.split(',').map(amenityId => {
            // remove space
            return amenityId.trim();
          }).filter(amenityId => {
            // empty check
            return amenityId;
          }).join(',');
        }
        this.productData[index] = product;
      }
    }
  }
  async processProduct() {
    if (this.currentlyProcessingIndex < this.productData.length - 1) {
      // increase index
      this.currentlyProcessingIndex++;
      const productToBeProcessed = this.productData[this.currentlyProcessingIndex];
      if (!productToBeProcessed) {
        return;
      }
      try {
        console.log(this.groupData);
        this.processedProducts++;
        this.pauseProcessingProduct = false;
        this.processingProduct = true;
        this.groupData.push(productToBeProcessed);
        let response = {};
        if (this.groupData.length % 100 === 0) {
          console.log('in');
          response = await (0, _address.importAddress)(this.groupData);
          console.log(response);
          this.groupData = [];
        } else if (this.productData.length === this.currentlyProcessingIndex + 1) {
          console.log('else');
          const data = this.productData.slice(this.currentlyProcessingIndex);
          response = await (0, _address.importAddress)(this.groupData);
          console.log(response);
          this.currentlyProcessingIndex = this.productData.length - 1;
          this.processedProducts += data.length - 1;
        }
        // check if any of these failed
        if (response.errors) {
          response.errors.forEach(failed => {
            if (failed.reason.id) {
              this.failedProducts.push(failed.reason);
            }
          });
        }
      } catch (error) {
        this.$message({
          type: 'warning',
          message: error.toString()
        });
      } finally {
        this.processingProduct = false;
        setTimeout(() => {
          if (!this.pauseProcessingProduct) {
            this.processProduct();
          }
        });
      }
    }
  }
  getProductToBeProcessed(index = -1) {
    if (index in this.productData) {
      const product = {};
      const productToBeProcessed = this.productData[index];
      // convert to our desired format
      for (const key in productToBeProcessed) {
        const value = productToBeProcessed[key];
        // check if this key exist in Map
        if (key in this.fieldsMap) {
          console.log(value);
          product[this.fieldsMap[key]] = value;
        }
      }
      return product;
    }
    return false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'UserImport',
  components: {}
})], default_1);
var _default = exports.default = default_1;