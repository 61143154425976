"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'trend-down': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<g data-name="Layer 2"><path pid="0" d="M21 12a1 1 0 00-2 0v2.3l-4.24-5a1 1 0 00-1.27-.21L9.22 11.7 4.77 6.36a1 1 0 10-1.54 1.28l5 6a1 1 0 001.28.22l4.28-2.57 4 4.71H15a1 1 0 000 2h5a1.1 1.1 0 00.36-.07l.14-.08a1.19 1.19 0 00.15-.09.75.75 0 00.14-.17 1.1 1.1 0 00.09-.14.64.64 0 00.05-.17A.78.78 0 0021 17z" data-name="trending-down"/></g>'
  }
});