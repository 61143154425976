"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _productsSubMenu = require("./subMenus/products/products-sub-menu");
var _sizesSubMenu = require("./subMenus/products/sizes-sub-menu");
var _shapesSubMenu = require("./subMenus/products/shapes-sub-menu");
var _coloursSubMenu = require("./subMenus/products/colours-sub-menu");
var _claritiesSubMenu = require("./subMenus/products/clarities-sub-menu");
var _fluorescencesSubMenu = require("./subMenus/products/fluorescences-sub-menu");
var _finishesSubMenu = require("./subMenus/products/finishes-sub-menu");
var _cutsSubMenu = require("./subMenus/products/cuts-sub-menu");
var _polishesSubMenu = require("./subMenus/products/polishes-sub-menu");
var _symmetriesSubMenu = require("./subMenus/products/symmetries-sub-menu");
const productRouter = {
  path: '/product',
  component: _index.default,
  redirect: 'noredirect',
  name: 'productManagement.title',
  meta: {
    title: 'productManagement.title',
    icon: 'product-management'
  },
  children: [..._productsSubMenu.productsSubMenu,
  // ...categoriesSubMenu,
  // ...attributesSubMenu,
  // ...brandsSubMenu,
  ..._shapesSubMenu.shapesSubMenu, ..._sizesSubMenu.sizesSubMenu, ..._coloursSubMenu.coloursSubMenu, ..._claritiesSubMenu.claritiesSubMenu, ..._cutsSubMenu.cutsSubMenu, ..._polishesSubMenu.polishesSubMenu, ..._symmetriesSubMenu.symmetriesSubMenu, ..._finishesSubMenu.finishesSubMenu, ..._fluorescencesSubMenu.fluorescencesSubMenu]
};
var _default = exports.default = productRouter;