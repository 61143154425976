"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticPagesRouter = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const staticPagesRouter = exports.staticPagesRouter = [{
  path: '/static-pages/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/static-pages/list.vue'))),
  name: 'Static Page',
  meta: {
    title: 'master.staticPages.title',
    noCache: true,
    hidden: false,
    icon: 'pdf'
  }
}, {
  path: '/static-pages/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/static-pages/manage.vue'))),
  name: 'master.staticPages.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.staticPages.manage',
    noCache: true,
    activeMenu: '/static-pages/list',
    hidden: true
  }
}, {
  path: '/static-pages/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/static-pages/manage.vue'))),
  name: 'master.staticPages.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.staticPages.manage',
    noCache: true,
    activeMenu: '/static-pages/list',
    hidden: true
  }
}];