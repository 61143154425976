"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productsSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const productsSubMenu = exports.productsSubMenu = [{
  path: "/product/list",
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/products/list.vue"))),
  name: "productManagement.product.title",
  meta: {
    title: "productManagement.product.title",
    noCache: true,
    icon: "diamond-products"
  }
}, {
  path: "/product/add",
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/products/manage.vue"))),
  name: "productManagement.product.manage",
  props: {
    isEdit: false
  },
  meta: {
    title: "productManagement.product.manage",
    noCache: true,
    hidden: true,
    activeMenu: "/product/list"
  }
}, {
  path: "/product/edit/:id(\\d+)",
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/products/manage.vue"))),
  name: "productManagement.product.manage",
  props: {
    isEdit: true
  },
  meta: {
    title: "productManagement.product.manage",
    noCache: true,
    hidden: true,
    activeMenu: "/product/list"
  }
}, {
  path: "/product/import",
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/products/import.vue"))),
  name: "productManagement.product.import",
  props: {
    isEdit: false
  },
  meta: {
    title: "productManagement.product.import",
    noCache: true,
    hidden: true
  }
}, {
  path: "/product/import-update-price",
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/products/import-update-price.vue"))),
  name: "productManagement.product.updatePrice",
  props: {
    isEdit: false
  },
  meta: {
    title: "productManagement.product.updatePrice",
    noCache: true,
    hidden: true
  }
}];