"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      action: "",
      "on-change": _vm.handleChange,
      "on-remove": _vm.handleRemove,
      "on-exceed": _vm.handleExceed,
      accept: _vm.acceptableFormats.join(", "),
      "auto-upload": false
    },
    model: {
      value: _vm.postForm.file,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "file", $$v);
      },
      expression: "postForm.file"
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "warning",
      loading: _vm.fileExcelToJsonProgressing,
      disabled: _vm.processingProduct
    }
  }, [_vm._v(" Click Upload ")])], 1), _c("br"), _c("br")], 1)], 1), _vm.fileRead && _vm.productData.length > 0 ? [_c("div", {
    staticClass: "centered-count"
  }, [_vm.currentlyProcessingIndex < _vm.productData.length - 1 ? _c("div", [_c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-video-play",
      disabled: _vm.processingProduct
    },
    on: {
      click: _vm.processProduct
    }
  }, [_vm._v(" Process ")]), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-video-play",
      disabled: _vm.processingProduct
    },
    on: {
      click: _vm.reset
    }
  }, [_vm._v(" Reset ")]), _c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-video-pause",
      disabled: _vm.pauseProcessingProduct
    },
    on: {
      click: function ($event) {
        _vm.pauseProcessingProduct = true;
      }
    }
  }, [_vm._v(" Pause ")])], 1) : _vm._e(), _c("br"), _c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 24,
      percentage: parseFloat((_vm.processedProducts * 100 / _vm.productData.length).toFixed(2)),
      status: "success"
    }
  }), _vm.currentlyProcessingIndex > -1 ? _c("p", [_vm._v(" currently processing id: " + _vm._s(_vm.productData[_vm.currentlyProcessingIndex].id) + " + 100 ")]) : _vm._e(), _c("br"), _c("el-row", {
    attrs: {
      gutter: 12
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_vm._v(" Total: " + _vm._s(_vm.productData.length) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_vm._v(" Processed: " + _vm._s(_vm.processedProducts) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_vm._v(" Failed: " + _vm._s(_vm.failedProducts.length) + " "), _c("br"), _vm._l(_vm.failedProducts, function (failedProduct) {
    return _c("div", {
      key: failedProduct.id,
      staticClass: "text item failed-product-item"
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: failedProduct.message,
        placement: "top"
      }
    }, [_c("el-button", {
      attrs: {
        type: "info",
        size: "mini"
      }
    }, [_vm._v(" " + _vm._s(failedProduct.id) + " ")])], 1)], 1);
  })], 2)], 1)], 1)], 1)] : _vm._e()], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;