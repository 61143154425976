"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrganization = exports.getOrganizations = exports.getOrganizationById = exports.fileTransferHeaders = exports.deleteOrganization = exports.defaultOrganizationData = exports.createOrganization = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultOrganizationData = exports.defaultOrganizationData = {
  id: 0,
  enabled: true,
  name: '',
  gstNumber: '',
  panNumber: '',
  fssaiNumber: ''
};
const getOrganizations = params => (0, _request.default)({
  url: '/Organizations',
  method: 'get',
  params
});
exports.getOrganizations = getOrganizations;
const getOrganizationById = id => (0, _request.default)({
  url: `/Organizations/${id}`,
  method: 'get'
});
exports.getOrganizationById = getOrganizationById;
const updateOrganization = (id, data) => (0, _request.default)({
  url: `/Organizations/${id}`,
  method: 'patch',
  data
});
exports.updateOrganization = updateOrganization;
const deleteOrganization = id => (0, _request.default)({
  url: `/Organizations/${id}`,
  method: 'delete'
});
exports.deleteOrganization = deleteOrganization;
const createOrganization = data => (0, _request.default)({
  url: '/Organizations/',
  method: 'post',
  data
});
exports.createOrganization = createOrganization;