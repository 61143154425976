"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _validate = require("@/utils/validate");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
var _admins = require("@/api/admins");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateUsername = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error('Please enter valid email'));
      } else {
        callback();
      }
    };
    this.recoveryForm = {
      email: ''
    };
    this.recoveryRules = {
      email: [{
        validator: this.validateUsername,
        trigger: 'blur'
      }]
    };
    this.passwordType = 'password';
    this.loading = false;
    this.showDialog = false;
    this.otherQuery = {};
  }
  handleLogin() {
    this.$refs.recoveryForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          await (0, _admins.forgotPassword)(this.recoveryForm);
          this.$notify({
            title: 'Success',
            message: 'New password has been mailed to you at ' + this.recoveryForm.email,
            type: 'success',
            duration: 3000
          });
          this.$router.push({
            path: '/login'
          });
        } catch (error) {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Recover Password',
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;