"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block menu-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Label",
      prop: "label"
    }
  }, [_c("el-input", {
    attrs: {
      name: "label",
      required: "",
      placeholder: "Label"
    },
    model: {
      value: _vm.formData.label,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "label", $$v);
      },
      expression: "formData.label"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Link Type",
      prop: "linkType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "linkType",
      required: ""
    },
    on: {
      change: _vm.emitFormData
    },
    model: {
      value: _vm.formData.linkType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "linkType", $$v);
      },
      expression: "formData.linkType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Category",
      value: "category"
    }
  }), _c("el-option", {
    attrs: {
      label: "Page",
      value: "page"
    }
  }), _c("el-option", {
    attrs: {
      label: "Link",
      value: "link"
    }
  })], 1)], 1), _vm.formData.linkType === "category" ? _c("el-form-item", {
    attrs: {
      label: "Category",
      prop: "categoryId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "categoryId",
      required: ""
    },
    on: {
      change: function ($event) {
        return _vm.updateCategorySlug(_vm.formData.categoryId);
      }
    },
    model: {
      value: _vm.formData.categoryId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "categoryId", $$v);
      },
      expression: "formData.categoryId"
    }
  }, _vm._l(_vm.categoryList.data, function (category) {
    return _c("el-option", {
      key: category.id,
      attrs: {
        label: category.name,
        value: category.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.linkType === "product" ? _c("el-form-item", {
    attrs: {
      label: "Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "productId",
      required: ""
    },
    on: {
      change: function ($event) {
        return _vm.updateProductSlug(_vm.formData.productId);
      }
    },
    model: {
      value: _vm.formData.productId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productId", $$v);
      },
      expression: "formData.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Visibility",
      prop: "visibility"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "visibility",
      multiple: "",
      clearable: "",
      required: ""
    },
    model: {
      value: _vm.formData.visibility,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "visibility", $$v);
      },
      expression: "formData.visibility"
    }
  }, _vm._l(_vm.visibilityOptions, function (visibility) {
    return _c("el-option", {
      key: visibility.key,
      attrs: {
        label: visibility.label,
        value: visibility.key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Slug",
      prop: "slug"
    }
  }, [_c("el-input", {
    attrs: {
      name: "slug",
      required: "",
      placeholder: "Slug"
    },
    model: {
      value: _vm.formData.slug,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "slug", $$v);
      },
      expression: "formData.slug"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v(" https://www.sgl.in/ ")])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "priority",
      required: "",
      placeholder: "Priority"
    },
    model: {
      value: _vm.formData.priority,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "priority", $$v);
      },
      expression: "formData.priority"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;