"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSizesMarkup = exports.getSizesMarkups = exports.getSizesMarkupById = exports.deleteSizesMarkup = exports.defaultSizesMarkupData = exports.createSizesMarkup = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSizesMarkupData = exports.defaultSizesMarkupData = {
  id: 0,
  enabled: true,
  size: 0,
  markup: 0,
  sellerId: undefined,
  seller: undefined
};
const getSizesMarkups = params => (0, _request.default)({
  url: '/sizes-markups',
  method: 'get',
  params
});
exports.getSizesMarkups = getSizesMarkups;
const getSizesMarkupById = id => (0, _request.default)({
  url: `/sizes-markups/${id}`,
  method: 'get'
});
exports.getSizesMarkupById = getSizesMarkupById;
const updateSizesMarkup = (id, data) => (0, _request.default)({
  url: `/sizes-markups/${id}`,
  method: 'patch',
  data
});
exports.updateSizesMarkup = updateSizesMarkup;
const deleteSizesMarkup = id => (0, _request.default)({
  url: `/sizes-markups/${id}`,
  method: 'delete'
});
exports.deleteSizesMarkup = deleteSizesMarkup;
const createSizesMarkup = data => (0, _request.default)({
  url: '/sizes-markups/',
  method: 'post',
  data
});
exports.createSizesMarkup = createSizesMarkup;