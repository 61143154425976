"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'color': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M376.822 153.145l-95.42 302.164L512 153.145zM74.336 46.263L1.082 134.168h124.532zM437.664 46.263l-51.278 87.905h124.532zM135.178 153.145H0l230.598 302.164zM278.906 39.279l88.968 88.968 51.894-88.968zM92.232 39.279l51.894 88.968 88.968-88.968zM155.076 153.145L256 472.721l100.924-319.576zM256 43.208l-90.96 90.96h181.92z"/>'
  }
});