"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTax = exports.getTaxes = exports.getTaxById = exports.fileTransferHeaders = exports.deleteTax = exports.defaultTaxData = exports.createTax = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultTaxData = exports.defaultTaxData = {
  id: 0,
  enabled: true,
  name: '',
  countryId: undefined,
  rate: 0,
  globalRate: 0
};
const getTaxes = params => (0, _request.default)({
  url: '/taxes',
  method: 'get',
  params
});
exports.getTaxes = getTaxes;
const getTaxById = id => (0, _request.default)({
  url: `/taxes/${id}`,
  method: 'get'
});
exports.getTaxById = getTaxById;
const updateTax = (id, data) => (0, _request.default)({
  url: `/taxes/${id}`,
  method: 'patch',
  data
});
exports.updateTax = updateTax;
const deleteTax = id => (0, _request.default)({
  url: `/taxes/${id}`,
  method: 'delete'
});
exports.deleteTax = deleteTax;
const createTax = data => (0, _request.default)({
  url: '/taxes/',
  method: 'post',
  data
});
exports.createTax = createTax;