"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFluorescence = exports.getFluorescences = exports.getFluorescenceById = exports.fileTransferHeaders = exports.deleteFluorescence = exports.defaultFluorescenceData = exports.createFluorescence = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultFluorescenceData = exports.defaultFluorescenceData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0
};
const getFluorescences = params => (0, _request.default)({
  url: '/fluorescences',
  method: 'get',
  params
});
exports.getFluorescences = getFluorescences;
const getFluorescenceById = id => (0, _request.default)({
  url: `/fluorescences/${id}`,
  method: 'get'
});
exports.getFluorescenceById = getFluorescenceById;
const updateFluorescence = (id, data) => (0, _request.default)({
  url: `/fluorescences/${id}`,
  method: 'patch',
  data
});
exports.updateFluorescence = updateFluorescence;
const deleteFluorescence = id => (0, _request.default)({
  url: `/fluorescences/${id}`,
  method: 'delete'
});
exports.deleteFluorescence = deleteFluorescence;
const createFluorescence = data => (0, _request.default)({
  url: '/fluorescences/',
  method: 'post',
  data
});
exports.createFluorescence = createFluorescence;