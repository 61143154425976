"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContact = exports.getContacts = exports.getContactById = exports.fileTransferHeaders = exports.deleteContact = exports.defaultContactData = exports.createContact = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultContactData = exports.defaultContactData = {
  id: 0,
  enabled: true,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  content: ''
};
const getContacts = params => (0, _request.default)({
  url: '/contacts',
  method: 'get',
  params
});
exports.getContacts = getContacts;
const getContactById = id => (0, _request.default)({
  url: `/contacts/${id}`,
  method: 'get'
});
exports.getContactById = getContactById;
const updateContact = (id, data) => (0, _request.default)({
  url: `/contacts/${id}`,
  method: 'patch',
  data
});
exports.updateContact = updateContact;
const deleteContact = id => (0, _request.default)({
  url: `/contacts/${id}`,
  method: 'delete'
});
exports.deleteContact = deleteContact;
const createContact = data => (0, _request.default)({
  url: '/contacts/',
  method: 'post',
  data
});
exports.createContact = createContact;