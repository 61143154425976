"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'admin-new': {
    width: 127,
    height: 127,
    viewBox: '0 0 127 127',
    data: '<defs><clipPath id="svgicon_admin-new_b"><path pid="0" data-name="Rectangle 12" _fill="#07953e" d="M0 0h111.205v124.067H0z"/></clipPath><clipPath id="svgicon_admin-new_a"><path pid="1" d="M0 0h127v127H0z"/></clipPath></defs><g data-name="Group 27" clip-path="url(#svgicon_admin-new_a)"><g data-name="Group 10" transform="translate(7.898 1.466)" clip-path="url(#svgicon_admin-new_b)" _fill="#07953e"><path pid="2" data-name="Path 32" d="M59.147 0a39.869 39.869 0 018.39 2.227c10.85 4.73 17.635 12.814 19.756 24.511a30.71 30.71 0 01-6.345 25.254A31.132 31.132 0 0159.568 64.2a30.911 30.911 0 01-26.731-9.128c-7.708-7.682-10.913-17.134-9.149-27.962A32.112 32.112 0 0149.042.544C49.984.339 50.936.18 51.883 0z"/><path pid="3" data-name="Path 33" d="M55.41 124.063a63.051 63.051 0 01-38.16-12.571A63.722 63.722 0 01.665 93.399a3.833 3.833 0 01-.2-4 40.2 40.2 0 0131.149-22.815 5.872 5.872 0 014.262 1.4A32.383 32.383 0 0048.2 73.407c9.451 1.918 18.352.485 26.3-5.067 3.072-2.144 6.05-1.766 9.21-.958a39.876 39.876 0 0125.733 19.772c2.444 4.333 2.323 3.8-.227 7.617-9.024 13.493-21.225 22.7-36.954 27.039a60.529 60.529 0 01-16.852 2.252"/></g></g>'
  }
});