"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.formData = Object.assign({}, _admins.defaultAdminData);
    this.adminId = _admin.AdminModule.id;
    this.loading = false;
    this.rules = {
      password: [{
        required: true,
        message: 'Password is required',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Password length should be greater than 6',
        trigger: 'blur'
      }],
      currentPassword: [{
        required: true,
        message: 'Please enter current password',
        trigger: 'blur'
      }],
      confirmPassword: [{
        required: true,
        message: 'Confirm Password is required',
        trigger: 'blur'
      }]
    };
    this.genderOptions = [{
      label: 'Male',
      key: 'male'
    }, {
      label: 'Female',
      key: 'female'
    }, {
      label: 'Other',
      key: 'other'
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      // const id = this.$route.params && this.$route.params.id;
      // this.fetchData(parseInt(id));
      this.fetchData(this.adminId);
    } else {
      this.formData = Object.assign({}, _admins.defaultAdminData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _admins.getAdminById)(id);
      this.formData = data;
      // Just for test
      const title = this.formData.fullName;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `Edit ${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      // check if password & confirm password matches
      if (this.formData.password !== this.formData.confirmPassword) {
        this.$notify.error('Password does not match');
        this.loading = false;
        return false;
      }
      const changePasswordObj = {
        adminId: this.adminId,
        currentPassword: this.formData.currentPassword,
        password: this.formData.password
      };
      await (0, _admins.changePassword)(changePasswordObj);
      this.$notify({
        title: 'Success',
        message: 'Password changed successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/admins/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AdminDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;