"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'dashboard-new': {
    width: 127,
    height: 127,
    viewBox: '0 0 127 127',
    data: '<defs><clipPath id="svgicon_dashboard-new_a"><path pid="0" d="M0 0h127v127H0z"/></clipPath></defs><g clip-path="url(#svgicon_dashboard-new_a)"><path pid="1" data-name="dashboard" d="M124.464 60.65a61.692 61.692 0 00-122.87 8.035 61.065 61.065 0 0019.325 44.788 24.883 24.883 0 0017.243 6.622h50.212a25.674 25.674 0 0018.04-7.346 61.4 61.4 0 0018.05-52.1zm-84.456 42.279a5.141 5.141 0 01-7.259.463 46.233 46.233 0 0130.537-80.971 46.783 46.783 0 0111.562 1.45 5.145 5.145 0 01-2.571 9.963A35.956 35.956 0 0039.545 95.67a5.141 5.141 0 01.463 7.259zm23.278-23.962a10.282 10.282 0 112.632-20.184l19.439-19.437a5.141 5.141 0 117.27 7.27L73.188 66.053a10.159 10.159 0 01-9.9 12.914zm30.538 24.425a5.141 5.141 0 01-6.8-7.711 35.946 35.946 0 0011.11-35.987 5.145 5.145 0 019.963-2.57 46.752 46.752 0 011.455 11.562 46.269 46.269 0 01-15.728 34.706z" _fill="#07953e"/></g>'
  }
});