"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pincodesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const pincodesSubMenu = exports.pincodesSubMenu = [{
  path: '/pincode/list/:id',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/list.vue'))),
  name: 'Pincode',
  meta: {
    title: 'locations.pincode.title',
    hidden: true,
    noCache: true,
    activeMenu: '/states/list'
  }
}, {
  path: '/pincode/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/manage.vue'))),
  name: 'locations.pincode.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.pincode.manage',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/pincode/add/:id',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/manage.vue'))),
  name: 'Add Pincode',
  props: {
    isEdit: false
  },
  meta: {
    title: 'Add Pincode',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/pincode/import',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/import.vue'))),
  name: 'locations.pincode.import',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.pincode.import',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}];