"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locationSubMenu = void 0;
var _citiesSubMenu = require("./locations/cities-sub-menu");
var _countriesSubMenu = require("./locations/countries-sub-menu");
var _pincodeSubMenu = require("./locations/pincode-sub-menu");
var _statesSubMenu = require("./locations/states-sub-menu");
const locationSubMenu = exports.locationSubMenu = [..._countriesSubMenu.countriesSubMenu, ..._statesSubMenu.statesSubMenu, ..._citiesSubMenu.citiesSubMenu, ..._pincodeSubMenu.pincodesSubMenu];