"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'trend-up': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 17l6-6 4 4 8-8m0 0v5m0-5h-5" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
});