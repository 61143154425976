"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePolish = exports.getPolishes = exports.getPolishById = exports.deletePolish = exports.defaultPolishData = exports.createPolish = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPolishData = exports.defaultPolishData = {
  id: 0,
  enabled: true,
  name: '',
  priority: 0
};
const getPolishes = params => (0, _request.default)({
  url: '/polishes',
  method: 'get',
  params
});
exports.getPolishes = getPolishes;
const getPolishById = id => (0, _request.default)({
  url: `/polishes/${id}`,
  method: 'get'
});
exports.getPolishById = getPolishById;
const updatePolish = (id, data) => (0, _request.default)({
  url: `/polishes/${id}`,
  method: 'patch',
  data
});
exports.updatePolish = updatePolish;
const deletePolish = id => (0, _request.default)({
  url: `/polishes/${id}`,
  method: 'delete'
});
exports.deletePolish = deletePolish;
const createPolish = data => (0, _request.default)({
  url: '/polishes/',
  method: 'post',
  data
});
exports.createPolish = createPolish;