"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'auction': {
    width: 16,
    height: 16,
    viewBox: '0 0 491.54 491.54',
    data: '<path pid="0" d="M282.58 387.484H36.909C16.534 387.484 0 404.016 0 424.394v67.115h319.488v-67.115c0-20.378-16.533-36.91-36.908-36.91zM484.916 392.69L260.66 168.433l43.315-43.238c7.142 6.298 18.125 5.99 24.883-.768a18.263 18.263 0 000-25.728L235.47 5.387c-7.066-7.142-18.586-7.142-25.651 0-7.142 7.066-7.142 18.586 0 25.651l-.768-.768-118.58 118.502.768.844c-7.066-7.142-18.586-7.142-25.651 0-7.142 7.066-7.142 18.586 0 25.652l93.312 93.388c7.142 7.066 18.662 7.066 25.728 0a18.26 18.26 0 000-25.726l.768.766 43.315-43.238 224.179 224.18c8.832 8.832 23.194 8.832 32.026 0 8.832-8.832 8.832-23.117 0-31.948z"/>'
  }
});