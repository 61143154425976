"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSetting = exports.getSettings = exports.getSettingById = exports.fileTransferHeaders = exports.deleteSetting = exports.defaultSettingData = exports.createSetting = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultSettingData = exports.defaultSettingData = {
  id: 0,
  deliveryCharge: 0,
  freeDeliveryThreshold: 0,
  orderCutOffTime: undefined,
  deliveryDiscount: 0,
  maximumDeliveryDays: 0,
  discountDeliveryDays: 0,
  maximumCashAllowed: 0,
  maximumDeliveryDiscount: 0,
  supportEmail: undefined,
  supportContact: undefined,
  tnc: undefined,
  helpFile: undefined,
  privacyPolicy: undefined,
  aboutUs: undefined,
  androidVersion: undefined,
  androidForceUpdate: false,
  iosVersion: undefined,
  iosForceUpdate: false,
  address: undefined,
  facebookLink: undefined,
  instagramLink: undefined,
  twitterLink: undefined,
  youtubeLink: undefined,
  pinterestLink: undefined,
  playStoreLink: undefined,
  appStoreLink: undefined,
  productDiscountType: undefined,
  discountOptions: [],
  showProductsOnCheckout: false,
  product: [],
  activateCOD: false,
  codHandlingCharges: 0,
  webVersion: undefined,
  webForceUpdate: false,
  websiteMenuImage: undefined,
  websiteMenuImageLink: undefined,
  metaTitle: undefined,
  metaDescription: undefined,
  metaKeywords: undefined,
  canonicalTag: undefined,
  headerJavascript: undefined,
  footerJavascript: undefined,
  googleAnalyticsCode: undefined,
  facebookPixelAnalyticsCode: undefined,
  footerDescription: undefined,
  footerYear: 0,
  holdTime: 0,
  minimumOrderAmount: 0,
  minCaratWeight: 0,
  maxCaratWeight: 0
};
const getSettings = params => (0, _request.default)({
  url: '/settings',
  method: 'get',
  params
});
exports.getSettings = getSettings;
const getSettingById = id => (0, _request.default)({
  url: `/settings/${id}`,
  method: 'get'
});
exports.getSettingById = getSettingById;
const updateSetting = (id, data) => (0, _request.default)({
  url: `/settings/${id}`,
  method: 'patch',
  data
});
exports.updateSetting = updateSetting;
const deleteSetting = id => (0, _request.default)({
  url: `/settings/${id}`,
  method: 'delete'
});
exports.deleteSetting = deleteSetting;
const createSetting = data => (0, _request.default)({
  url: '/settings/',
  method: 'post',
  data
});
exports.createSetting = createSetting;