"use strict";

var _interopRequireWildcard = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
require("@/styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("@/App.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _app = require("@/store/modules/app");
var _router = _interopRequireDefault(require("@/router"));
var _lang = _interopRequireDefault(require("@/lang"));
require("@/icons/components");
require("@/permission");
require("@/utils/error-log");
require("@/pwa/register-service-worker");
var directives = _interopRequireWildcard(require("@/directives"));
var filters = _interopRequireWildcard(require("@/filters"));
var _vueMoment = _interopRequireDefault(require("vue-moment"));
var _vuePluginLoadScript = _interopRequireDefault(require("vue-plugin-load-script"));
var _vueQrcode = _interopRequireDefault(require("@chenfengyuan/vue-qrcode"));
var _vueCtkDateTimePicker = _interopRequireDefault(require("vue-ctk-date-time-picker"));
require("vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css");
_vue.default.component('VueCtkDateTimePicker', _vueCtkDateTimePicker.default);
_vue.default.use(_elementUi.default, {
  size: _app.AppModule.size,
  i18n: (key, value) => _lang.default.t(key, value)
});
_vue.default.use(_vueMoment.default);
_vue.default.use(_vuePluginLoadScript.default);
_vue.default.component(_vueQrcode.default.name, _vueQrcode.default);
_vue.default.use(_vueSvgicon.default, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
});
// Register global directives
Object.keys(directives).forEach(key => {
  _vue.default.directive(key, directives[key]);
});
// Register global filter functions
Object.keys(filters).forEach(key => {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: h => h(_App.default)
}).$mount('#app');