"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block banner-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Seller",
      prop: "sellerId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "sellerId",
      required: "",
      disabled: ""
    },
    model: {
      value: _vm.formData.sellerId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sellerId", $$v);
      },
      expression: "formData.sellerId"
    }
  }, _vm._l(_vm.sellersList, function (seller) {
    return _c("el-option", {
      key: seller.id,
      attrs: {
        label: seller.companyName,
        value: seller.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticClass: "margin-top-10px",
    attrs: {
      label: "Size",
      prop: "size"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "size",
      required: "",
      min: 0.01,
      max: 0.4,
      precision: 2,
      step: 0.01,
      placeholder: "Size",
      disabled: _vm.isEdit
    },
    model: {
      value: _vm.formData.size,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "size", $$v);
      },
      expression: "formData.size"
    }
  })], 1), _c("el-form-item", {
    staticClass: "margin-top-10px",
    attrs: {
      label: "Markup (in %)",
      prop: "markup"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "markup",
      required: "",
      placeholder: "Markup",
      min: 0,
      max: 100,
      precision: 2,
      step: 0.01
    },
    model: {
      value: _vm.formData.markup,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "markup", $$v);
      },
      expression: "formData.markup"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;