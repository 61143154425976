"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cutsSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const cutsSubMenu = exports.cutsSubMenu = [{
  path: '/cut/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/cut/list.vue'))),
  name: 'Cuts',
  meta: {
    title: 'productManagement.cut.title',
    noCache: true,
    hidden: false,
    icon: 'diamond-cut'
  }
}, {
  path: '/cut/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/cut/manage.vue'))),
  name: 'productManagement.cut.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.cut.manage',
    noCache: true,
    activeMenu: '/cut/list',
    hidden: true
  }
}, {
  path: '/cut/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/cut/manage.vue'))),
  name: 'productManagement.cut.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.cut.manage',
    noCache: true,
    activeMenu: '/cut/list',
    hidden: true
  }
}];