"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'tag': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M422.574 0L269.633 11.697 0 281.331 230.67 512l269.633-269.633L512 89.426 422.574 0zM110.645 305.635l147.413-147.413 23.611 23.611-147.413 147.413-23.611-23.611zm95.721 95.721l-23.611-23.611 147.413-147.413 23.611 23.611-147.413 147.413zm203.41-247.283c-14.317 14.317-37.531 14.317-51.849 0-14.317-14.318-14.317-37.531 0-51.849 14.317-14.317 37.531-14.317 51.849 0 14.318 14.318 14.318 37.531 0 51.849z"/>'
  }
});