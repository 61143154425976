"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'shape': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M510 139.6L424.7 37.2c-1.6-1.9-4-3.1-6.6-3.1H93.9c-2.5 0-4.9 1.1-6.6 3.1L2 139.6c-2.1 2.5-2.6 6.1-1.2 9.1s4.4 4.9 7.7 4.9h494.9c3.3 0 6.3-1.9 7.7-4.9 1.5-3 1.1-6.5-1.1-9.1zm-483.2-3.1l71.1-85.3h316.3l71.1 85.3H26.8z" _fill="#0a3378"/><path pid="1" d="M263.9 39.4c-1.3-3.2-4.4-5.3-7.9-5.3H93.9c-3.1 0-5.9 1.6-7.4 4.3s-1.5 5.9 0 8.5l59.7 102.4c1.3 2.3 3.6 3.8 6.3 4.2.4 0 .7.1 1.1.1 2.3 0 4.4-.9 6-2.5L262 48.7c2.5-2.4 3.2-6.1 1.9-9.3zm-108.5 91.8l-46.7-80h126.7l-80 80z" _fill="#0a3378"/><path pid="2" d="M511.1 141.3c-1.4-2.9-4.4-4.8-7.6-4.8H8.5c-3.2 0-6.2 1.8-7.6 4.8-1.4 2.9-1.1 6.4.9 9l247.5 324.3c1.6 2.1 4.1 3.4 6.8 3.4s5.2-1.2 6.8-3.4l247.5-324.3c1.8-2.6 2.1-6.1.7-9zM256 455.3L25.8 153.6h460.4L256 455.3z" _fill="#0a3378"/><path pid="3" d="M264.1 466.8L161.7 142.5c-1.1-3.6-4.4-6-8.1-6H8.5c-3.2 0-6.2 1.8-7.6 4.8-1.4 2.9-1.1 6.4.9 9l247.5 324.3c1.7 2.2 4.2 3.4 6.8 3.4 1.3 0 2.7-.3 3.9-1 3.6-2 5.4-6.3 4.1-10.2zM25.8 153.6h121.6l85.8 271.7L25.8 153.6zM425.5 38.4c-1.5-2.7-4.3-4.3-7.4-4.3H256c-3.4 0-6.6 2.1-7.9 5.3-1.3 3.2-.6 6.9 1.9 9.3l102.4 102.4c1.6 1.6 3.8 2.5 6 2.5.4 0 .7 0 1.1-.1 2.6-.3 4.9-1.9 6.3-4.2L425.5 47c1.5-2.7 1.6-5.9 0-8.6zm-68.9 92.8l-80-80h126.7l-46.7 80z" _fill="#0a3378"/><path pid="4" d="M511.1 141.3c-1.4-2.9-4.4-4.8-7.6-4.8H358.4c-3.7 0-7 2.4-8.1 6L247.9 466.8c-1.2 3.9.5 8.2 4.2 10.1 1.2.6 2.6 1 3.9 1 2.6 0 5.1-1.2 6.8-3.3l247.5-324.3c1.9-2.6 2.3-6.1.8-9zm-232.3 284l85.8-271.7h121.6L278.8 425.3z" _fill="#0a3378"/>'
  }
});