"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _roles = require("@/api/roles");
var _utils = require("@/utils");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.formData = Object.assign({}, _admins.defaultAdminData);
    this.loading = false;
    this.rules = {
      fullName: [{
        required: true,
        message: 'Full Name is required',
        trigger: 'blur'
      }, {
        min: 3,
        max: 100,
        message: 'Length should be 3 to 100',
        trigger: 'blur'
      }],
      mobileNumber: [{
        required: true,
        message: 'Contact Number is required',
        trigger: 'blur'
      }, {
        min: 10,
        max: 10,
        message: 'Length should be 10',
        trigger: 'blur'
      }],
      emailAddress: [{
        required: true,
        message: 'Email is required',
        trigger: 'blur'
      }, {
        type: 'email',
        message: 'Please enter correct email address',
        trigger: ['blur', 'change']
      }],
      password: [{
        required: true,
        message: 'Password is required',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Password length should be greater than 6',
        trigger: 'blur'
      }]
    };
    this.genderOptions = [{
      label: 'Male',
      key: 'male'
    }, {
      label: 'Female',
      key: 'female'
    }, {
      label: 'Other',
      key: 'other'
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.fetchRoles();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _admins.defaultAdminData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _admins.getAdminById)(id);
      this.formData = data;
      // Just for test
      const title = this.formData.fullName;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchRoles() {
    try {
      this.roleList = await (0, _roles.getRoles)((0, _utils.getQuery)({
        enabled: true,
        sort: 'name,ASC',
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `Edit ${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.adminsRoleId = 1;
      if (this.isEdit) {
        this.formData.adminsRole.id = this.formData.adminsRoleId;
        delete this.formData.password;
        await (0, _admins.updateAdmin)(this.formData.id, this.formData);
      } else {
        delete this.formData.adminsRole;
        await (0, _admins.register)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'Admin saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/admins/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AdminDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;