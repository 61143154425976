"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const newslettersRouter = {
  path: '/newsletters',
  component: _index.default,
  redirect: 'newsletters',
  name: 'Contact Us',
  meta: {
    title: 'contacts.title',
    icon: 'hamburger'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/newsletters/list.vue'))),
    name: 'Newsletters',
    meta: {
      title: 'newsletters.title',
      noCache: true,
      icon: 'hamburger'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/newsletters/manage.vue'))),
    name: 'Edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'newsletters.edit',
      noCache: true,
      activeMenu: '/newsletters/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/newsletters/manage.vue'))),
    name: 'Add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'newsletters.add',
      noCache: true,
      activeMenu: '/newsletters/list',
      hidden: true
    }
  }]
};
var _default = exports.default = newslettersRouter;