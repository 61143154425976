"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCut = exports.getCuts = exports.getCutById = exports.deleteCut = exports.defaultCutData = exports.createCut = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCutData = exports.defaultCutData = {
  id: 0,
  enabled: true,
  name: '',
  priority: 0
};
const getCuts = params => (0, _request.default)({
  url: '/cuts',
  method: 'get',
  params
});
exports.getCuts = getCuts;
const getCutById = id => (0, _request.default)({
  url: `/cuts/${id}`,
  method: 'get'
});
exports.getCutById = getCutById;
const updateCut = (id, data) => (0, _request.default)({
  url: `/cuts/${id}`,
  method: 'patch',
  data
});
exports.updateCut = updateCut;
const deleteCut = id => (0, _request.default)({
  url: `/cuts/${id}`,
  method: 'delete'
});
exports.deleteCut = deleteCut;
const createCut = data => (0, _request.default)({
  url: '/cuts/',
  method: 'post',
  data
});
exports.createCut = createCut;