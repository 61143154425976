"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block state-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      required: "",
      placeholder: "Name"
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Code",
      prop: "code"
    }
  }, [_c("el-input", {
    attrs: {
      name: "code",
      required: "",
      placeholder: "Code"
    },
    model: {
      value: _vm.formData.code,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "code", $$v);
      },
      expression: "formData.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-input-number", {
    staticClass: "priority",
    attrs: {
      name: "priority",
      placeholder: "Priority",
      min: 0,
      precision: 0
    },
    model: {
      value: _vm.formData.priority,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "priority", $$v);
      },
      expression: "formData.priority"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;