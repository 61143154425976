"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _categories = require("@/api/categories");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
    this.categories = [];
    this.selectedCategories = []; // remove this with formData
    this.mainCategory = {};
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  created() {
    this.getCategories();
    // set tree nodes
    if (this.formData.category && this.formData.category.length) {
      this.selectedCategories = this.formData.category.map(category => {
        if (category.parentId !== null) {
          return category.id;
        }
      });
      const treeRef = this.$refs.tree;
      treeRef.setCheckedKeys(this.selectedCategories);
    }
  }
  emitFormData() {
    const treeRef = this.$refs.tree;
    this.selectedCategories = treeRef.getCheckedKeys(); // eslint-disable-line
    this.mainCategory = treeRef.getHalfCheckedKeys();
    this.$emit('updated', this.formData);
  }
  async getCategories() {
    try {
      // this.categories = await getCategoriesTree();
      this.categories = await (0, _categories.getCategoriesTreeForAdmin)();
      if (this.categories && this.categories.length) {
        this.categories.sort(function (a, b) {
          return a.id - b.id;
        });
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async saveForm() {
    this.loading = true;
    try {
      const categoriesToSave = {
        category: []
      };
      this.selectedCategories.forEach(id => {
        categoriesToSave.category.push({
          id: id
        });
      });
      if (this.mainCategory.length) {
        this.mainCategory.forEach(cateId => {
          categoriesToSave.category.push({
            id: cateId
          });
        });
      }
      const productsCategories = await (0, _products.updateProductCategories)(this.formData.id, categoriesToSave);
      this.formData.category = productsCategories;
      this.$notify({
        title: 'Success',
        message: 'Categories saved',
        type: 'success',
        duration: 2000
      });
      this.$emit('updated', this.formData);
      this.$emit('added', true);
      this.$emit('reload', true);
    } catch (err) {
      this.$notify.error({
        title: 'Failed to save',
        message: err + ''
      });
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductCategoryTab',
  components: {
    HelpText: _index.default
  }
})], default_1);
var _default = exports.default = default_1;