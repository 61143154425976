"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subMenusSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const subMenusSubMenu = exports.subMenusSubMenu = [{
  path: '/sub-menus/add/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/sub-menus/manage.vue'))),
  name: 'Manage Sub Menu',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.subMenus.manage',
    noCache: true,
    hidden: true
  }
}, {
  path: '/sub-menus/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/sub-menus/manage.vue'))),
  name: 'master.subMenus.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.subMenus.manage',
    noCache: true,
    hidden: true
  }
}, {
  path: '/sub-menus/list/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/sub-menus/list.vue'))),
  name: 'Sub Menu',
  meta: {
    title: 'master.subMenus.title',
    noCache: true,
    hidden: true,
    icon: 'series'
  }
}];