// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.admin-textarea textarea {\n  padding-right: 40px;\n  resize: none;\n  border: none;\n  border-radius: 0px;\n  border-bottom: 1px solid #bfcbd9;\n}\n.float-left {\n  float: left;\n}\n.errorColor {\n  color: #07953E;\n  margin-left: calc(30px + 25%);\n}\n.margin-top-10px {\n  margin-top: 10px;\n}\n.banner-block.form-block {\n  width: 55%;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#0A3378",
	"menuText": "#fff",
	"menuActiveText": "#409eff"
};
module.exports = exports;
