"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'series': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M301 181c-8.276 0-15 6.724-15 15s6.724 15 15 15 15-6.724 15-15-6.724-15-15-15zM151 91c-8.276 0-15 6.724-15 15s6.724 15 15 15 15-6.724 15-15-6.724-15-15-15zM211 271c-8.276 0-15 6.724-15 15s6.724 15 15 15 15-6.724 15-15-6.724-15-15-15z"/><path pid="1" d="M451 0H61C36.186 0 16 20.186 16 45v422c0 24.814 20.186 45 45 45h315V407c0-8.286 6.716-15 15-15h105V45c0-24.814-20.186-45-45-45zM241 452H121c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15zm-135-75c0-8.291 6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15h-60c-8.291 0-15-6.709-15-15zm60-106h2.763c6.213-17.422 22.707-30 42.237-30s36.024 12.578 42.237 30h101.552l-4.395-4.395c-5.859-5.859-5.859-15.352 0-21.211 5.859-5.859 15.352-5.859 21.211 0l29.982 29.982c5.836 5.819 5.872 15.39 0 21.248l-29.982 29.982c-5.859 5.859-15.352 5.859-21.211 0s-5.859-15.352 0-21.211l4.395-4.395H253.237c-6.213 17.422-22.707 30-42.237 30s-36.024-12.578-42.237-30H166c-33.091 0-60-26.909-60-60s26.909-60 60-60h92.763c6.213-17.422 22.707-30 42.237-30s36.024 12.578 42.237 30H346c16.538 0 30-13.462 30-30s-13.462-30-30-30H193.237c-6.213 17.422-22.707 30-42.237 30-24.814 0-45-20.186-45-45s20.186-45 45-45c19.53 0 36.024 12.578 42.237 30H346c33.091 0 60 26.909 60 60s-26.909 60-60 60h-2.763c-6.213 17.422-22.707 30-42.237 30s-36.024-12.578-42.237-30H166c-16.538 0-30 13.462-30 30s13.462 30 30 30z"/><path pid="2" d="M406 422v81.211L487.211 422z"/>'
  }
});