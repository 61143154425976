"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form settingForm",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Delivery Charge",
      prop: "deliveryCharge"
    }
  }, [_c("el-input-number", {
    staticClass: "width125",
    attrs: {
      name: "deliveryCharge",
      precision: 2,
      step: 1,
      min: 0
    },
    model: {
      value: _vm.formData.deliveryCharge,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "deliveryCharge", $$v);
      },
      expression: "formData.deliveryCharge"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Free Delivery Transaction Amount",
      prop: "freeDeliveryThreshold"
    }
  }, [_c("el-input-number", {
    staticClass: "width125",
    attrs: {
      name: "freeDeliveryThreshold",
      precision: 2,
      step: 1,
      min: 0
    },
    model: {
      value: _vm.formData.freeDeliveryThreshold,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "freeDeliveryThreshold", $$v);
      },
      expression: "formData.freeDeliveryThreshold"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Support Email",
      prop: "supportEmail"
    }
  }, [_c("el-input", {
    attrs: {
      name: "supportEmail"
    },
    model: {
      value: _vm.formData.supportEmail,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "supportEmail", $$v);
      },
      expression: "formData.supportEmail"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Support Contact",
      prop: "supportContact"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: 12,
      "show-word-limit": "",
      name: "supportContact"
    },
    model: {
      value: _vm.formData.supportContact,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "supportContact", $$v);
      },
      expression: "formData.supportContact"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Address"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2
    },
    model: {
      value: _vm.formData.address,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Home Video Link",
      prop: "homeVideoLink"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "homeVideoLink",
      required: ""
    },
    model: {
      value: _vm.formData.homeVideoLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "homeVideoLink", $$v);
      },
      expression: "formData.homeVideoLink"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Popular Search",
      prop: "popularSearch"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      "allow-create": "",
      "default-first-option": "",
      placeholder: "Enter popular search"
    },
    model: {
      value: _vm.formData.popularSearch,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "popularSearch", $$v);
      },
      expression: "formData.popularSearch"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Facebook Link",
      prop: "facebookLink"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "facebookLink",
      required: ""
    },
    model: {
      value: _vm.formData.facebookLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "facebookLink", $$v);
      },
      expression: "formData.facebookLink"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Instagram Link",
      prop: "instagramLink"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "instagramLink",
      required: ""
    },
    model: {
      value: _vm.formData.instagramLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "instagramLink", $$v);
      },
      expression: "formData.instagramLink"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Twitter Link",
      prop: "twitterLink"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "twitterLink",
      required: ""
    },
    model: {
      value: _vm.formData.twitterLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "twitterLink", $$v);
      },
      expression: "formData.twitterLink"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "LinkedIn Link",
      prop: "linkedinLink"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "linkedinLink",
      required: ""
    },
    model: {
      value: _vm.formData.linkedinLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "linkedinLink", $$v);
      },
      expression: "formData.linkedinLink"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Youtube Link",
      prop: "youtubeLink"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "youtubeLink",
      required: ""
    },
    model: {
      value: _vm.formData.youtubeLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "youtubeLink", $$v);
      },
      expression: "formData.youtubeLink"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Android Version",
      prop: "androidVersion"
    }
  }, [_c("el-input", {
    attrs: {
      name: "androidVersion"
    },
    model: {
      value: _vm.formData.androidVersion,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "androidVersion", $$v);
      },
      expression: "formData.androidVersion"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Android Force Update",
      prop: "androidForceUpdate"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.androidForceUpdate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "androidForceUpdate", $$v);
      },
      expression: "formData.androidForceUpdate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "IOS Version",
      prop: "iosVersion"
    }
  }, [_c("el-input", {
    attrs: {
      name: "iosVersion"
    },
    model: {
      value: _vm.formData.iosVersion,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "iosVersion", $$v);
      },
      expression: "formData.iosVersion"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "IOS Force Update",
      prop: "iosForceUpdate"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.iosForceUpdate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "iosForceUpdate", $$v);
      },
      expression: "formData.iosForceUpdate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Terms And Conditions",
      prop: "tnc"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "tnc",
      required: ""
    },
    model: {
      value: _vm.formData.tnc,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "tnc", $$v);
      },
      expression: "formData.tnc"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Privacy Policy",
      prop: "formData.privacyPolicy"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "privacyPolicy",
      required: ""
    },
    model: {
      value: _vm.formData.privacyPolicy,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "privacyPolicy", $$v);
      },
      expression: "formData.privacyPolicy"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("i", {
    staticClass: "el-icon-link"
  })])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "About Us",
      prop: "aboutUs"
    }
  }, [_c("file-upload", {
    attrs: {
      "file-list": _vm.aboutUsFile,
      "max-size": 5
    },
    on: {
      change: _vm.aboutUsFileUploaded
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;