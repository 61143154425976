"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _users = require("@/api/users");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _moment = _interopRequireDefault(require("moment"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.BASE_URL = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.userData = [];
    this.userName = '';
    this.mobile = '';
    this.email = '';
    this.dateRange = '';
    this.startDate = '';
    this.endDate = '';
    this.listQuery = {
      page: 1,
      limit: 10,
      orderReference: undefined,
      userId: undefined,
      'user.fullName': undefined,
      'user.mobileNumber': undefined,
      'user.emailAddress': undefined,
      sort: 'orderTimestamp,DESC',
      status: 'Order Placed',
      orderTimestamp: undefined,
      filter: {
        orderReference: '$startsL',
        userId: 'eq',
        status: 'eq',
        'user.fullName': '$contL',
        'user.mobileNumber': '$contL',
        'user.emailAddress': '$contL',
        orderTimestamp: 'between'
      }
    };
    this.value = [];
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'orderTimestamp,ASC'
    }, {
      label: 'ID Descending',
      key: 'orderTimestamp,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
    this.totalColumn = {
      total: 'Total',
      shippingCharges: 'Shipping Charges',
      discountAmount: 'Discount Amount',
      taxedAmount: 'Taxed Amount',
      grandTotal: 'Grand Total',
      amountReceived: 'Amount Received'
    };
    this.statusColorMap = {
      'Order Placed': 'primary',
      'Out for Delivery': 'warning',
      'Order Delivered': 'success',
      'Order Cancelled': 'danger',
      'In Cart': 'danger'
    };
    this.paymentColorMap = {
      online: 'success',
      cash: 'warning'
    };
    this.statusOptions = [{
      label: 'In Cart',
      key: 'in cart'
    }, {
      label: 'Order Placed',
      key: 'Order Placed'
    }, {
      label: 'Order Confirmed',
      key: 'Order Confirmed'
    }, {
      label: 'Order In Process',
      key: 'Order In Process'
    }, {
      label: 'Order Packed',
      key: 'Order Packed'
    }, {
      label: 'Ready for Pickup',
      key: 'Ready for Pickup'
    }, {
      label: 'Out for Delivery',
      key: 'Out for Delivery'
    }, {
      label: 'Order Delivered',
      key: 'Order Delivered'
    }, {
      label: 'Order Cancelled',
      key: 'Order Cancelled'
    }];
  }
  created() {
    if (this.$route.query && this.$route.query.status) {
      this.listQuery.status = this.$route.query.status;
    }
    this.getList();
    //  this.getUser()
  }
  async getList() {
    try {
      this.listLoading = true;
      if (this.listQuery.status === 'in cart') {
        this.listQuery.sort = 'id,DESC';
      }
      const data = await await (0, _order.getOrders)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getUser() {
    try {
      const data = await (0, _users.getUsers)((0, _index2.getQuery)({}));
      this.userData = data;
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  handleFilter() {
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.listQuery.orderTimestamp = [this.startDate, this.endDate].join(',');
    } else {
      this.listQuery.orderTimestamp = '';
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  async dateval() {
    this.getList();
  }
  async handleDownload() {
    const orderFilter = Object.assign({}, this.listQuery);
    delete orderFilter.limit;
    delete orderFilter.page;
    this.downloadLoading = true;
    const tHeader = ['Order ID', 'User', 'Contact No.', 'Email ID', 'Order Status', 'Item', 'Variant', 'MRP/Unit', 'Sales Price/Unit', 'Quantity', 'Total', 'Delivery Charges', 'Grand Total', 'Amount Received', 'Payment Method', 'Placed On'];
    const filterVal = ['id', 'user.fullName', 'user.mobileNumber', 'user.emailAddress', 'status', 'products', 'variant', 'unitPrice', 'salePrice', 'quantity', 'total', 'shippingCharges', 'grandTotal', 'amountReceived', 'paymentMethod', 'orderTimestamp'];
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      const exportData = await (0, _order.orderExport)({
        startDate: this.startDate,
        endDate: this.endDate,
        status: this.listQuery.status
      });
      const data = (0, _index2.formatJsonOrder)(filterVal, exportData);
      (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.orderManagement.orders.title'));
      this.downloadLoading = false;
    } else {
      this.$message({
        type: 'info',
        message: 'Please select a date range'
      });
      this.downloadLoading = false;
    }
  }
  getFormattedTimestamp(time) {
    return (0, _moment.default)(time).local().format('lll');
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrderList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;