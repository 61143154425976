"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'scale': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M19 0c-1.654 0-3 1.346-3 3v12c0 1.654 1.346 3 3 3h9v18H12.02c-.957 0-1.864.461-2.426 1.234L2.957 46.36A4.97 4.97 0 002 49.301V57c0 1.654 1.346 3 3 3h3v1c0 1.654 1.346 3 3 3s3-1.346 3-3v-1h36v1c0 1.654 1.346 3 3 3s3-1.346 3-3v-1h3c1.654 0 3-1.346 3-3v-7.7a4.97 4.97 0 00-.957-2.94l-6.637-9.126A3.007 3.007 0 0051.98 36H36V18h9c1.654 0 3-1.346 3-3V3c0-1.654-1.346-3-3-3H19zm0 2h26c.551 0 1 .449 1 1v12c0 .551-.449 1-1 1H19c-.551 0-1-.449-1-1v-1h19a1 1 0 000-2H18V3c0-.551.449-1 1-1zm22.082 10a1 1 0 000 2H43a1 1 0 000-2h-1.918zM30 18h4v4h-3.918c-.029 0-.053.015-.082.018V18zm0 5.982c.029.003.053.018.082.018H34v12h-4V23.982zM12.02 38h39.96c.32 0 .621.154.81.412l6.636 9.123c.107.147.195.304.273.465H4.301c.078-.161.166-.317.273-.463l6.637-9.125c.187-.258.49-.412.809-.412zM4 50h56v7c0 .551-.449 1-1 1H5c-.551 0-1-.449-1-1v-7zm3 2a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm-47 8h2v1c0 .551-.449 1-1 1-.551 0-1-.449-1-1v-1zm42 0h2v1c0 .551-.449 1-1 1-.551 0-1-.449-1-1v-1z"/>'
  }
});