"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _userSubMenu = require("./subMenus/users/user-sub-menu");
const customerRouter = {
  path: '/customer',
  component: _index.default,
  redirect: 'noredirect',
  name: 'customerManagement.title',
  meta: {
    title: 'customerManagement.title',
    icon: 'customer'
  },
  children: [{
    path: '/organization/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/organization/list.vue'))),
    name: 'Organization',
    meta: {
      icon: 'building',
      title: 'customerManagement.organization.title',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/organization/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/organization/manage.vue'))),
    name: 'customerManagement.organization.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'customerManagement.organization.edit',
      noCache: true,
      activeMenu: '/organization/list',
      hidden: true
    }
  }, {
    path: '/organization/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/organization/manage.vue'))),
    name: 'customerManagement.organization.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'customerManagement.organization.add',
      noCache: true,
      activeMenu: '/organization/list',
      hidden: true
    }
  }, {
    path: '/contact/list/:id',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/contact/list.vue'))),
    name: 'customerManagement.contact.title',
    meta: {
      title: 'customerManagement.contact.title',
      hidden: true,
      noCache: true
    }
  }, {
    path: '/contact/add/:id',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/contact/manage.vue'))),
    name: 'customerManagement.contact.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'customerManagement.contact.add',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/contact/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/contact/manage.vue'))),
    name: 'customerManagement.contact.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'customerManagement.contact.edit',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/address/list/:type/:id',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/list.vue'))),
    name: 'Address',
    meta: {
      title: 'customerManagement.address.title',
      hidden: true,
      noCache: true
    }
  }, {
    path: '/address/list/:id',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/list.vue'))),
    name: 'Address',
    meta: {
      title: 'customerManagement.address.title',
      hidden: true,
      noCache: true
    }
  }, {
    path: '/address/add/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/manage.vue'))),
    name: 'customerManagement.address.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'customerManagement.address.add',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/address/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/manage.vue'))),
    name: 'customerManagement.address.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'customerManagement.address.edit',
      noCache: true,
      hidden: true
    }
  }, ..._userSubMenu.userSubMenu]
};
var _default = exports.default = customerRouter;