"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _measurementUnit = require("@/api/master/measurement-unit");
var _taxes = require("@/api/master/taxes");
var _brands = require("@/api/brands");
var _utils = require("@/utils");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
var _sellers = require("@/api/sellers");
var _shapes = require("@/api/shapes");
var _colours = require("@/api/colours");
var _clarities = require("@/api/clarities");
var _finishes = require("@/api/finishes");
var _fluorescences = require("@/api/fluorescences");
var _index2 = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _admin = require("@/store/modules/admin");
var _cuts = require("@/api/cuts");
var _polishes = require("@/api/polishes");
var _symmetries = require("@/api/symmetries");
require("tinymce/tinymce");
require("tinymce/themes/silver");
require("tinymce/icons/default");
require("tinymce/skins/ui/oxide/skin.css");
var _tinymceVue = _interopRequireDefault(require("@tinymce/tinymce-vue"));
require("tinymce/plugins/lists");
require("tinymce/plugins/link");
require("tinymce/plugins/image");
require("tinymce/plugins/paste");
require("tinymce/plugins/help");
require("tinymce/plugins/wordcount");
require("tinymce/plugins/preview");
require("tinymce/plugins/table");
require("tinymce/plugins/code");
// plugins
// 'lists link image paste help wordcount preview table | code',

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.certificateFile = [];
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 3,
        message: 'Minimum length should be 3',
        trigger: 'blur'
      }],
      stoneId: [{
        required: true,
        message: 'Please Stone ID',
        trigger: 'blur'
      }],
      sellerId: [{
        required: true,
        message: 'Please Select Seller',
        trigger: 'blur'
      }],
      shapeId: [{
        required: true,
        message: 'Please Select shape',
        trigger: 'blur'
      }],
      caratWeight: [{
        required: true,
        message: 'Please enter Carat',
        trigger: 'blur'
      }],
      colourId: [{
        required: true,
        message: 'Please select Colour',
        trigger: 'blur'
      }],
      clarityId: [{
        required: true,
        message: 'Please select Clarity',
        trigger: 'blur'
      }],
      cutId: [{
        required: true,
        message: 'Please select Cut',
        trigger: 'blur'
      }],
      polishId: [{
        required: true,
        message: 'Please select Polish',
        trigger: 'blur'
      }],
      symmetryId: [{
        required: true,
        message: 'Please select Symmetry',
        trigger: 'blur'
      }],
      finishId: [{
        required: true,
        message: 'Please select Finish',
        trigger: 'blur'
      }],
      fluorescenceId: [{
        required: true,
        message: 'Please select Fluorescence',
        trigger: 'blur'
      }],
      parcelWeight: [{
        required: true,
        message: 'Please enter Parcel Weight',
        trigger: 'blur'
      }],
      diamondPieces: [{
        required: true,
        message: 'Please enter Diamond Pieces',
        trigger: 'blur'
      }],
      type: [{
        required: true,
        message: 'Please select Product Type',
        trigger: 'blur'
      }],
      certificateNumber: [{
        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/,
        message: 'Please enter Positive number',
        trigger: 'blur'
      }],
      taxId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
    this.productGenFormData = Object.assign({}, _products.defaultProductData);
    this.savedSimpleVariation = [];
    this.savedVariableVariations = [];
    this.activeName = '';
    this.inputTagVisible = false;
    this.inputTagValue = '';
    this.unitOfMeasurements = [];
    this.taxes = [];
    this.brandsList = [];
    this.productsList = [];
    this.selectedRelatedProduct = [];
    this.bulkStepper = 10;
    this.sellersList = [];
    this.shapesList = [];
    this.sizesList = [];
    this.coloursList = [];
    this.clarityList = [];
    this.finishList = [];
    this.fluorescenceList = [];
    this.cutList = [];
    this.polishList = [];
    this.symmetryList = [];
    this.loggedInAdmin = null;
    this.cutPolishSymmetryOptions = [{
      key: 'Excellent',
      value: 'Excellent'
    }, {
      key: 'Very Good',
      value: 'Very Good'
    }, {
      key: 'Good',
      value: 'Good'
    }];
    this.statusOptions = [{
      key: 'Pending',
      value: 'Pending'
    }, {
      key: 'Approved',
      value: 'Approved'
    }, {
      key: 'Rejected',
      value: 'Rejected'
    }];
    this.resultOptions = [{
      key: 'Pass',
      value: 'Pass'
    }, {
      key: 'Reject',
      value: 'Reject'
    }];
    this.typeOptions = [{
      key: 'Pointer',
      value: 'Pointer'
    }, {
      key: 'Parcel',
      value: 'Parcel'
    }];
    this.savedCaratWeight = 0;
  }
  submitForm(redirect = false) {
    this.$refs.productGenFormData.validate(valid => {
      if (valid) {
        this.saveForm();
        if (redirect) {
          _router.default.push({
            path: '/product/list',
            query: this.$route.query
          });
        }
      } else {
        this.$message.error('Failed to saved!');
        return false;
      }
    });
  }
  created() {
    // get logged in admin
    this.loggedInAdmin = _admin.AdminModule;
    // this.getMeasurementUnits()
    this.getTaxes();
    this.getBrandsList();
    this.getSellersList();
    this.getShapesList();
    // this.getSizesList()
    this.getColoursList();
    this.getClarityList();
    this.getFinishList();
    this.getFluorescenceList();
    this.getCutList();
    this.getPolishList();
    this.getSymmetryList();
    if (this.isEdit) {
      const productId = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(productId));
    }
    // this.getProductsList();
  }
  async fetchData(id) {
    try {
      const data = await (0, _products.getProductById)(id);
      this.productGenFormData = data;
      this.savedCaratWeight = this.productGenFormData.caratWeight;
      this.certificateFile.push(this.productGenFormData.certificateDetails);
      if (this.productGenFormData.productsVariation && this.productGenFormData.productsVariation.length) {
        this.productGenFormData.productsVariation.sort(function (a, b) {
          return a.id - b.id;
        });
      }
      if (this.productGenFormData.relatedProduct && this.productGenFormData.relatedProduct.length) {
        this.selectedRelatedProduct = this.productGenFormData.relatedProduct.map(relatedProduct => relatedProduct.id);
        const products = [];
        this.productGenFormData.relatedProduct.forEach(value => {
          products.push({
            id: value.id,
            name: value.name
          });
        });
        this.productsList = products;
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  toggleProductType() {
    // toggle single product
    if (this.productGenFormData.productType === 'simple') {
      this.productGenFormData.productsVariation = [];
      this.productGenFormData.productsVariation.push(Object.assign({
        enabled: true,
        allowBackOrder: false,
        manageStock: this.productGenFormData.manageStock,
        productId: this.productGenFormData.id || null,
        productsVariationsAttribute: [{
          productId: this.productGenFormData.id || null,
          attributeId: 1,
          attributesValueId: 1
        }],
        mrp: 0,
        unitPrice: 0,
        salePrice: 0,
        sku: '',
        stockQuantity: 0,
        stockStatus: 'In Stock'
      }, ...this.savedSimpleVariation // override with saved one
      ));
    } else {
      this.productGenFormData.productsVariation = this.savedVariableVariations;
    }
  }
  mounted() {
    if (this.productGenFormData.productType === 'simple') {
      this.savedSimpleVariation = this.productGenFormData.productsVariation;
    } else {
      this.savedVariableVariations = this.productGenFormData.productsVariation;
    }
    this.toggleProductType();
  }
  emitFormData() {
    this.toggleProductType();
    this.$emit('updated', this.productGenFormData);
  }
  async getMeasurementUnits() {
    try {
      this.unitOfMeasurements = await (0, _measurementUnit.getMeasurementUnits)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getTaxes() {
    try {
      this.taxes = await (0, _taxes.getTaxes)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getBrandsList() {
    try {
      this.brandsList = await (0, _brands.getBrands)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getSellersList() {
    try {
      const response = await (0, _sellers.getSellers)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.sellersList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getShapesList() {
    try {
      const response = await (0, _shapes.getShapes)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.shapesList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  // async getSizesList() {
  //   try {
  //     const response: any = await getSizes(
  //       getQuery({
  //         enabled: true,
  //         filter: {
  //           enabled: 'eq'
  //         }
  //       })
  //     )
  //     this.sizesList = response.data
  //   } catch (err) {} // eslint-disable-line  no-empty
  // }
  async getColoursList() {
    try {
      const response = await (0, _colours.getColours)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.coloursList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getClarityList() {
    try {
      const response = await (0, _clarities.getClarities)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.clarityList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getFinishList() {
    try {
      const response = await (0, _finishes.getFinishes)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.finishList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getFluorescenceList() {
    try {
      const response = await (0, _fluorescences.getFluorescences)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.fluorescenceList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getCutList() {
    try {
      const response = await (0, _cuts.getCuts)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.cutList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getPolishList() {
    try {
      const response = await (0, _polishes.getPolishes)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.polishList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getSymmetryList() {
    try {
      const response = await (0, _symmetries.getSymmetries)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
      this.symmetryList = response.data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getProductsList(query) {
    try {
      this.productsList = await (0, _products.getProducts)((0, _utils.getQuery)({
        enabled: true,
        name: query,
        filter: {
          enabled: 'eq',
          name: '$contL'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  certificateUploaded(file) {
    this.productGenFormData.certificateDetails = file.response;
  }
  async saveForm() {
    this.loading = true;
    try {
      this.productGenFormData.slug = this.productGenFormData.stoneId;
      if (this.productGenFormData.isOfflineSold && !this.productGenFormData.offlineMarkedByType) {
        this.productGenFormData.offlineMarkedByType = 'Admin';
        this.productGenFormData.offlineMarkedById = this.loggedInAdmin.id;
        this.productGenFormData.offlineMarkedByFullName = this.loggedInAdmin.fullName;
        this.productGenFormData.offlineMarkedTimestamp = new Date().toISOString();
      }
      if (this.productGenFormData.productType === 'simple') {
        // calculate discount percentage
        let discountPercentage = 0;
        let discountAmount = 0;
        if (this.productGenFormData.productsVariation[0].salePrice > 0) {
          discountAmount = this.productGenFormData.productsVariation[0].unitPrice - this.productGenFormData.productsVariation[0].salePrice;
          discountPercentage = discountAmount / this.productGenFormData.productsVariation[0].unitPrice * 100;
          this.productGenFormData.productsVariation[0].discountPercentage = discountPercentage;
          this.productGenFormData.productsVariation[0].discountAmount = discountAmount;
        } else {
          this.productGenFormData.productsVariation[0].discountPercentage = discountPercentage;
          this.productGenFormData.productsVariation[0].discountAmount = discountAmount;
        }
        this.productGenFormData.productsVariation[0] = {
          name: this.productGenFormData.name,
          mrp: this.productGenFormData.productsVariation[0].mrp,
          unitPrice: this.productGenFormData.productsVariation[0].unitPrice,
          salePrice: this.productGenFormData.productsVariation[0].salePrice,
          manageStock: this.productGenFormData.productsVariation[0].manageStock,
          stockStatus: this.productGenFormData.productsVariation[0].stockStatus,
          allowBackOrder: this.productGenFormData.productsVariation[0].allowBackOrder,
          stockQuantity: this.productGenFormData.productsVariation[0].stockQuantity,
          discountPercentage: this.productGenFormData.productsVariation[0].discountPercentage,
          discountAmount: this.productGenFormData.productsVariation[0].discountAmount,
          sku: this.productGenFormData.productsVariation[0].sku,
          id: this.productGenFormData.productsVariation[0].id
        };
        // update status = In Stock if manageStock and stockQuantity > 0
        if (this.productGenFormData.productsVariation[0].manageStock && this.productGenFormData.productsVariation[0].stockQuantity > 0) {
          this.productGenFormData.productsVariation[0].stockStatus = 'In Stock';
        }
        // update status = Out Of Stock if manageStock and stockQuantity <= 0
        if (this.productGenFormData.productsVariation[0].manageStock && this.productGenFormData.productsVariation[0].stockQuantity <= 0) {
          this.productGenFormData.productsVariation[0].stockStatus = 'Out Of Stock';
        }
        // this.productGenFormData.sku = this.productGenFormData.productsVariation[0].sku
      }
      let response = {};
      this.productGenFormData.relatedProduct = [];
      if (this.selectedRelatedProduct.length > 0) {
        this.selectedRelatedProduct.forEach(value => {
          this.productGenFormData.relatedProduct.push({
            id: value
          });
        });
      }
      if (this.isEdit) {
        this.productGenFormData.measurementUnit = {
          id: this.productGenFormData.unitOfMeasurementId
        };
        if (!this.productGenFormData.isTaxable) {
          this.productGenFormData.taxId = null;
        }
        this.productGenFormData.measurementUnitId = undefined;
        this.productGenFormData.brand = {
          id: this.productGenFormData.brandId
        };
        // set for shape
        this.productGenFormData.shape = {
          id: this.productGenFormData.shapeId
        }; // set for colour
        this.productGenFormData.colour = {
          id: this.productGenFormData.colourId
        };
        // set for clarity
        this.productGenFormData.clarity = {
          id: this.productGenFormData.clarityId
        };
        // set for cut
        this.productGenFormData.cut = {
          id: this.productGenFormData.cutId
        };
        // set for polish
        this.productGenFormData.polish = {
          id: this.productGenFormData.polishId
        };
        // set for symmetry
        this.productGenFormData.symmetry = {
          id: this.productGenFormData.symmetryId
        };
        // set for finish
        this.productGenFormData.finish = {
          id: this.productGenFormData.finishId
        };
        // set for fluorescence
        this.productGenFormData.fluorescence = {
          id: this.productGenFormData.fluorescenceId
        };
        //  update product
        response = await (0, _products.updateProduct)(this.productGenFormData.id, this.productGenFormData);
        // Yansh, test properly for updated
        // this.$emit("updated", this.productGenFormData);
        this.$emit('added', true);
        this.$emit('reload', true);
      } else {
        if (!this.productGenFormData.isTaxable) {
          this.productGenFormData.taxId = null;
        }
        delete this.productGenFormData.tax;
        delete this.productGenFormData.measurementUnit;
        delete this.productGenFormData.brand;
        this.productGenFormData.measurementUnitId = undefined;
        response = await (0, _products.createProduct)(this.productGenFormData);
        this.productGenFormData = response;
        this.isEdit = true;
        this.emitFormData();
        // to make other tabs available
        this.$emit('edited', this.isEdit);
      }
      this.$notify({
        title: 'Success',
        message: 'Product saved',
        type: 'success',
        duration: 2000
      });
    } catch (err) {
      this.$notify.error({
        title: 'Failed to save',
        message: err + ''
      });
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.productGenFormData.resetFields();
  }
  showTagInput() {
    this.inputTagVisible = true;
    this.focusTagInput();
  }
  focusTagInput() {
    this.$nextTick(() => {
      this.$refs.saveTagInput.$refs.input.focus();
    });
  }
  handleTagInputConfirm(event) {
    const inputValue = this.inputTagValue;
    if (inputValue) {
      let tags = [];
      if (this.productGenFormData.tags) {
        tags = this.productGenFormData.tags.split(',');
      }
      // push if not exist
      if (tags.indexOf(inputValue) === -1) {
        // jeff check for case insensitive
        tags.push(inputValue);
      } else {
        this.$message({
          showClose: true,
          message: inputValue + ' already exist!',
          type: 'warning'
        });
      }
      this.productGenFormData.tags = tags.join(',');
    }
    this.inputTagValue = '';
    // if entered
    if (event.keyCode && event.keyCode === 13) {
      this.focusTagInput();
    } else {
      this.inputTagVisible = false;
    }
  }
  handleTagClose(tag) {
    const tags = this.productGenFormData.tags.split(',');
    this.productGenFormData.tags = tags.splice(tags.indexOf(tag), 1).join(',');
  }
  createSlug(title) {
    // Change to lower case
    const titleLower = title.toLowerCase();
    // Trim the last whitespace
    let slug = titleLower.replace(/\s*$/g, '');
    // replace all special characters | symbols with a space
    slug = slug.replace(/[^\w\s]/gi, ' ');
    // Change whitespace to "-"
    slug = slug.replace(/\s+/g, '-');
    this.productGenFormData.slug = slug;
  }
  remoteMethod(query) {
    this.getProductsList(query);
  }
  async setRegularPrice(index = 0) {
    let taxRate = 0;
    if (this.productGenFormData.taxId) {
      // get tax rate
      const tax = await (0, _taxes.getTaxById)(this.productGenFormData.taxId);
      if (tax) {
        taxRate = Math.round(tax.rate);
      }
    }
    const mrp = this.productGenFormData.productsVariation[index].mrp;
    let regularPrice = 0;
    if (!isNaN(mrp)) {
      regularPrice = +(mrp / ((100 + taxRate) / 100)).toFixed(2);
    }
    this.productGenFormData.productsVariation[index].unitPrice = regularPrice;
  }
  productTypeChanged() {
    this.productGenFormData.diamondPieces = 1;
    this.productGenFormData.parcelWeight = 0;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductGeneralTab',
  components: {
    HelpText: _index.default,
    Editor: _tinymceVue.default,
    FileUpload: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;