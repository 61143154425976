"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateShape = exports.getShapes = exports.getShapeById = exports.fileTransferHeaders = exports.deleteShape = exports.defaultShapeData = exports.createShape = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultShapeData = exports.defaultShapeData = {
  id: 0,
  enabled: true,
  name: '',
  icon: '',
  priority: 0
};
const getShapes = params => (0, _request.default)({
  url: '/shapes',
  method: 'get',
  params
});
exports.getShapes = getShapes;
const getShapeById = id => (0, _request.default)({
  url: `/shapes/${id}`,
  method: 'get'
});
exports.getShapeById = getShapeById;
const updateShape = (id, data) => (0, _request.default)({
  url: `/shapes/${id}`,
  method: 'patch',
  data
});
exports.updateShape = updateShape;
const deleteShape = id => (0, _request.default)({
  url: `/shapes/${id}`,
  method: 'delete'
});
exports.deleteShape = deleteShape;
const createShape = data => (0, _request.default)({
  url: '/shapes/',
  method: 'post',
  data
});
exports.createShape = createShape;