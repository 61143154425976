"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceType = exports.AppModule = void 0;
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _cookies = require("@/utils/cookies");
var _lang = require("@/lang");
var _store = _interopRequireDefault(require("@/store"));
var DeviceType;
(function (DeviceType) {
  DeviceType[DeviceType["Mobile"] = 0] = "Mobile";
  DeviceType[DeviceType["Desktop"] = 1] = "Desktop";
})(DeviceType || (exports.DeviceType = DeviceType = {}));
let App = class App extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.sidebar = {
      opened: (0, _cookies.getSidebarStatus)() !== 'closed',
      withoutAnimation: false
    };
    this.device = DeviceType.Desktop;
    this.language = (0, _lang.getLocale)();
    this.size = (0, _cookies.getSize)() || 'medium';
  }
  TOGGLE_SIDEBAR(withoutAnimation) {
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
    if (this.sidebar.opened) {
      (0, _cookies.setSidebarStatus)('opened');
    } else {
      (0, _cookies.setSidebarStatus)('closed');
    }
  }
  CLOSE_SIDEBAR(withoutAnimation) {
    this.sidebar.opened = false;
    this.sidebar.withoutAnimation = withoutAnimation;
    (0, _cookies.setSidebarStatus)('closed');
  }
  TOGGLE_DEVICE(device) {
    this.device = device;
  }
  SET_LANGUAGE(language) {
    this.language = language;
    (0, _cookies.setLanguage)(this.language);
    (0, _lang.SetLanguageDirection)(this.language);
  }
  SET_SIZE(size) {
    this.size = size;
    (0, _cookies.setSize)(this.size);
  }
  ToggleSideBar(withoutAnimation) {
    this.TOGGLE_SIDEBAR(withoutAnimation);
  }
  CloseSideBar(withoutAnimation) {
    this.CLOSE_SIDEBAR(withoutAnimation);
  }
  ToggleDevice(device) {
    this.TOGGLE_DEVICE(device);
  }
  SetLanguage(language) {
    this.SET_LANGUAGE(language);
  }
  SetSize(size) {
    this.SET_SIZE(size);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], App.prototype, "TOGGLE_SIDEBAR", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], App.prototype, "CLOSE_SIDEBAR", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], App.prototype, "TOGGLE_DEVICE", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], App.prototype, "SET_LANGUAGE", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], App.prototype, "SET_SIZE", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], App.prototype, "ToggleSideBar", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], App.prototype, "CloseSideBar", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], App.prototype, "ToggleDevice", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], App.prototype, "SetLanguage", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], App.prototype, "SetSize", null);
App = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'app'
})], App);
const AppModule = exports.AppModule = (0, _vuexModuleDecorators.getModule)(App);