"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _transactions = require("@/api/transactions");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.proformaInvoiceFile = [];
    this.purchaseOrderFile = [];
    this.amountPaid = 0;
    this.referenceNo = '';
    this.paymentMethod = '';
    this.ordersProduct = [];
    this.formData = Object.assign({}, _order.defaultOrderData);
    this.loading = false;
    this.orderOptions = [{
      label: 'Order Placed',
      key: 'Order Placed'
    }, {
      label: 'Order Cancelled',
      key: 'Order Cancelled'
    }
    // { label: 'Partial Payment', key: 'Partial Payment' }
    ];
    this.paymentStatusOptions = [{
      label: 'Partial',
      key: 'Partial'
    }, {
      label: 'Completed',
      key: 'Completed'
    }];
    this.paymentMethodOptions = [{
      label: 'Bank Transfer',
      key: 'Bank Transfer'
    }, {
      label: 'UPI',
      key: 'UPI'
    }, {
      label: 'Card',
      key: 'Card'
    }];
    this.rules = {
      status: [{
        required: true,
        message: 'Please enter Status',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      proformaInvoiceNumber: [{
        required: true,
        message: 'Please enter Proforma Invoice Number',
        trigger: 'blur'
      }],
      proformaInvoiceFile: [{
        required: true,
        message: 'Please upload Proforma Invoice file',
        trigger: 'blur'
      }],
      invoiceFile: [{
        required: true,
        message: 'Please upload Invoice file',
        trigger: 'blur'
      }],
      // certificateNumber: [
      //   {
      //     required: true,
      //     message: 'Please enter Certificate Number',
      //     trigger: 'blur'
      //   }
      // ],
      // purchaseOrderNumber: [
      //   {
      //     required: true,
      //     message: 'Please enter Purchase Order Number',
      //     trigger: 'blur'
      //   }
      // ],
      // purchaseOrderFile: [
      //   {
      //     required: true,
      //     message: 'Please upload Purchase Order file',
      //     trigger: 'blur'
      //   }
      // ],
      courierName: [{
        required: true,
        message: 'Please enter Courier Name',
        trigger: 'blur'
      }],
      awbNumber: [{
        required: true,
        message: 'Please enter AWB Number',
        trigger: 'blur'
      }]
      // comments: [
      //   { required: true, message: 'Please enter comments', trigger: 'blur' },
      //   {
      //     min: 3,
      //     max: 150,
      //     message: 'Length should be 3 to 150',
      //     trigger: 'blur'
      //   }
      // ]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    // this.getCountryList()
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(id);
    } else {
      this.formData = Object.assign({}, _order.defaultOrderData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    const data = await (0, _order.getOrderById)(id);
    this.ordersProduct = data.ordersProduct;
    this.formData = data;
    this.orderOptions = [{
      label: 'Order Cancelled',
      key: 'Order Cancelled'
    }];
    switch (this.formData.status) {
      case 'Order Placed':
        this.orderOptions.unshift({
          label: 'Order Confirmed',
          key: 'Order Confirmed'
        });
        break;
      case 'Order Confirmed':
        this.orderOptions.unshift({
          label: 'Order In Process',
          key: 'Order In Process'
        });
        break;
      case 'Order In Process':
        this.orderOptions = [{
          label: 'Order Packed',
          key: 'Order Packed'
        }];
        break;
      case 'Order Packed':
        // pickup or delivery
        if (this.formData.orderPickupType === 'pickup') {
          this.orderOptions = [{
            label: 'Ready for Pickup',
            key: 'Ready for Pickup'
          }];
        } else {
          this.orderOptions = [{
            label: 'Out for Delivery',
            key: 'Out for Delivery'
          }];
        }
        break;
      case 'Ready for Pickup':
        this.orderOptions = [{
          label: 'Delivered',
          key: 'Order Delivered'
        }];
        break;
      case 'Out for Delivery':
        this.orderOptions = [{
          label: 'Delivered',
          key: 'Order Delivered'
        }];
        break;
    }
    if (this.formData.proformaInvoiceFile) {
      this.proformaInvoiceFile.push({
        url: this.formData.proformaInvoiceFile
      });
    }
    if (this.formData.purchaseOrderFile) {
      this.purchaseOrderFile.push({
        url: this.formData.purchaseOrderFile
      });
    }
    // Just for test
    const title = this.formData.orderReference || 'Edit';
    // Set tagsview title
    this.setTagsViewTitle(title);
    // Set page title
    this.setPageTitle(title);
  }
  async deleteOrdersProduct(id) {
    const response = await (0, _order.deleteOrdersProduct)(id);
    const orderId = this.$route.params && this.$route.params.id;
    this.fetchData(orderId);
  }
  proformaInvoiceFileUploaded(file) {
    this.formData.proformaInvoiceFile = file.response;
  }
  purchaseOrderFileUploaded(file) {
    this.formData.purchaseOrderFile = file.response;
  }
  invoiceFileUploaded(file) {
    this.formData.invoiceFile = file.response;
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.formData.status === 'Order In Process') {
        const transactionObj = {
          order: {
            id: this.formData.id
          },
          orderReference: this.referenceNo,
          status: 'PAID',
          amount: this.amountPaid,
          payment_source: this.paymentMethod
        };
        await (0, _transactions.createTransaction)(transactionObj);
      }
      if (this.isEdit) {
        await (0, _order.updateOrder)(this.formData.id, this.formData);
      } else {
        await (0, _order.createOrder)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'Order saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/order/list?status=' + this.formData.status);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrderDetail',
  components: {
    FileUpload: _index2.default,
    Inr: _index.default
  }
})], default_1);
var _default = exports.default = default_1;