// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.form-block {\n  width: auto;\n}\n.form-block.orderView {\n  width: auto;\n}\n.marginBottom10 {\n  margin-bottom: 10px;\n}\n.admin-textarea textarea {\n  padding-right: 40px;\n  resize: none;\n  border: none;\n  border-radius: 0px;\n  border-bottom: 1px solid #bfcbd9;\n}\n.text-center {\n  text-align: center;\n}\n.bold {\n  font-weight: bold;\n}\n.hidden {\n  display: none;\n}\n.el-divider--horizontal {\n  margin: 10px 0;\n}\n.lineHeight {\n  line-height: 25px;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#0A3378",
	"menuText": "#fff",
	"menuActiveText": "#409eff"
};
module.exports = exports;
