"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$orderData$user;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block orderView"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_c("h2", {
    staticClass: "sub-title"
  }, [_vm._v(" Order & Account Information ")]), _c("el-divider"), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("p", {
    staticClass: "sub-title"
  }, [_c("b", [_vm._v("Order #" + _vm._s(_vm.orderData.orderReference))])]), _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", [_c("div", {
    staticClass: "sub-title lineHeight"
  }, [_vm._v(" Order Status : " + _vm._s(_vm.orderData.status) + " ")])]), _c("el-col", [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v(" Order Placed on : " + _vm._s(_vm._f("moment")(_vm.orderData.orderTimestamp, "MMMM Do YYYY, hh:mm a")) + " ")])])], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("p", {
    staticClass: "sub-title"
  }, [_c("b", [_vm._v("Account Information")])]), _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", [_c("div", {
    staticClass: "grid-content bg-purple"
  }, [_c("div", {
    staticClass: "sub-title lineHeight"
  }, [_vm._v(" Customer Name : " + _vm._s(_vm.orderData.user.firstName + " " + ((_vm$orderData$user = _vm.orderData.user) === null || _vm$orderData$user === void 0 ? void 0 : _vm$orderData$user.lastName)) + " ")])])]), _c("el-col", [_c("div", {
    staticClass: "grid-content bg-purple"
  }, [_c("div", {
    staticClass: "sub-title lineHeight"
  }, [_vm._v(" Mobile : " + _vm._s(_vm.orderData.user.mobileNumber) + " ")])])]), _c("el-col", [_c("div", {
    staticClass: "grid-content bg-purple"
  }, [_c("div", {
    staticClass: "sub-title lineHeight"
  }, [_vm._v(" Email : " + _vm._s(_vm.orderData.user.emailAddress) + " ")])])])], 1)], 1)], 1), _vm.orderData.deliveryType !== "Store Pickup" ? _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_c("h2", {
    staticClass: "sub-title"
  }, [_vm._v(" Address Information ")]), _c("el-divider"), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4", [_vm._v("Billing Address")]), _vm.orderData.addressId ? _c("div", [_c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("Name: " + _vm._s(_vm.orderData.address.name))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("Mobile: " + _vm._s(_vm.orderData.address.mobileNumber))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("Address: " + _vm._s(_vm.orderData.address.line1 + " " + (_vm.orderData.address.line2 ? _vm.orderData.address.line2 : ""))), _c("br"), _vm._v(" " + _vm._s(_vm.orderData.address.city.name) + ", " + _vm._s(_vm.orderData.address.state.name) + ", " + _vm._s(_vm.orderData.address.pincode.pincode)), _c("br"), _vm._v(" India")])]) : _vm._e()])])], 1), _vm.orderData.orderPickupType !== "pickup" ? _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4", [_vm._v("Shipping Address")]), _vm.orderData.shippingId ? _c("div", [_c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("Name: " + _vm._s(_vm.orderData.shippingAddress.name))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("Mobile: " + _vm._s(_vm.orderData.shippingAddress.mobileNumber))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("Address: " + _vm._s(_vm.orderData.shippingAddress.line1 + " " + (_vm.orderData.shippingAddress.line2 ? _vm.orderData.shippingAddress.line2 : ""))), _c("br"), _vm._v(" " + _vm._s(_vm.orderData.shippingAddress.city.name) + ", " + _vm._s(_vm.orderData.shippingAddress.state.name) + ", " + _vm._s(_vm.orderData.shippingAddress.pincode.pincode)), _c("br"), _vm._v(" India")])]) : _vm._e()])])], 1) : _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4", [_vm._v("This is Pickup Order")])])])], 1)], 1) : _vm._e(), _vm.transactionDetails ? _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_c("h2", {
    staticClass: "sub-title"
  }, [_vm._v(" Payment Information ")]), _c("el-divider"), _c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4"), _c("div", [_c("span", {
    staticClass: "lineHeight"
  }, [_c("b", [_vm._v("Payment Method: ")]), _vm._v(_vm._s(_vm.transactionDetails.payment_source))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_c("b", [_vm._v("Reference No: ")]), _vm._v(_vm._s(_vm.transactionDetails.orderReference))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_c("b", [_vm._v("Amount Paid: ")]), _vm._v(_vm._s(_vm.transactionDetails.amount))]), _c("br"), _c("span", {
    staticClass: "lineHeight"
  }, [_c("b", [_vm._v("Payment Status: ")]), _vm._v(_vm._s(_vm.orderData.paymentStatus))]), _c("br")])])])], 1) : _vm._e(), _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_c("h2", {
    staticClass: "sub-title"
  }, [_vm._v(" Order Details ")]), _c("el-divider"), _vm.orderData.proformaInvoiceNumber ? _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4", [_vm._v(" Proforma Invoice Details ")]), _c("div", [_c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("No. - " + _vm._s(_vm.orderData.proformaInvoiceNumber)), _c("br")]), _c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("File - "), _c("el-link", {
    attrs: {
      href: _vm.orderData.proformaInvoiceFile,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v("Download")])], 1)])])]) : _vm._e(), _c("el-col", {
    attrs: {
      lg: 12,
      sm: 14
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4", [_vm._v(" Purchase Order Details ")]), _vm.orderData.purchaseOrderDate ? _c("div", [_c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("No. - " + _vm._s(_vm.orderData.purchaseOrderNumber)), _c("br")]), _c("span", {
    staticClass: "lineHeight"
  }, [_vm._v(" File - "), _c("el-link", {
    attrs: {
      href: _vm.orderData.purchaseOrderFile,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v("Download")])], 1)]) : _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Purchase Order No",
      prop: "purchaseOrderNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "purchaseOrderNumber",
      placeholder: "Purchase Order Number"
    },
    model: {
      value: _vm.formData.purchaseOrderNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "purchaseOrderNumber", $$v);
      },
      expression: "formData.purchaseOrderNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Purchase Order File",
      prop: "purchaseOrderFile"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/common/upload",
      field: "file",
      "file-list": _vm.purchaseOrderFile,
      accept: ".pdf",
      description: "(Note: PDF size should be less than 1mb)",
      "max-size": "1",
      "button-text": "Upload File",
      "list-type": "text"
    },
    on: {
      change: _vm.purchaseOrderFileUploaded
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary",
      size: "medium"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)])])])], 1), _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_vm.orderData.invoiceFile ? _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_c("h4", [_vm._v(" Invoice Details ")]), _c("div", [_c("span", {
    staticClass: "lineHeight"
  }, [_vm._v("File - "), _c("el-link", {
    attrs: {
      href: _vm.orderData.invoiceFile,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v("Download")])], 1)])])]) : _vm._e()], 1), _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_c("h2", {
    staticClass: "sub-title"
  }, [_vm._v(" Items Ordered ")]), _c("el-divider"), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.orderData.ordersProduct,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Product",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.product.stoneId)), _c("br"), scope.row.product.productType === "variable" ? _c("span", [_vm._v(" " + _vm._s(scope.row.productsVariation.name)), _c("br")]) : _vm._e(), _vm._v(" " + _vm._s(scope.row.productsVariation.sku) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "seller",
      label: "Seller",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.product.seller.companyName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Unit Price",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.unitPrice
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Sale Price",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.salePrice
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tax",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.taxedAmount
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "quantity",
      label: "Quantity",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Sub Total",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.subTotal
          }
        })];
      }
    }])
  })], 1)], 1), _c("el-row", {
    staticClass: "marginBottom10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("p", {
    staticClass: "sub-title lineHeight"
  }, [_c("b", [_vm._v("Grand Total: "), _c("inr", {
    attrs: {
      number: _vm.orderData.grandTotal
    }
  })], 1)])])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;