"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _ordersSubMenu = require("./subMenus/orders/orders-sub-menu");
const orderRouter = {
  path: '/order',
  component: _index.default,
  redirect: 'noredirect',
  name: 'orderManagement.title',
  meta: {
    title: 'orderManagement.title',
    icon: 'shopping'
  },
  children: [..._ordersSubMenu.ordersSubMenu
  // ...couponsSubMenu
  ]
};
var _default = exports.default = orderRouter;