"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.claritiesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const claritiesSubMenu = exports.claritiesSubMenu = [{
  path: '/clarity/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/clarity/list.vue'))),
  name: 'clarities',
  meta: {
    title: 'productManagement.clarity.title',
    noCache: true,
    hidden: false,
    icon: 'clarity'
  }
}, {
  path: '/clarity/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/clarity/manage.vue'))),
  name: 'productManagement.clarity.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.clarity.manage',
    noCache: true,
    activeMenu: '/clarity/list',
    hidden: true
  }
}, {
  path: '/clarity/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/clarity/manage.vue'))),
  name: 'productManagement.clarity.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.clarity.manage',
    noCache: true,
    activeMenu: '/clarity/list',
    hidden: true
  }
}];