"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block banner-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _vm.formData.bannerFor === "mobileApp" ? _c("el-form-item", {
    staticClass: "margin-top-10px",
    attrs: {
      label: "Banner Position",
      prop: "bannerPosition"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "bannerPosition",
      required: ""
    },
    on: {
      change: _vm.emitFormData
    },
    model: {
      value: _vm.formData.bannerPosition,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "bannerPosition", $$v);
      },
      expression: "formData.bannerPosition"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Header",
      value: "header"
    }
  }), _c("el-option", {
    attrs: {
      label: "Footer",
      value: "footer"
    }
  })], 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Image",
      prop: "image"
    }
  }, [_c("file-upload", {
    staticClass: "float-left",
    attrs: {
      url: "/banners/upload",
      field: "image",
      "file-list": _vm.image,
      width: 1920,
      height: 853,
      description: "Image has to be in 1920px * 853px"
    },
    on: {
      change: _vm.imageUploaded
    }
  })], 1), _c("el-form-item", {
    staticClass: "margin-top-10px",
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "priority",
      required: "",
      placeholder: "Priority"
    },
    model: {
      value: _vm.formData.priority,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "priority", $$v);
      },
      expression: "formData.priority"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Validity",
      prop: "validity"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "default-time": ["00:00:00", "23:59:59"],
      "picker-options": {
        disabledDate: _vm.isDateAllowed
      }
    },
    model: {
      value: _vm.validity,
      callback: function ($$v) {
        _vm.validity = $$v;
      },
      expression: "validity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Page",
      prop: "page"
    }
  }, [_c("el-select", {
    attrs: {
      name: "page",
      clearable: "",
      required: "",
      filterable: "",
      "auto-complete": "off"
    },
    model: {
      value: _vm.formData.page,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "page", $$v);
      },
      expression: "formData.page"
    }
  }, _vm._l(_vm.pageList, function (page) {
    return _c("el-option", {
      key: page,
      attrs: {
        label: page,
        value: page
      }
    });
  }), 1)], 1), _vm.formData.page === "Link" ? _c("el-form-item", {
    attrs: {
      label: "Link",
      prop: "link"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "link",
      required: ""
    },
    model: {
      value: _vm.formData.link,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "link", $$v);
      },
      expression: "formData.link"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v(" https:// ")])], 2)], 1) : _vm._e(), _vm.formData.page === "Categories" ? _c("el-form-item", {
    attrs: {
      label: "Category",
      prop: "categoryId"
    }
  }, [_c("el-select", {
    attrs: {
      name: "categoryId",
      clearable: "",
      filterable: "",
      "auto-complete": "off",
      placeholder: "Select",
      required: ""
    },
    model: {
      value: _vm.formData.categoryId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "categoryId", $$v);
      },
      expression: "formData.categoryId"
    }
  }, _vm._l(_vm.categoryList, function (category) {
    return _c("el-option", {
      key: category.id,
      attrs: {
        label: category.name,
        value: category.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.page === "Product" ? _c("el-form-item", {
    attrs: {
      label: "Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "productId",
      clearable: "",
      filterable: "",
      required: ""
    },
    model: {
      value: _vm.formData.productId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productId", $$v);
      },
      expression: "formData.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.page === "Brand" ? _c("el-form-item", {
    attrs: {
      label: "Brand",
      prop: "brandId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "brandId",
      required: ""
    },
    model: {
      value: _vm.formData.brandId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "brandId", $$v);
      },
      expression: "formData.brandId"
    }
  }, _vm._l(_vm.brandList, function (brand) {
    return _c("el-option", {
      key: brand.id,
      attrs: {
        label: brand.name,
        value: brand.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;