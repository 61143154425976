"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _staticPages = require("@/api/static-pages");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _sortablejs = _interopRequireDefault(require("sortablejs"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.sortable = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      title: undefined,
      sort: 'priority,ASC',
      filter: {
        title: '$contL'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    this.getList();
    this.$nextTick(() => {
      this.setSort();
    });
  }
  setSort() {
    // tslint:disable-next-line no-undef: "error"
    const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: 'sortable-ghost',
      setData: function (dataTransfer) {
        // to avoid Firefox bug
        // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        dataTransfer.setData('Text', '');
      },
      onEnd: evt => {
        const targetRow = this.list.splice(evt.oldIndex, 1)[0];
        this.list.splice(evt.newIndex, 0, targetRow);
        this.reCalculatePriority();
      }
    });
  }
  reCalculatePriority() {
    this.list.forEach((image, index) => {
      image.priority = index + 1;
      console.log(image);
      this.updatePriority(image.id, image.priority);
    });
  }
  async updatePriority(id, priority) {
    const update = await (0, _staticPages.updateStaticPage)(id, {
      priority: priority
    });
    if (update) {
      this.getList();
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _staticPages.getStaticPages)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'fullname', 'email', 'contact', 'role'];
    const filterVal = ['id', 'fullName'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'StaticPageList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;