"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reviewCount = exports.getReviews = exports.defaultUserData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUserData = exports.defaultUserData = {
  id: 0,
  rating: '',
  suggestions: '',
  comments: ''
};
const getReviews = params => (0, _request.default)({
  url: '/reviews',
  method: 'get',
  params
});
exports.getReviews = getReviews;
const reviewCount = params => (0, _request.default)({
  url: '/reviews/count',
  method: 'get',
  params
});
exports.reviewCount = reviewCount;