"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block setting-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "180px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Minimum Order Value (₹)",
      prop: "minimumOrderAmount"
    }
  }, [_c("el-input-number", {
    staticClass: "width125",
    attrs: {
      name: "minimumOrderAmount",
      step: 1,
      min: 0
    },
    model: {
      value: _vm.formData.minimumOrderAmount,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "minimumOrderAmount", $$v);
      },
      expression: "formData.minimumOrderAmount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hold Time (in hours)",
      prop: "holdTime"
    }
  }, [_c("el-input-number", {
    staticClass: "width125",
    attrs: {
      name: "holdTime",
      step: 1,
      min: 0
    },
    model: {
      value: _vm.formData.holdTime,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "holdTime", $$v);
      },
      expression: "formData.holdTime"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Min Carat Weight",
      prop: "minCaratWeight"
    }
  }, [_c("el-input-number", {
    staticClass: "width125",
    attrs: {
      name: "minCaratWeight",
      step: 1,
      prcesion: 2,
      min: 0
    },
    model: {
      value: _vm.formData.minCaratWeight,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "minCaratWeight", $$v);
      },
      expression: "formData.minCaratWeight"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Max Carat Weight",
      prop: "maxCaratWeight"
    }
  }, [_c("el-input-number", {
    staticClass: "width125",
    attrs: {
      name: "maxCaratWeight",
      step: 1,
      prcesion: 2,
      min: 0
    },
    model: {
      value: _vm.formData.maxCaratWeight,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "maxCaratWeight", $$v);
      },
      expression: "formData.maxCaratWeight"
    }
  })], 1), _c("el-divider", [_vm._v(" Address ")]), _c("el-form-item", {
    attrs: {
      label: "Address"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2
    },
    model: {
      value: _vm.formData.address,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;