"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _cities = require("@/api/locations/cities");
var _states = require("@/api/locations/states");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _cities.defaultCityData);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 50,
        message: 'Length should be 3 to 50',
        trigger: 'blur'
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _cities.defaultCityData);
      this.formData.stateId = parseInt(this.$route.params && this.$route.params.stateId);
      // fetch state to get countryId
      this.fetchState(this.formData.stateId);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _cities.getCityById)(id);
      this.formData = data;
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchState(stateId) {
    try {
      const stateData = await (0, _states.getStateById)(stateId);
      this.formData.countryId = stateData.countryId;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `Edit ${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `Edit ${title}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        this.formData.country.id = this.formData.countryId;
        this.formData.state.id = this.formData.stateId;
        await (0, _cities.updateCity)(this.formData.id, this.formData);
      } else {
        delete this.formData.country;
        delete this.formData.state;
        await (0, _cities.createCity)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'City added successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/cities/list/' + this.formData.stateId);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'CityDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;