"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBrand = exports.getBrands = exports.getBrandById = exports.fileTransferHeaders = exports.deleteBrand = exports.defaultBrandData = exports.createBrand = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultBrandData = exports.defaultBrandData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  description: ''
};
const getBrands = params => (0, _request.default)({
  url: '/brands',
  method: 'get',
  params
});
exports.getBrands = getBrands;
const getBrandById = id => (0, _request.default)({
  url: `/brands/${id}`,
  method: 'get'
});
exports.getBrandById = getBrandById;
const updateBrand = (id, data) => (0, _request.default)({
  url: `/brands/${id}`,
  method: 'patch',
  data
});
exports.updateBrand = updateBrand;
const deleteBrand = id => (0, _request.default)({
  url: `/brands/${id}`,
  method: 'delete'
});
exports.deleteBrand = deleteBrand;
const createBrand = data => (0, _request.default)({
  url: '/brands/',
  method: 'post',
  data
});
exports.createBrand = createBrand;