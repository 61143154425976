"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'marketing': {
    width: 16,
    height: 16,
    viewBox: '0 0 502.671 502.671',
    data: '<g _fill="#010002"><path pid="0" d="M0 204.203h88.764v261.33H0zM502.664 447.219L396.708 341.221l-13.762 8.391-20.881-20.233c32.615-45.773 28.732-109.58-12.295-150.629-2.804-2.804-5.997-4.918-9.017-7.356V5.363h-88.699v140.404c-12.921 1.639-25.454 5.457-37.339 11.411v-42.667h-88.634v351.043h88.613v-99.635c11.885 5.932 24.44 9.75 37.339 11.389v88.225h88.699v-103.13l13.676 13.18-7.873 15.811 105.977 105.913s23.038.302 36.929-15.574c13.956-15.855 13.223-34.514 13.223-34.514zM203.175 197.71c35.096-35.247 92.323-35.268 127.57-.022 35.203 35.268 35.225 92.323-.065 127.677-35.182 35.182-92.345 35.225-127.613-.043-35.246-35.203-35.225-92.365.108-127.612z"/></g>'
  }
});