"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _router = _interopRequireDefault(require("@/router"));
var _utils = require("@/utils");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _products = require("@/api/products");
var _index2 = _interopRequireDefault(require("@/components/HelpText/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
    this.coverImageId = 0;
    this.sortable = null;
    this.variationsList = [{
      name: 'No Variation',
      id: null
    }];
  }
  fileUploaded(file) {
    /* eslint-disable */
    this.formData.productsImage.push({
      isCover: false,
      image: file.response,
      priority: this.formData.productsImage.length + 1
    });
    // check if only one image then make it cover
    if (this.formData.productsImage.length === 1) {
      this.formData.productsImage[0].isCover = true;
    }
    /* eslint-enable */
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  created() {
    this.$nextTick(() => {
      this.setSort();
    });
    this.getVariationsList();
  }
  activated() {
    if (this.formData.productsImage && this.formData.productsImage.length) {
      this.formData.productsImage.sort(function (a, b) {
        return a.priority - b.priority;
      });
    }
  }
  async getVariationsList() {
    const variations = await (0, _products.getProductsVariations)((0, _utils.getQuery)({
      enabled: true,
      productId: this.formData.id,
      filter: {
        enabled: 'eq',
        productId: 'eq'
      }
    }));
    if (variations && variations.length) {
      variations.forEach(variation => {
        this.variationsList.push({
          name: variation.name,
          id: variation.id
        });
      });
    }
  }
  handleProductTypeChange() {
    this.$emit('updated', this.formData);
  }
  async saveForm() {
    this.loading = true;
    try {
      await (0, _products.updateProductImages)(this.formData.id, {
        productsImage: this.formData.productsImage
      });
      this.$notify({
        title: 'Success',
        message: 'Images saved',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/product/list');
    } catch (err) {
      this.$notify.error({
        title: 'Failed to save',
        message: err + ''
      });
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  handleCoverChange(rowIndex) {
    if (rowIndex in this.formData.productsImage) {
      // disabled active
      this.formData.productsImage.forEach((image, index) => {
        if (rowIndex !== index) {
          image.isCover = false;
        }
      });
      // enable this
      this.formData.productsImage[rowIndex].isCover = true;
      this.handleProductTypeChange();
    }
  }
  async handleDelete(image, index = -1) {
    if (image) {
      try {
        await this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
          name: image.id
        }), this.$tc('table.delete'), {
          confirmButtonText: this.$tc('table.delete'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Cancel',
          type: 'error'
        });
        if (image.id) {
          await (0, _products.deleteProductImages)(image.id);
        }
        // remove this from the list
        if (index > -1) {
          this.formData.productsImage.splice(index, 1);
        }
        this.reCalculatePriority();
        this.handleProductTypeChange();
      } catch (error) {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      }
    }
  }
  setSort() {
    // tslint:disable-next-line no-undef: "error"
    const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: 'sortable-ghost',
      setData: function (dataTransfer) {
        // to avoid Firefox bug
        // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        dataTransfer.setData('Text', '');
      },
      onEnd: evt => {
        const targetRow = this.formData.productsImage.splice(evt.oldIndex, 1)[0];
        this.formData.productsImage.splice(evt.newIndex, 0, targetRow);
        this.reCalculatePriority();
        this.handleProductTypeChange();
      }
    });
  }
  reCalculatePriority() {
    this.formData.productsImage.forEach((image, index) => {
      image.priority = index + 1;
    });
  }
  loadImage(image) {
    if (image.indexOf('-sm') !== -1) {
      image = image.replace('-sm', '-md');
    }
    // image = image + '-md'
    return image;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductImagesTab',
  components: {
    FileUpload: _index.default,
    HelpText: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;