"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _sellersSubMenu = require("./subMenus/sellers/sellers-sub-menu");
var _sizesMarkupsSubMenu = require("./subMenus/products/sizes-markups-sub-menu");
const sellerRouter = {
  path: '/seller',
  component: _index.default,
  redirect: 'noredirect',
  name: 'sellerManagement.title',
  meta: {
    title: 'sellerManagement.title',
    icon: 'seller-management'
  },
  children: [..._sellersSubMenu.sellersSubMenu,
  // ...sellersUsersRoleSubMenu,
  // ...sellersUsersSubMenu,
  ..._sizesMarkupsSubMenu.sizesMarkupsSubMenu]
};
var _default = exports.default = sellerRouter;