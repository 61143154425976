"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellersUser = exports.getSellersUsers = exports.getSellersUserBySellerId = exports.getSellersUserById = exports.deleteSellersUser = exports.defaultSellersUserData = exports.createSellersUser = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSellersUserData = exports.defaultSellersUserData = {
  id: 0,
  enabled: true,
  firstName: '',
  lastName: '',
  mobileNumber: undefined,
  mobileNumberVerified: false,
  emailAddress: undefined,
  emailAddressVerified: false,
  gender: undefined,
  avatar: undefined,
  sellersUsersRoleId: 1,
  sellerId: undefined,
  lastLogin: '',
  sellersUsersRole: undefined,
  seller: undefined,
  countryCode: undefined
};
const getSellersUsers = params => (0, _request.default)({
  url: '/sellers-users',
  method: 'get',
  params
});
exports.getSellersUsers = getSellersUsers;
const getSellersUserById = id => (0, _request.default)({
  url: `/sellers-users/${id}`,
  method: 'get'
});
exports.getSellersUserById = getSellersUserById;
const updateSellersUser = (id, data) => (0, _request.default)({
  url: `/sellers-users/${id}`,
  method: 'patch',
  data
});
exports.updateSellersUser = updateSellersUser;
const deleteSellersUser = id => (0, _request.default)({
  url: `/sellers-users/${id}`,
  method: 'delete'
});
exports.deleteSellersUser = deleteSellersUser;
const createSellersUser = data => (0, _request.default)({
  url: '/sellers-users/',
  method: 'post',
  data
});
exports.createSellersUser = createSellersUser;
const getSellersUserBySellerId = sellerId => (0, _request.default)({
  url: `/sellers-users/seller/${sellerId}`,
  method: 'get'
});
exports.getSellersUserBySellerId = getSellersUserBySellerId;