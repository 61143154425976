"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array-buffer.detached.js");
require("core-js/modules/es.array-buffer.transfer.js");
require("core-js/modules/es.array-buffer.transfer-to-fixed-length.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.to-reversed.js");
require("core-js/modules/es.typed-array.to-sorted.js");
require("core-js/modules/es.typed-array.with.js");
require("core-js/modules/esnext.set.difference.v2.js");
require("core-js/modules/esnext.set.intersection.v2.js");
require("core-js/modules/esnext.set.is-disjoint-from.v2.js");
require("core-js/modules/esnext.set.is-subset-of.v2.js");
require("core-js/modules/esnext.set.is-superset-of.v2.js");
require("core-js/modules/esnext.set.symmetric-difference.v2.js");
require("core-js/modules/esnext.set.union.v2.js");
require("core-js/modules/web.dom-exception.stack.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _products = require("@/api/products");
var _jszip = _interopRequireDefault(require("jszip"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _jimp = _interopRequireDefault(require("jimp"));
var _axios = _interopRequireDefault(require("axios"));
var _jpegJs = _interopRequireDefault(require("jpeg-js"));
var _index = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = {
      file: ''
    };
    this.acceptableFormats = ['application/zip'];
    this.fieldsMap = {
      'Product Id': 'id',
      'Product Name': 'name',
      'Product Type': 'productType',
      Featured: 'featured',
      Category: 'category',
      'Attributes & Values': 'attributesValues',
      SKU: 'sku',
      'Regular Price': 'unitPrice',
      'Sale Price': 'salePrice',
      'Stock Status': 'stockStatus',
      'Stock Quantity': 'stockQuantity',
      'Allow backorders': 'allowBackOrder',
      Tax: 'taxRate',
      'HSN Code': 'hsnCode',
      Priority: 'priority',
      Images: 'Images',
      'Short Description': 'shortDescription',
      Tags: 'tags'
    };
    this.file = null;
    this.fileTemp = null;
    this.fileExcelToJsonProgressing = false;
    this.fileRead = false;
    this.productNameIndexMap = {};
    this.productData = [];
    this.groupData = [];
    this.processingProduct = false;
    this.currentlyProcessingIndex = -1;
    this.processingLength = 10;
    this.processedProducts = 0;
    this.failedProducts = 0;
    this.pauseProcessingProduct = false;
    this.isLessThanHundred = true;
    this.importExcelBinary = null;
    // private importExcel: any = [];
    this.images = {};
    this.uploading = false;
    this.statusColorMap = {
      Pending: 'success',
      Approved: 'primary',
      Rejected: 'danger'
    };
    this.uploadStatusesMap = {
      '': 'info',
      pending: 'info',
      uploading: 'warning',
      uploaded: 'primary',
      failed: 'danger'
    };
  }
  reset() {
    this.postForm.file = '';
    this.fileExcelToJsonProgressing = false;
    this.fileRead = false;
    this.productData = [];
    this.productNameIndexMap = {};
    this.processingProduct = false;
    this.currentlyProcessingIndex = -1;
    this.processedProducts = 0;
    this.failedProducts = 0;
    this.pauseProcessingProduct = false;
  }
  async created() {
    _jimp.default.decoders['image/jpeg'] = data => _jpegJs.default.decode(data, {
      maxMemoryUsageInMB: 1024 * 2
    });
  }
  async handleChange(file) {
    this.reset();
    this.fileExcelToJsonProgressing = true;
    try {
      const isZIP = ['application/zip', 'application/x-zip-compressed'].indexOf(file.raw.type) > -1;
      if (!isZIP) {
        return this.$message.error('File must be in ZIP format! \nWe found:' + file.raw.type);
      }
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        return this.$message.error('File size can not exceed 50MB!');
      }
      await this.extractZip(file);
      await this.processExcel();
      // load next 10 images
      await this.loadImages();
      this.fileRead = true;
    } catch (error) {
      this.$notify.error(error + '');
    } finally {
      this.fileExcelToJsonProgressing = false;
    }
  }
  async extractZip(file) {
    const This = this;
    const zip = new _jszip.default();
    return new _jszip.default.external.Promise(function (resolve, reject) {
      _jszip.default.loadAsync(file.raw).then(function (zip) {
        // loop over
        for (const fileName in zip.files) {
          const file = zip.files[fileName];
          // read Excel
          if (fileName === 'import/import.xlsx') {
            This.importExcelBinary = file;
          } else if (!file.dir) {
            // check if file is image
            let fileName = file.name;
            const isImage = fileName.split('.').pop().match(/(jpg|jpeg|png)/);
            if (isImage) {
              // remove import/ from file name
              fileName = file.name.replace('import/', '');
              // set
              This.images[fileName] = file;
            }
          }
        }
        // return promise
        resolve(true);
      });
    });
    // });
  }
  async processExcel() {
    const This = this;
    const zip = new _jszip.default();
    if (!this.importExcelBinary) {
      throw new Error('Excel is empty');
    }
    return new _jszip.default.external.Promise(function (resolve, reject) {
      This.importExcelBinary.async('nodebuffer').then(fileData => {
        var workbook = _xlsx.default.read(fileData, {
          type: 'array'
        });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        var outData = _xlsx.default.utils.sheet_to_json(firstSheet, {
          defval: null
        });
        This.clearData(outData);
        resolve(true);
      });
    });
  }
  // Processing method when exceeding the maximum number of uploaded files
  handleExceed() {
    this.$message({
      type: 'warning',
      message: 'Exceed the maximum number of uploaded files limit!'
    });
  }
  async loadImages() {
    try {
      const promises = [];
      // loop
      for (var index = this.currentlyProcessingIndex + 1; index < this.currentlyProcessingIndex + 1 + this.processingLength; index++) {
        // get row
        const row = this.productData[index];
        if (row) {
          // adding index for later processing
          row.index = index;
          // only if image is present
          if (!row.imageNotAvailable && !row.imageLoaded) {
            const file = row.Images in this.images ? this.images[row.Images] : null;
            if (file) {
              promises.push(this.getBase64(file, index));
            }
          }
        }
      }
      if (promises.length > 0) {
        // run all promises
        const results = await Promise.allSettled(promises);
        const successfulPromises = results.filter(p => p.status === 'fulfilled');
        const errors = results.filter(p => p.status === 'rejected');
        // return
        return successfulPromises.length;
      }
      return;
    } catch (error) {
      this.$message({
        type: 'warning',
        message: error.toString()
      });
    }
  }
  async getBase64(file, index) {
    const This = this;
    return new _jszip.default.external.Promise(function (resolve, reject) {
      file.async('base64').then(fileData => {
        This.productData[index].file = 'data:image/jpeg;base64,' + fileData;
        This.productData[index].imageLoaded = true;
        resolve(true);
      }).catch(error => {
        console.log(error);
      });
    });
  }
  getImageIntoBase64(event, scope) {
    const This = this;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // this.importExcel.forEach((row: any, i: number) => {
    // if (This.importExcel.index === scope.row.index) {
    reader.onload = function () {
      This.productData[scope.row.index].file = reader.result;
      This.productData[scope.row.index].imageLoaded = true;
      return true;
    };
    // }
    // });
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  // How to remove files
  handleRemove(file, fileList) {
    this.fileTemp = null;
  }
  importfxx(obj) {
    this.fileRead = false;
    this.fileExcelToJsonProgressing = true;
    const _this = this;
    const inputDOM = this.$refs.inputer;
    // Retrieving file data through DOM
    this.file = this.fileTemp;
    var rABS = false; // Read the file as a binary string
    var f = this.file;
    var reader = new FileReader();
    // if (!FileReader.prototype.readAsBinaryString) {
    FileReader.prototype.readAsBinaryString = function (f) {
      var binary = '';
      var rABS = false; // Read the file as a binary string
      var pt = this;
      var wb; // Read completed data
      let outdata = [];
      var reader = new FileReader();
      reader.onload = function (e) {
        var result = reader.result;
        var bytes = new Uint8Array(result);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        // If not introduced in main.js, you need to introduce it here to parse excel
        // var XLSX = require("xlsx");
        if (rABS) {
          wb = _xlsx.default.read(btoa(_this.fixdata(binary)), {
            // Manual conversion
            type: 'base64'
          });
        } else {
          wb = _xlsx.default.read(binary, {
            type: 'binary'
          });
        }
        _this.fileRead = true;
        _this.fileExcelToJsonProgressing = false;
        outdata = _xlsx.default.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          defval: null
        });
        _this.clearData(outdata);
      };
      reader.readAsArrayBuffer(f);
    };
    if (rABS) {
      reader.readAsArrayBuffer(f);
    } else {
      reader.readAsBinaryString(f);
    }
  }
  fixdata(data) {
    let o = '';
    let l = 0;
    const w = 10240;
    for (; l < data.byteLength / w; ++l) {
      const sliced = new Uint8Array(data.slice(l * w, l * w + w));
      o += String.fromCharCode.apply(null, sliced);
    }
    const sliced = new Uint8Array(data.slice(l * w));
    o += String.fromCharCode.apply(null, sliced);
    return o;
  }
  clearData(excelProductData = []) {
    // loop
    for (let index = 1; index < excelProductData.length; index++) {
      const product = this.getProductToBeProcessed(excelProductData[index]);
      product.uploadedToServer = false;
      // check if status is null or doesn't exist
      if (!('status' in product) || product.status === null) {
        // if status is null or doesn't exist, then add it to the list
        product.status = 'Approved';
      }
      if (product) {
        // delete id
        if (product.id) {
          delete product.id;
        }
        // check for enabled
        product.enabled = true;
        product.multiplier = 1;
        // Featured
        // handle null to N
        product.featured = product.featured || 'N';
        product.featured = product.featured.toUpperCase() === 'Y';
        // tax
        product.isTaxable = !!product.taxRate;
        product.category = product.category.trim();
        // manageStock
        // handle null to N
        product.manageStock = product.manageStock || 'N';
        // check if typeof is not string
        if (typeof product.manageStock !== 'string') {
          product.manageStock = 'N';
        }
        // check if manageStock is not Y or N
        if (product.manageStock.toUpperCase() !== 'Y' || product.manageStock.toUpperCase() !== 'N') {
          product.manageStock = 'N';
        }
        // null handle
        product.stockQuantity = +(product.stockQuantity || 0);
        // check if manageStock is N and stockQuantity is non zero, then make it Y
        if (product.manageStock.toUpperCase() === 'N') {
          if (product.stockQuantity > 0) {
            product.manageStock = 'Y';
          }
        }
        product.manageStock = product.manageStock.toUpperCase() === 'Y';
        // system managed
        if (product.manageStock) {
          // zero handle
          product.stockQuantity = +(product.stockQuantity || 1);
          // handle null to N
          product.allowBackOrder = product.allowBackOrder || 'N';
          product.allowBackOrder = product.allowBackOrder.toUpperCase() === 'Y';
          product.stockStatus = product.stockQuantity > 0 ? 'In Stock' : 'Out Of Stock';
        } else {
          // admin managed
          // handle null to N
          product.stockStatus = product.stockStatus || 'N';
          product.stockStatus = product.stockStatus.toUpperCase() === 'Y' ? 'In Stock' : 'Out Of Stock';
          product.allowBackOrder = false;
          product.stockQuantity = 0;
        }
        const unitPrice = product.unitPrice;
        const salePrice = product.salePrice || 0;
        const discountAmount = +(salePrice ? unitPrice - salePrice : 0).toFixed(2);
        // variation
        const variation = {
          enabled: true,
          name: product.name,
          salePrice: salePrice,
          unitPrice: unitPrice,
          discountAmount,
          discountPercentage: discountAmount ? +(discountAmount / unitPrice * 100).toFixed(2) : 0,
          sku: product.sku,
          image: {},
          manageStock: product.manageStock,
          stockStatus: product.stockStatus,
          stockQuantity: product.stockQuantity,
          allowBackOrder: product.allowBackOrder,
          productsVariationsAttribute: []
        };
        // attribute
        if (product.attributesValues) {
          // eg Weight~10 kg, Weight~5 kg
          const attributesValues = product.attributesValues.split(',');
          // get only the attributes
          product.attribute = [];
          // handle null
          product.productType = product.productType || 'simple';
          // check if variable
          if (product.productType.toLowerCase() === 'variable') {
            variation.name = '';
          }
          attributesValues.forEach(attributesValue => {
            const attributesValueSplit = attributesValue.split('~');
            const attribute = attributesValueSplit[0].trim();
            // add if not added
            if (product.attribute.indexOf(attribute) === -1) {
              product.attribute.push(attribute);
            }
            // add to variation
            variation.productsVariationsAttribute.push({
              enabled: true,
              attribute: attribute,
              attributesValue: attributesValueSplit[1].trim()
            });
            variation.name += attributesValueSplit[1].trim() + ' ';
          });
        }
        // add productsImage
        product.file = null;
        product.uploadStatus = 'pending'; // uploading & uploaded (failed)
        product.imageLoaded = false;
        product.productsImage = [];
        // push if not exist by id
        if (product.productType === 'simple') {
          // default variation
          product.productsVariation = JSON.stringify([variation]);
          if (product.productsImage.length) {
            product.productsImage[0].isCover = true;
          }
          // push
          const pushedIndex = this.productData.push(product) - 1;
          this.productNameIndexMap[product.name] = pushedIndex;
        } else {
          // check if id present, then push for new variation
          if (!(product.name in this.productNameIndexMap)) {
            product.productsVariation = JSON.stringify([]);
            const pushedIndex = this.productData.push(product) - 1;
            this.productNameIndexMap[product.name] = pushedIndex;
          }
          const pushedIndex = this.productNameIndexMap[product.name];
          // add variation to product
          const productsVariationParsed = JSON.parse(this.productData[pushedIndex].productsVariation);
          productsVariationParsed.push(variation);
          this.productData[pushedIndex].productsVariation = JSON.stringify(productsVariationParsed);
          // add images (merge + unique)
          this.productData[pushedIndex].productsImage = [...new Set([...this.productData[pushedIndex].productsImage, ...product.productsImage])];
          // check for cover
          if (this.productData[pushedIndex].productsImage.length) {
            this.productData[pushedIndex].productsImage[0].isCover = true;
          }
        }
      }
    }
    // stringify productsImages
    this.productData.forEach(product => {
      product.productsImage = JSON.stringify(product.productsImage);
    });
  }
  async processProduct() {
    if (this.currentlyProcessingIndex < this.productData.length - 1) {
      // increase index
      this.currentlyProcessingIndex++;
      const productToBeProcessed = this.currentlyProcessingIndex in this.productData ? {
        ...this.productData[this.currentlyProcessingIndex]
      } : null;
      if (!productToBeProcessed) {
        return;
      }
      try {
        var _response$errors, _response$successfulP;
        this.processedProducts++;
        this.pauseProcessingProduct = false;
        this.processingProduct = true;
        //  mubasshir
        // delete file
        delete productToBeProcessed.file;
        this.productData[productToBeProcessed.index].uploadStatus = 'uploading';
        const response = await (0, _products.importProduct)([productToBeProcessed]);
        // check if any of these failed
        if ((_response$errors = response.errors) !== null && _response$errors !== void 0 && _response$errors.length) {
          response.errors.forEach(failed => {
            if (failed.reason) {
              this.productData[productToBeProcessed.index].uploadStatus = 'failed';
              this.productData[productToBeProcessed.index].failedReason = failed.reason.message;
              this.failedProducts++;
            }
          });
        } else if ((_response$successfulP = response.successfulPromises) !== null && _response$successfulP !== void 0 && _response$successfulP.length) {
          this.productData[productToBeProcessed.index].uploadedToServer = true;
          // add id to product
          this.productData[productToBeProcessed.index].id = response.successfulPromises[0].value.id;
          if (this.productData[productToBeProcessed.index].file) {
            // upload image
            const uploadedURL = await this.uploadImage(this.productData[productToBeProcessed.index].file);
            if (uploadedURL) {
              // map uploaded to product
              const productsImage = await (0, _products.addNewProductsImages)({
                productId: this.productData[productToBeProcessed.index].id,
                image: uploadedURL,
                isCover: true,
                priority: 1,
                isLocal: false
              });
              this.productData[productToBeProcessed.index].imageLoaded = true;
              this.productData[productToBeProcessed.index].file = productsImage.image;
              this.productData[productToBeProcessed.index].uploadedImage = productsImage;
            } else {
              this.productData[productToBeProcessed.index].imageLoaded = false;
            }
          } else {
            this.productData[productToBeProcessed.index].imageLoaded = false;
          }
          // update status
          this.productData[productToBeProcessed.index].uploadStatus = 'uploaded';
        }
      } catch (error) {
        this.$message({
          type: 'warning',
          message: error.toString()
        });
        // update status
        this.productData[productToBeProcessed.index].uploadStatus = 'failed';
        this.productData[productToBeProcessed.index].failedReason = error.toString();
        this.failedProducts++;
        // check if it has been saved then delete from db
        if (this.productData[productToBeProcessed.index].id) {
          await (0, _products.deleteProduct)(this.productData[productToBeProcessed.index].id);
        } // end if
      } finally {
        this.processingProduct = false;
        if (this.currentlyProcessingIndex >= this.productData.length) {
          this.currentlyProcessingIndex = -1;
        } else {
          // pause for a while
          setTimeout(async () => {
            if (!this.pauseProcessingProduct) {
              await this.loadImages();
              await this.processProduct();
            }
          });
        }
      }
    }
  }
  async uploadImage(base64FileString) {
    // convert base64 string to file
    const file = await this.dataURLtoFile(base64FileString, 'file.png');
    // upload file to server
    if (file) {
      const url = await this.uploadFile(file, 'common/upload');
      if (url) {
        return url.toString();
      }
    }
    return null;
  }
  async dataURLtoFile(dataurl, filename) {
    if (!dataurl) {
      return null;
    }
    // check if string is not base64
    if (!dataurl.startsWith('data:image')) {
      return null;
    }
    // get mime from base64
    const mime = dataurl.split(',')[0].split(':')[1].split(';')[0];
    const withOutMine = dataurl.replace(/^data:image\/\w+;base64,/, '');
    // download file
    const imgBuffer = Buffer.from(withOutMine, 'base64');
    // Jimp read
    const image = await _jimp.default.read(imgBuffer);
    if (image) {
      // resize Jimp of 500 center white
      image.resize(500, _jimp.default.AUTO).background(0xffffffff).contain(500, 500, _jimp.default.HORIZONTAL_ALIGN_CENTER | _jimp.default.VERTICAL_ALIGN_MIDDLE).quality(80);
      // get buffer
      const buffer = await image.getBufferAsync(mime);
      // create file
      const file = new File([buffer], filename, {
        type: mime
      });
      return file;
    }
    return null;
  }
  getProductToBeProcessed(productToBeProcessed = {}) {
    if (productToBeProcessed) {
      const product = {};
      // convert to our desired format
      for (const key in productToBeProcessed) {
        const value = productToBeProcessed[key];
        // check if this key exist in Map
        if (key in this.fieldsMap) {
          product[this.fieldsMap[key]] = value;
        }
      }
      return product;
    }
    return false;
  }
  async createThumbnail(url = null) {
    try {
      if (!url) {
        this.$notify.error('Invalid URL');
        return;
      }
      // download image as file
      const remoteImage = await this.downloadRemoteFile(url);
      // create thumbnail on server side
      const thumbnailURL = await this.uploadFile(remoteImage, 'products/upload');
      return thumbnailURL;
    } catch (error) {
      this.$notify.error(error + '');
    }
  }
  async downloadRemoteFile(url = null) {
    try {
      if (!url) {
        return new Error('Invalid URL');
      }
      const fileName = url.split('#').shift().split('?').shift().split('/').pop();
      const response = await _axios.default.get(url, {
        responseType: 'blob'
      });
      if (response.status === 200) {
        // get blob
        const file = new File([response.data], fileName, {
          type: response.data.type
        });
        return file;
      }
      return new Error('Failed to download');
    } catch (error) {
      return error;
    }
  }
  async uploadFile(file = null, remoteURL = null) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      // upload to server
      const response = await _axios.default.post(process.env.VUE_APP_BASE_API + remoteURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // add auth bearer
          Authorization: `Bearer ${_admin.AdminModule.token}`
        }
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
  getVariantPrice(variationsJSON = null, field = 'unitPrice') {
    if (variationsJSON) {
      const variations = JSON.parse(variationsJSON);
      if (variations.length) {
        const prices = variations.map(variation => {
          return variation[field];
        });
        return prices[0] || '-';
      }
    }
    return 'NA';
  }
  getUploadStatusType(uploadStatus = '') {
    return this.uploadStatusesMap[uploadStatus];
  }
  getTableRowClassName(row) {
    if (row.row.imageNotAvailable) {
      return 'error-row';
    }
    if (row.row.uploadStatus === 'failed') {
      return 'error-row';
    }
    if (row.row.uploadStatus === 'uploaded') {
      return 'success-row';
    }
    return '';
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductImport',
  components: {
    Enabled: _index.default
  }
})], default_1);
var _default = exports.default = default_1;