"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.citiesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const citiesSubMenu = exports.citiesSubMenu = [{
  path: '/cities/list/:stateId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "cities-list" */'@/views/locations/cities/list.vue'))),
  name: 'Cities',
  meta: {
    title: 'locations.cities.title',
    activeMenu: '/states/list',
    noCache: true,
    hidden: true
  }
}, {
  path: '/cities/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "cities-list" */'@/views/locations/cities/manage.vue'))),
  name: 'Edit City',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.cities.edit',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/cities/add/:stateId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/cities/manage.vue'))),
  name: 'Add City',
  props: {
    isEdit: false
  },
  meta: {
    title: 'Add City',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}];