"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _users = require("@/api/users");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _moment = _interopRequireDefault(require("moment"));
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.open = false;
    this.listQuery = {
      page: 1,
      limit: 10,
      code: undefined,
      firstName: undefined,
      emailAddress: undefined,
      mobileNumber: undefined,
      enabled: undefined,
      sort: 'id,DESC',
      filter: {
        emailAddress: '$contL',
        firstName: '$contL',
        mobileNumber: '$startsL',
        enabled: 'eq'
      }
    };
    this.loggedInAdmin = _admin.AdminModule;
    this.value = [];
    this.options = [{
      value: 'organization',
      label: 'Organization'
    }];
    this.columns = {
      createdDate: false,
      emailAddress: false,
      mobileNumber: false,
      organization: false
    };
    this.statusColorMap = {
      Pending: 'success',
      Approved: 'primary',
      Rejected: 'danger'
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    window.addEventListener('click', this.close);
    this.getList();
  }
  beforeDestroy() {
    window.removeEventListener('click', this.close);
  }
  toggle() {
    this.open = !this.open;
  }
  close(e) {
    if (!this.$el.contains(e.target)) {
      this.open = false;
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _users.getUsers)((0, _index2.getQuery)(this.listQuery));
      // add random lifetimeOrderValue & totalOrders
      this.list = data.data.map(item => {
        item.lastOrderTimestamp = this.getRandomDateFromLastMonth();
        item.lastOrderTotal = this.getRandomOrderValue(100, 1000);
        item.lifetimeOrderValue = this.getRandomOrderValue(10000, 100000);
        item.totalOrders = +this.getRandomOrderValue(1, 100);
        item.averageOrderValue = (+item.lifetimeOrderValue / item.totalOrders).toFixed(2);
        return item;
      });
      // this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleColumn() {
    for (const c in this.columns) {
      this.columns[c] = this.value.includes(c);
    }
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'enabled', 'Full Name', 'Mobile Number', 'Email Address', 'Address', 'Registration Date'];
    const filterVal = ['id', 'enabled', 'fullName', 'mobileNumber', 'emailAddress', 'address', 'registeredOn'];
    const userFilter = Object.assign({}, this.listQuery);
    delete userFilter.limit;
    delete userFilter.page;
    const data2 = await (0, _users.getUsers)((0, _index2.getQuery)(userFilter));
    const data = (0, _index2.formatJsonUser)(filterVal, data2);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
  getRandomDateFromLastMonth() {
    const start = new Date();
    start.setMonth(start.getMonth() - 1);
    const end = new Date();
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }
  getRandomOrderValue(min = 10, max = 10000) {
    return Math.floor(Math.random() * (max - min + 1) + min).toFixed(2);
  }
  getFormattedTimestamp(time) {
    return (0, _moment.default)(time).utc(true).local().format('LLLL');
  }
  getFormattedTimestampInWords(time) {
    return (0, _moment.default)(time).fromNow(true) + ' ago';
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'WalletPlanList',
  components: {
    Pagination: _index.default,
    Enabled: _index4.default,
    Inr: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;