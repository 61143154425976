"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateNewsletter = exports.getNewsletters = exports.getNewsletterById = exports.fileTransferHeaders = exports.deleteNewsletter = exports.defaultNewsletterData = exports.createNewsletter = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultNewsletterData = exports.defaultNewsletterData = {
  id: 0,
  enabled: true,
  emailAddress: ''
};
const getNewsletters = params => (0, _request.default)({
  url: '/newsletters',
  method: 'get',
  params
});
exports.getNewsletters = getNewsletters;
const getNewsletterById = id => (0, _request.default)({
  url: `/newsletters/${id}`,
  method: 'get'
});
exports.getNewsletterById = getNewsletterById;
const updateNewsletter = (id, data) => (0, _request.default)({
  url: `/newsletters/${id}`,
  method: 'patch',
  data
});
exports.updateNewsletter = updateNewsletter;
const deleteNewsletter = id => (0, _request.default)({
  url: `/newsletters/${id}`,
  method: 'delete'
});
exports.deleteNewsletter = deleteNewsletter;
const createNewsletter = data => (0, _request.default)({
  url: '/newsletters/',
  method: 'post',
  data
});
exports.createNewsletter = createNewsletter;