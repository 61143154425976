"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePincode = exports.importPincode = exports.getPincodes = exports.getPincodeById = exports.fileTransferHeaders = exports.deletePincode = exports.defaultPincodeData = exports.createPincode = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultPincodeData = exports.defaultPincodeData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  country: {
    id: null
  },
  stateId: undefined,
  state: {
    id: null
  },
  cityId: undefined,
  city: {
    id: null
  },
  pincode: '',
  pincodeName: '',
  isDeliverable: null
};
const getPincodes = params => (0, _request.default)({
  url: '/pincodes',
  method: 'get',
  params
});
exports.getPincodes = getPincodes;
const getPincodeById = id => (0, _request.default)({
  url: `/pincodes/${id}`,
  method: 'get'
});
exports.getPincodeById = getPincodeById;
const updatePincode = (id, data) => (0, _request.default)({
  url: `/pincodes/${id}`,
  method: 'patch',
  data
});
exports.updatePincode = updatePincode;
const deletePincode = id => (0, _request.default)({
  url: `/pincodes/${id}`,
  method: 'delete'
});
exports.deletePincode = deletePincode;
const createPincode = data => (0, _request.default)({
  url: '/pincodes/',
  method: 'post',
  data
});
exports.createPincode = createPincode;
const importPincode = data => (0, _request.default)({
  url: '/pincodes/singleImport',
  method: 'post',
  data
});
exports.importPincode = importPincode;