"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _settings = require("@/api/settings");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/HelpText/index.vue"));
var _products = require("@/api/products");
var _utils = require("@/utils");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.products = [];
    this.selectedProduct = [];
    this.websiteMenuImageList = [];
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _settings.defaultSettingData);
    this.tncFile = [];
    this.privacyPolicyFile = [];
    this.aboutUsFile = [];
    this.loading = false;
    this.helpFile = [];
    this.options = [];
    this.rules = {
      deliveryCharge: [{
        required: true,
        message: 'Please enter Delivery Charge',
        trigger: 'blur'
      }],
      freeDeliveryThreshold: [{
        required: true,
        message: 'Please enter Free Delivery Threshold',
        trigger: 'blur'
      }],
      orderCutOffTime: [{
        required: true,
        message: 'Please enter Cutoff Time',
        trigger: 'blur'
      }],
      deliveryDiscount: [{
        required: true,
        message: 'Please enter Delivery Discount',
        trigger: 'blur'
      }],
      discountDeliveryDays: [{
        required: true,
        message: 'Please enter Discount Delivery Days',
        trigger: 'blur'
      }],
      maximumCashAllowed: [{
        required: true,
        message: 'Please enter Maximum Cash On Delivery Allowed',
        trigger: 'blur'
      }],
      maximumDeliveryDays: [{
        required: true,
        message: 'Please enter Maximum Delivery Days',
        trigger: 'blur'
      }],
      maximumDeliveryDiscount: [{
        required: true,
        message: 'Please enter Maximum Delivery Discount',
        trigger: 'blur'
      }],
      supportEmail: [{
        required: true,
        message: 'Please enter email',
        trigger: 'blur'
      }, {
        type: 'email',
        required: true,
        message: 'Please enter valid support email',
        trigger: 'change'
      }],
      supportContact: [{
        required: true,
        message: 'Please enter support contact number',
        trigger: 'blur'
      }, {
        min: 10,
        max: 13,
        message: 'Length should be between 10 and 13',
        trigger: ['blur', 'change']
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.getProductsList();
    this.fetchData(1);
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _settings.defaultSettingData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _settings.getSettingById)(id);
      this.formData = data;
      if (this.formData.product && this.formData.product.length) {
        this.selectedProduct = this.formData.product.map(product => product.id);
      }
      // Just for test
      const title = 'Edit Setting';
      if (this.formData && data.websiteMenuImage) {
        this.websiteMenuImageList.push({
          name: data.title,
          url: data.websiteMenuImage
        });
      }
      this.aboutUsFile.push({
        name: data.aboutUs,
        url: data.aboutUs
      });
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (error) {
      // this.$message.error(error.message || error);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  helpFileUploaded(file) {
    this.formData.helpFile = file.response;
  }
  tncFileUploaded(file) {
    this.formData.tnc = file.response;
  }
  privacyPolicyUploaded(file) {
    this.formData.privacyPolicy = file.response;
  }
  aboutUsFileUploaded(file) {
    this.formData.aboutUs = file.response;
  }
  setPageTitle(title) {
    document.title = `${title}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.product = [];
      if (this.selectedProduct.length > 0) {
        this.selectedProduct.forEach(value => {
          this.formData.product.push({
            id: value
          });
        });
      }
      if (this.isEdit) {
        await (0, _settings.updateSetting)(this.formData.id, this.formData);
      } else {
        await (0, _settings.createSetting)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'Settings saved successfully',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      //   router.push("/setting/list");
    } catch (error) {
      this.loading = false;
    }
  }
  async getProductsList() {
    try {
      this.products = await (0, _products.getProducts)((0, _utils.getQuery)({
        enabled: true,
        isOfflineSold: false,
        status: 'Approved',
        orderStatus: 'Available',
        filter: {
          enabled: 'eq',
          isOfflineSold: 'eq',
          status: '$eq',
          orderStatus: '$eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SettingDetail',
  components: {
    FileUpload: _index.default,
    HelpText: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;