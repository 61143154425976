"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'clarity': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M340.3 367l27.7-27.7 26.3 26.3c3.2 3.1 8.2 3 11.3-.2 3-3.1 3-8 0-11.1l-27.4-27.4c6.7-12.3 4.5-27.6-5.5-37.5l-15.8-15.8c12.6-25.3 19.1-53.3 19.1-81.6C376 90.5 293.5 8 192 8S8 90.5 8 192s82.5 184 184 184c28.3 0 56.2-6.5 81.6-19.1l15.8 15.8c10.3 10.4 26.3 12.4 38.8 4.9l123.1 123.1c3.2 3.1 8.2 3 11.3-.1 3-3.1 3-8.1 0-11.2L340.3 367zM24 192C24 99.4 99.4 24 192 24s168 75.4 168 168-75.4 168-168 168S24 284.6 24 192zm276.7 169.4l-12.6-12.6c24.7-15.2 45.5-36 60.7-60.7l12.6 12.6c6.2 6.3 6.2 16.4 0 22.6l-38.1 38.1c-6.2 6.2-16.4 6.2-22.6 0z" _fill="#0a3378"/><path pid="1" d="M475.7 424.3l-72-72c-3.2-3.1-8.2-3-11.3.2-3 3.1-3 8 0 11.1l72 72c3.2 3.1 8.2 3 11.3-.2 3-3 3-8 0-11.1zM192 56.5c-33 0-64.9 12-89.6 33.9-3.1 2.8-3.4 7.5-.7 10.6s7.5 3.4 10.6.7c49.9-43.9 125.9-39.1 169.8 10.8s39.1 125.9-10.8 169.8-125.9 39.1-169.8-10.8c-36.3-41.2-40.1-101.8-9.2-147.2 2.3-3.4 1.4-8.1-2-10.5-3.4-2.3-8.1-1.4-10.4 2-15.3 22.5-23.5 49-23.5 76.2 0 74.7 60.8 135.5 135.5 135.5S327.5 266.7 327.5 192 266.7 56.5 192 56.5z" _fill="#0a3378"/><path pid="2" d="M186.5 277.1c2.8 3.1 7.6 3.3 10.6.5l.5-.5 44-48c2.8-3.1 2.5-7.8-.6-10.6-3.1-2.7-7.7-2.5-10.5.5l-20.2 22 19.1-57.4h33.5L252.5 195c-2.8 3-2.7 7.8.4 10.6 3 2.8 7.8 2.7 10.6-.4l.1-.1 22-24c2.3-2.6 2.6-6.4.7-9.3l-32-48c-1.4-2.1-3.7-3.3-6.3-3.4H136c-2.5 0-4.9 1.3-6.3 3.4l-32 48c-1.9 2.9-1.6 6.7.7 9.3l88.1 96zm-31.9-93.6l19.1 57.4-52.6-57.4h33.5zm37.4 64.7l-21.6-64.7h43.1L192 248.2zm-21.6-79.7l11-32.9h21.1l11 32.9h-43.1zm95.5 0h-36.5l-11-32.9H244l21.9 32.9zm-125.9-33h25.5l-11 32.9H118l22-32.9z" _fill="#0a3378"/>'
  }
});