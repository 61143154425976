import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=08a76377"
import script from "./manage.vue?vue&type=script&lang=ts"
export * from "./manage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yaambs/Desktop/SGL-Admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08a76377')) {
      api.createRecord('08a76377', component.options)
    } else {
      api.reload('08a76377', component.options)
    }
    module.hot.accept("./manage.vue?vue&type=template&id=08a76377", function () {
      api.rerender('08a76377', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/locations/states/manage.vue"
export default component.exports