"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateColour = exports.getColours = exports.getColourById = exports.fileTransferHeaders = exports.deleteColour = exports.defaultColourData = exports.createColour = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultColourData = exports.defaultColourData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0
};
const getColours = params => (0, _request.default)({
  url: '/colours',
  method: 'get',
  params
});
exports.getColours = getColours;
const getColourById = id => (0, _request.default)({
  url: `/colours/${id}`,
  method: 'get'
});
exports.getColourById = getColourById;
const updateColour = (id, data) => (0, _request.default)({
  url: `/colours/${id}`,
  method: 'patch',
  data
});
exports.updateColour = updateColour;
const deleteColour = id => (0, _request.default)({
  url: `/colours/${id}`,
  method: 'delete'
});
exports.deleteColour = deleteColour;
const createColour = data => (0, _request.default)({
  url: '/colours/',
  method: 'post',
  data
});
exports.createColour = createColour;