import { render, staticRenderFns } from "./StaticPageDetail.vue?vue&type=template&id=25a41a12&scoped=true"
import script from "./StaticPageDetail.vue?vue&type=script&lang=ts"
export * from "./StaticPageDetail.vue?vue&type=script&lang=ts"
import style0 from "./StaticPageDetail.vue?vue&type=style&index=0&id=25a41a12&lang=scss"
import style1 from "./StaticPageDetail.vue?vue&type=style&index=1&id=25a41a12&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a41a12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yaambs/Desktop/SGL-Admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25a41a12')) {
      api.createRecord('25a41a12', component.options)
    } else {
      api.reload('25a41a12', component.options)
    }
    module.hot.accept("./StaticPageDetail.vue?vue&type=template&id=25a41a12&scoped=true", function () {
      api.rerender('25a41a12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/static-pages/components/StaticPageDetail.vue"
export default component.exports