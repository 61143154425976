"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "productGenFormData",
    staticClass: "demo-form full-width",
    attrs: {
      model: _vm.productGenFormData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_vm.productGenFormData.orderStatus !== "Sold Online" ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.productGenFormData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "enabled", $$v);
      },
      expression: "productGenFormData.enabled"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "status",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.status,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "status", $$v);
      },
      expression: "productGenFormData.status"
    }
  }, _vm._l(_vm.statusOptions, function (status) {
    return _c("el-option", {
      key: status.key,
      attrs: {
        label: status.value,
        value: status.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Stone ID",
      prop: "stoneId"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "stoneId",
      placeholder: "Stone ID",
      controls: false
    },
    model: {
      value: _vm.productGenFormData.stoneId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "stoneId", $$v);
      },
      expression: "productGenFormData.stoneId"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Seller",
      prop: "sellerId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "sellerId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.sellerId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "sellerId", $$v);
      },
      expression: "productGenFormData.sellerId"
    }
  }, _vm._l(_vm.sellersList, function (seller) {
    return _c("el-option", {
      key: seller.id,
      attrs: {
        label: seller.companyName,
        value: seller.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Product Type",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "type",
      required: "",
      disabled: _vm.isEdit
    },
    on: {
      change: _vm.productTypeChanged
    },
    model: {
      value: _vm.productGenFormData.type,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "type", $$v);
      },
      expression: "productGenFormData.type"
    }
  }, _vm._l(_vm.typeOptions, function (productType) {
    return _c("el-option", {
      key: productType.key,
      attrs: {
        label: productType.key,
        value: productType.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Carat",
      prop: "caratWeight"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "caratWeight",
      required: "",
      placeholder: "Carat",
      precision: 2,
      min: 0.01,
      step: 0.01
    },
    model: {
      value: _vm.productGenFormData.caratWeight,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "caratWeight", $$v);
      },
      expression: "productGenFormData.caratWeight"
    }
  })], 1)], 1), _vm.productGenFormData.type === "Parcel" ? [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diamond Pieces",
      prop: "diamondPieces"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "diamondPieces",
      placeholder: "Diamond Pieces",
      min: 1
    },
    model: {
      value: _vm.productGenFormData.diamondPieces,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "diamondPieces", $$v);
      },
      expression: "productGenFormData.diamondPieces"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Parcel Weight",
      prop: "parcelWeight"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "parcelWeight",
      placeholder: "Parcel Weight",
      precision: 2,
      min: 0.01,
      step: 0.01
    },
    model: {
      value: _vm.productGenFormData.parcelWeight,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "parcelWeight", $$v);
      },
      expression: "productGenFormData.parcelWeight"
    }
  })], 1)], 1)] : _vm._e()], 2) : _vm._e(), _vm.productGenFormData.orderStatus !== "Sold Online" ? _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v(" Attributes ")]) : _vm._e(), _vm.productGenFormData.orderStatus !== "Sold Online" ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Shape",
      prop: "shapeId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "shapeId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.shapeId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "shapeId", $$v);
      },
      expression: "productGenFormData.shapeId"
    }
  }, _vm._l(_vm.shapesList, function (shape) {
    return _c("el-option", {
      key: shape.id,
      attrs: {
        label: shape.name,
        value: shape.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Colour",
      prop: "colourId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "colourId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.colourId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "colourId", $$v);
      },
      expression: "productGenFormData.colourId"
    }
  }, _vm._l(_vm.coloursList, function (colour) {
    return _c("el-option", {
      key: colour.id,
      attrs: {
        label: colour.name,
        value: colour.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Clarity",
      prop: "clarityId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "clarityId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.clarityId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "clarityId", $$v);
      },
      expression: "productGenFormData.clarityId"
    }
  }, _vm._l(_vm.clarityList, function (clarity) {
    return _c("el-option", {
      key: clarity.id,
      attrs: {
        label: clarity.name,
        value: clarity.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cut",
      prop: "cutId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "cut",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.cutId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "cutId", $$v);
      },
      expression: "productGenFormData.cutId"
    }
  }, _vm._l(_vm.cutList, function (cut) {
    return _c("el-option", {
      key: cut.id,
      attrs: {
        label: cut.name,
        value: cut.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Polish",
      prop: "polishId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "polish",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.polishId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "polishId", $$v);
      },
      expression: "productGenFormData.polishId"
    }
  }, _vm._l(_vm.polishList, function (polish) {
    return _c("el-option", {
      key: polish.id,
      attrs: {
        label: polish.name,
        value: polish.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Symmetry",
      prop: "symmetryId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "symmetry",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.symmetryId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "symmetryId", $$v);
      },
      expression: "productGenFormData.symmetryId"
    }
  }, _vm._l(_vm.symmetryList, function (symmetry) {
    return _c("el-option", {
      key: symmetry.id,
      attrs: {
        label: symmetry.name,
        value: symmetry.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Finish",
      prop: "finishId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "finishId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.finishId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "finishId", $$v);
      },
      expression: "productGenFormData.finishId"
    }
  }, _vm._l(_vm.finishList, function (finish) {
    return _c("el-option", {
      key: finish.id,
      attrs: {
        label: finish.name,
        value: finish.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Fluorescence",
      prop: "fluorescenceId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "fluorescenceId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.fluorescenceId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "fluorescenceId", $$v);
      },
      expression: "productGenFormData.fluorescenceId"
    }
  }, _vm._l(_vm.fluorescenceList, function (fluorescence) {
    return _c("el-option", {
      key: fluorescence.id,
      attrs: {
        label: fluorescence.name,
        value: fluorescence.id
      }
    });
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.productGenFormData.orderStatus !== "Sold Online" ? _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v(" Other Details ")]) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm.productGenFormData.orderStatus !== "Sold Online" ? [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "BGM",
      prop: "bgm"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "Yes",
      "inactive-text": "No"
    },
    model: {
      value: _vm.productGenFormData.colorTone,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "colorTone", $$v);
      },
      expression: "productGenFormData.colorTone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Featured",
      prop: "featured"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "Show on Home page",
      "inactive-text": "Hide"
    },
    model: {
      value: _vm.productGenFormData.featured,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "featured", $$v);
      },
      expression: "productGenFormData.featured"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Taxable",
      prop: "isTaxable"
    }
  }, [_c("el-switch", {
    attrs: {
      "inactive-text": "Tax inclusive",
      "active-text": "Taxes extra",
      disabled: ""
    },
    model: {
      value: _vm.productGenFormData.isTaxable,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "isTaxable", $$v);
      },
      expression: "productGenFormData.isTaxable"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_vm.productGenFormData.isTaxable ? _c("el-form-item", {
    attrs: {
      label: "Tax",
      prop: "taxId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "taxId",
      required: ""
    },
    model: {
      value: _vm.productGenFormData.taxId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "taxId", $$v);
      },
      expression: "productGenFormData.taxId"
    }
  }, _vm._l(_vm.taxes, function (tax) {
    return _c("el-option", {
      key: tax.id,
      attrs: {
        label: tax.name,
        value: tax.id
      }
    });
  }), 1)], 1) : _vm._e()], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Offline Sold",
      prop: "offlineSold"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "Sold Offline",
      "inactive-text": "Available",
      disabled: _vm.productGenFormData.orderStatus !== "Available"
    },
    model: {
      value: _vm.productGenFormData.isOfflineSold,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "isOfflineSold", $$v);
      },
      expression: "productGenFormData.isOfflineSold"
    }
  }), _c("help-text", {
    attrs: {
      content: "If the product/parcel has been sold outside the platform"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Order Status",
      prop: "orderStatus"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini",
      disabled: _vm.productGenFormData.isOfflineSold
    },
    model: {
      value: _vm.productGenFormData.orderStatus,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "orderStatus", $$v);
      },
      expression: "productGenFormData.orderStatus"
    }
  }, [_c("el-radio-button", {
    staticClass: "available-radio",
    attrs: {
      value: "Available",
      label: "Available"
    }
  }), _c("el-radio-button", {
    staticClass: "in-cart-radio",
    attrs: {
      value: "in cart",
      label: "in cart"
    }
  }, [_vm._v(" In cart ")]), _c("el-radio-button", {
    staticClass: "sold-online-radio",
    attrs: {
      value: "Sold Online",
      label: "Sold Online",
      disabled: ""
    }
  })], 1), _c("p", {
    staticClass: "no-margin no-padding text-small text-italic"
  }, [_vm._v(" Note: keep only for testing, remove in production ")])], 1)], 1)] : _vm._e(), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Certificate Number",
      prop: "certificateNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "certificateNumber",
      required: "",
      min: 0,
      placeholder: "Certificate Number"
    },
    model: {
      value: _vm.productGenFormData.certificateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "certificateNumber", $$v);
      },
      expression: "productGenFormData.certificateNumber"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Certificate Details",
      prop: "certificateDetails"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/common/upload",
      field: "file",
      "file-list": _vm.certificateFile,
      accept: ".pdf",
      description: "(Note: PDF size should be less than 1mb)",
      "max-size": "1",
      "button-text": "Upload Certificate",
      "list-type": "text"
    },
    on: {
      change: _vm.certificateUploaded
    }
  })], 1)], 1)], 2), _vm.productGenFormData.orderStatus !== "Sold Online" ? _c("el-form-item", {
    attrs: {
      label: "Short Description",
      prop: "shortDescription"
    }
  }, [_c("editor", {
    attrs: {
      "api-key": "no-api-key",
      init: {
        height: 500,
        plugins: ["lists link table image paste help wordcount"],
        toolbar: "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
      }
    },
    model: {
      value: _vm.productGenFormData.shortDescription,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "shortDescription", $$v);
      },
      expression: "productGenFormData.shortDescription"
    }
  })], 1) : _vm._e(), _vm.productGenFormData.orderStatus !== "Sold Online" ? _c("el-collapse", {
    staticClass: "margin20",
    attrs: {
      accordion: ""
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "Advanced Datafields",
      name: "2"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "priority",
      placeholder: "Priority",
      min: 0,
      max: 100,
      precision: 2
    },
    model: {
      value: _vm.productGenFormData.priority,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "priority", $$v);
      },
      expression: "productGenFormData.priority"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tags",
      prop: "tags"
    }
  }, [_vm._l(_vm.productGenFormData.tags ? _vm.productGenFormData.tags.split(",") : [], function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function ($event) {
          return _vm.handleTagClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), _vm.inputTagVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: _vm.handleTagInputConfirm
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleTagInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputTagValue,
      callback: function ($$v) {
        _vm.inputTagValue = $$v;
      },
      expression: "inputTagValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showTagInput
    }
  }, [_vm._v(" + New Tag ")])], 2)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Meta Title",
      prop: "metaTitle"
    }
  }, [_c("el-input", {
    attrs: {
      name: "metaTitle",
      maxlength: "70",
      required: "",
      placeholder: "Meta Title",
      "show-word-limit": ""
    },
    model: {
      value: _vm.productGenFormData.metaTitle,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "metaTitle", $$v);
      },
      expression: "productGenFormData.metaTitle"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Meta Description",
      prop: "metaDescription"
    }
  }, [_c("el-input", {
    attrs: {
      name: "metaDescription",
      maxlength: "220",
      type: "textarea",
      placeholder: "Meta Description",
      "show-word-limit": ""
    },
    model: {
      value: _vm.productGenFormData.metaDescription,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "metaDescription", $$v);
      },
      expression: "productGenFormData.metaDescription"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Meta Keywords",
      prop: "metaKeywords"
    }
  }, [_c("el-input", {
    attrs: {
      name: "metaKeywords",
      required: "",
      placeholder: "Meta Keywords"
    },
    model: {
      value: _vm.productGenFormData.metaKeywords,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "metaKeywords", $$v);
      },
      expression: "productGenFormData.metaKeywords"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Canonical Tag",
      prop: "canonicalTag"
    }
  }, [_c("el-input", {
    attrs: {
      name: "canonicalTag",
      required: "",
      placeholder: "Canonical Tag"
    },
    model: {
      value: _vm.productGenFormData.canonicalTag,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "canonicalTag", $$v);
      },
      expression: "productGenFormData.canonicalTag"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      loading: _vm.loading,
      type: "sucess"
    },
    on: {
      click: function ($event) {
        return _vm.submitForm(true);
      }
    }
  }, [_vm._v(" Save & Exit ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;