"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_vm.isSuperAdmin() ? _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/seller/add/"
    }
  }, [_c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")])], 1) : _vm._e()], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "50",
      align: "center",
      label: "ID",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      label: "Status",
      prop: "status"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.companyName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "markup",
      label: "Markup"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.markup) + "% ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "sglId",
      label: "SGL ID"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.sglId) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "300",
      align: "center",
      label: "Address"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.formatAddress(scope.row)) + " ")];
      }
    }])
  }), _vm.isSuperAdmin() ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/seller/edit/" + scope.row.id
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          }
        })], 1), _c("router-link", {
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            to: "/sizes-markup/list/" + scope.row.id
          }
        }, [_c("el-button", {
          attrs: {
            type: "info",
            size: "small",
            icon: "el-icon-money"
          }
        })], 1)];
      }
    }], null, false, 3358116098)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;