"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFaq = exports.getFaqs = exports.getFaqById = exports.deleteFaq = exports.defaultFaqData = exports.createFaq = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultFaqData = exports.defaultFaqData = {
  id: 0,
  enabled: true,
  title: '',
  answer: '',
  priority: 0
};
const getFaqs = params => (0, _request.default)({
  url: '/faqs',
  method: 'get',
  params
});
exports.getFaqs = getFaqs;
const getFaqById = id => (0, _request.default)({
  url: `/faqs/${id}`,
  method: 'get'
});
exports.getFaqById = getFaqById;
const updateFaq = (id, data) => (0, _request.default)({
  url: `/faqs/${id}`,
  method: 'patch',
  data
});
exports.updateFaq = updateFaq;
const deleteFaq = id => (0, _request.default)({
  url: `/faqs/${id}`,
  method: 'delete'
});
exports.deleteFaq = deleteFaq;
const createFaq = data => (0, _request.default)({
  url: '/faqs/',
  method: 'post',
  data
});
exports.createFaq = createFaq;