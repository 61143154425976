"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block import-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      action: "",
      "on-change": _vm.handleChange,
      "on-remove": _vm.handleRemove,
      "on-exceed": _vm.handleExceed,
      accept: _vm.acceptableFormats.join(", "),
      "auto-upload": false
    },
    model: {
      value: _vm.postForm.file,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "file", $$v);
      },
      expression: "postForm.file"
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "warning",
      loading: _vm.fileExcelToJsonProgressing,
      disabled: _vm.processingProduct
    }
  }, [_vm._v(" Click to Upload ")]), _c("router-link", {
    attrs: {
      to: "/product/list/"
    }
  }, [_vm._v("    "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary",
      loading: _vm.fileExcelToJsonProgressing,
      disabled: _vm.processingProduct
    }
  }, [_vm._v(" Go to Product List ")])], 1)], 1), _c("br"), _vm.fileExcelToJsonProgressing ? [_c("el-skeleton", {
    attrs: {
      animated: ""
    }
  }), _c("br")] : _vm._e(), _vm.productData && _vm.productData.length ? [_c("el-divider", [_c("i", {
    staticClass: "el-icon-star-on"
  })]), _vm.fileRead && _vm.productData.length > 0 ? [_c("div", {
    staticClass: "centered-count"
  }, [_vm.currentlyProcessingIndex < _vm.productData.length - 1 ? _c("div", [_c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-video-play",
      disabled: _vm.processingProduct
    },
    on: {
      click: _vm.processProduct
    }
  }, [_vm._v(" Process ")]), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-video-play",
      disabled: _vm.processingProduct
    },
    on: {
      click: _vm.reset
    }
  }, [_vm._v(" Reset ")]), _c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-video-pause",
      disabled: _vm.pauseProcessingProduct
    },
    on: {
      click: function ($event) {
        _vm.pauseProcessingProduct = true;
      }
    }
  }, [_vm._v(" Pause ")])], 1) : _vm._e(), _c("br"), _c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 24,
      percentage: parseFloat((_vm.processedProducts * 100 / _vm.productData.length).toFixed(2)),
      status: "success"
    }
  }), _vm.currentlyProcessingIndex > -1 ? _c("p", [_vm._v(" currently processing sr#: " + _vm._s(_vm.currentlyProcessingIndex + 1) + " ")]) : _vm._e(), _c("br"), _c("el-row", {
    attrs: {
      gutter: 12
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_vm._v(" Total: " + _vm._s(_vm.productData.length) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_vm._v(" Processed: " + _vm._s(_vm.processedProducts) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_vm._v(" Failed: " + _vm._s(_vm.failedProducts) + " "), _c("br")])], 1)], 1)], 1)] : _vm._e(), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.productData,
      border: "",
      height: "500",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "Sr."
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [!scope.row.uploadedToServer ? _c("el-switch", {
          attrs: {
            "active-value": "Approved",
            "inactive-value": "Rejected"
          },
          model: {
            value: scope.row.status,
            callback: function ($$v) {
              _vm.$set(scope.row, "status", $$v);
            },
            expression: "scope.row.status"
          }
        }) : [_c("el-tag", {
          attrs: {
            disabled: "",
            size: "mini",
            type: _vm.statusColorMap[scope.row.status]
          }
        }, [_vm._v(" " + _vm._s(scope.row.status) + " ")])]];
      }
    }], null, false, 1276148109)
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        }), _vm._v(" " + _vm._s(scope.row.name) + " "), _c("br"), scope.row.featured ? _c("el-tag", {
          attrs: {
            size: "mini",
            type: "warning"
          }
        }, [_vm._v(" featured ")]) : _vm._e()];
      }
    }], null, false, 2932792512)
  }), _c("el-table-column", {
    attrs: {
      prop: "productType",
      label: "Type",
      width: "70"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "category",
      label: "Category",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "attributesValues",
      label: "Attributes",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Price"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "attributesValues",
      label: "Regular",
      width: "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.getVariantPrice(scope.row.productsVariation, "unitPrice")) + " ")];
      }
    }], null, false, 4173430462)
  }), _c("el-table-column", {
    attrs: {
      prop: "attributesValues",
      label: "Sale",
      width: "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.getVariantPrice(scope.row.productsVariation, "salePrice")) + " ")];
      }
    }], null, false, 1665952227)
  })], 1), _c("el-table-column", {
    attrs: {
      prop: "file",
      label: "Image",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.file ? [scope.row.imageLoaded ? [_c("el-image", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            src: scope.row.file,
            lazy: ""
          }
        }, [_c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "placeholder"
          },
          slot: "placeholder"
        }, [_vm._v(" Loading"), _c("span", {
          staticClass: "dot"
        }, [_vm._v("...")])]), _c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "error"
          },
          slot: "error"
        }, [_c("i", {
          staticClass: "el-icon-picture-outline"
        })])])] : [_vm._v(" upload prev rows ")]] : [_c("input", {
          staticClass: "filter-item",
          attrs: {
            size: "mini",
            type: "file",
            disabled: scope.row.uploadStatus === "uploaded"
          },
          on: {
            change: function ($event) {
              return _vm.getImageIntoBase64($event, scope);
            }
          }
        })]];
      }
    }], null, false, 2460211800)
  }), _c("el-table-column", {
    attrs: {
      prop: "action",
      label: "Action",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.file || scope.row.uploadStatus === "uploaded" ? [_c("el-button", {
          attrs: {
            type: _vm.getUploadStatusType(scope.row.uploadStatus),
            disabled: "",
            size: "mini"
          }
        }, [_vm._v(" " + _vm._s(scope.row.uploadStatus) + " ")]), scope.row.uploadStatus === "failed" ? _c("div", [_vm._v(" " + _vm._s(scope.row.failedReason) + " ")]) : _vm._e()] : [_vm._v(" Image not found ")]];
      }
    }], null, false, 3301807312)
  })], 1), _c("br"), _c("br")] : _vm._e()], 2)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;