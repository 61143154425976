"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const userSubMenu = exports.userSubMenu = [{
  path: '/users/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/users/list.vue'))),
  name: 'userManagement.users.title',
  meta: {
    title: 'userManagement.users.title',
    icon: 'customer',
    noCache: true
  }
}, {
  path: '/users/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/users/manage.vue'))),
  name: 'userManagement.users.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'userManagement.users.add',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/users/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/users/manage.vue'))),
  name: 'userManagement.users.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'userManagement.users.edit',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/address/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/list.vue'))),
  name: 'Address',
  meta: {
    title: 'Address',
    activeMenu: '/users/list',
    icon: 'user',
    noCache: true,
    hidden: true
  }
}, {
  path: '/address/import',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/import.vue'))),
  name: 'customerManagement.address.import',
  props: {
    isEdit: false
  },
  meta: {
    title: 'customerManagement.address.import',
    noCache: true,
    hidden: true
  }
}];