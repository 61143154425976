"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      name: "title",
      required: "",
      placeholder: "Title"
    },
    on: {
      input: function ($event) {
        return _vm.createSlug(_vm.formData.title);
      }
    },
    model: {
      value: _vm.formData.title,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Slug",
      prop: "slug"
    }
  }, [_c("el-input", {
    attrs: {
      name: "slug",
      required: "",
      placeholder: "Slug"
    },
    model: {
      value: _vm.formData.slug,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "slug", $$v);
      },
      expression: "formData.slug"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v(" https://www.sgl.in/ ")])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("editor", {
    attrs: {
      "api-key": "no-api-key",
      init: {
        height: 500,
        plugins: ["lists link image paste help wordcount preview table | code"],
        toolbar: "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help | preview",
        // eslint-disable-next-line
        default_font_stack: ["Helvetica Neue"],
        // eslint-disable-next-line
        font_family_formats: "helvetica"
      }
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "priority",
      required: "",
      placeholder: "Priority"
    },
    model: {
      value: _vm.formData.priority,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "priority", $$v);
      },
      expression: "formData.priority"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Meta Title",
      prop: "metaTitle"
    }
  }, [_c("el-input", {
    attrs: {
      name: "metaTitle",
      maxlength: "70",
      required: "",
      placeholder: "Meta Title",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.metaTitle,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "metaTitle", $$v);
      },
      expression: "formData.metaTitle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Meta Description",
      prop: "metaDescription"
    }
  }, [_c("el-input", {
    attrs: {
      name: "metaDescription",
      maxlength: "220",
      type: "textarea",
      placeholder: "Meta Description",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.metaDescription,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "metaDescription", $$v);
      },
      expression: "formData.metaDescription"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Meta Keywords",
      prop: "metaKeywords"
    }
  }, [_c("el-input", {
    attrs: {
      name: "metaKeywords",
      required: "",
      placeholder: "Meta Keywords"
    },
    model: {
      value: _vm.formData.metaKeywords,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "metaKeywords", $$v);
      },
      expression: "formData.metaKeywords"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Canonical Tag",
      prop: "canonicalTag"
    }
  }, [_c("el-input", {
    attrs: {
      name: "canonicalTag",
      required: "",
      placeholder: "Canonical Tag"
    },
    model: {
      value: _vm.formData.canonicalTag,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "canonicalTag", $$v);
      },
      expression: "formData.canonicalTag"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;