"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSymmetry = exports.getSymmetryById = exports.getSymmetries = exports.deleteSymmetry = exports.defaultSymmetryData = exports.createSymmetry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSymmetryData = exports.defaultSymmetryData = {
  id: 0,
  enabled: true,
  name: '',
  priority: 0
};
const getSymmetries = params => (0, _request.default)({
  url: '/symmetries',
  method: 'get',
  params
});
exports.getSymmetries = getSymmetries;
const getSymmetryById = id => (0, _request.default)({
  url: `/symmetries/${id}`,
  method: 'get'
});
exports.getSymmetryById = getSymmetryById;
const updateSymmetry = (id, data) => (0, _request.default)({
  url: `/symmetries/${id}`,
  method: 'patch',
  data
});
exports.updateSymmetry = updateSymmetry;
const deleteSymmetry = id => (0, _request.default)({
  url: `/symmetries/${id}`,
  method: 'delete'
});
exports.deleteSymmetry = deleteSymmetry;
const createSymmetry = data => (0, _request.default)({
  url: '/symmetries/',
  method: 'post',
  data
});
exports.createSymmetry = createSymmetry;