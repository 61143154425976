"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 12
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("FAQs")]), _c("router-link", {
    staticClass: "margin-horizontal pull-right",
    attrs: {
      to: "/faqs/add"
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")])], 1)], 1), _c("el-collapse", {
    attrs: {
      accordion: ""
    }
  }, _vm._l(_vm.list, function (item) {
    return _c("el-collapse-item", {
      key: item.id
    }, [_c("template", {
      slot: "title"
    }, [_c("div", {
      staticClass: "title-content"
    }, [_c("enabled", {
      attrs: {
        url: "/faqs/" + item.id
      },
      model: {
        value: item.enabled,
        callback: function ($$v) {
          _vm.$set(item, "enabled", $$v);
        },
        expression: "item.enabled"
      }
    }), _vm._v(" " + _vm._s(item.title) + " "), _c("p", {
      staticClass: "priority"
    }, [_vm._v(_vm._s(item.priority))])], 1)]), _c("div", [_vm._v(" " + _vm._s(item.answer) + " "), _c("div", {
      staticClass: "pull-right"
    }, [_c("router-link", {
      attrs: {
        to: "/faqs/edit/" + item.id
      }
    }, [_c("el-button", {
      attrs: {
        type: "primary",
        size: "mini",
        icon: "el-icon-edit"
      }
    })], 1), _c("el-button", {
      directives: [{
        name: "waves",
        rawName: "v-waves"
      }],
      attrs: {
        size: "mini",
        type: "danger",
        icon: "el-icon-delete"
      },
      on: {
        click: function ($event) {
          return _vm.handleDelete(item);
        }
      }
    })], 1), _c("br"), _c("br")])], 2);
  }), 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;