"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const statesSubMenu = exports.statesSubMenu = [{
  path: '/states/list/1',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "states-list" */'@/views/locations/states/list.vue'))),
  name: 'States',
  meta: {
    title: 'locations.titleShort',
    activeMenu: '/states/list',
    noCache: true,
    hidden: true,
    icon: 'globe'
  }
}, {
  path: '/states/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "states-list" */'@/views/locations/states/manage.vue'))),
  name: 'Edit State',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.states.edit',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/states/add/:countryId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/states/manage.vue'))),
  name: 'Add State',
  props: {
    isEdit: false
  },
  meta: {
    title: 'Add State',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}];