"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _users = require("@/api/users");
var _reviews = require("@/api/reviews");
var _products = require("@/api/products");
var _order = require("@/api/order");
var _index = require("@/utils/index");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.startDate = '';
    this.endDate = '';
    this.averageOrderValue = 0;
    this.stonesOrdered = 0;
    this.priceNotEntered = 0;
    this.stonesInCart = 0;
    this.userscount = 0;
    this.reviewscount = 0;
    this.productscount = 0;
    this.orderscount = 0;
    this.animationDuration = 3000;
  }
  handleSetLineChartData(type) {
    this.$emit('handleSetLineChartData', type);
  }
  created() {
    if (this.dateRange.start && this.dateRange.end) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.getProductCount();
      this.getOrderCount();
      this.getStonesPriceNotEnteredCount();
      this.getStonesOrderCount();
      this.getStonesInCartCount();
    } else if (!this.dateRange) {
      const date = new Date();
      this.endDate = date.toISOString();
      date.setMonth(date.getMonth() - 2);
      this.startDate = date.toISOString();
      this.getProductCount();
      this.getOrderCount();
      this.getStonesPriceNotEnteredCount();
      this.getStonesOrderCount();
      this.getStonesInCartCount();
    }
  }
  async fetchRevenue() {
    const query = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    const response = await (0, _order.orderRevenueByDate)(query);
    let totalSales = 0;
    let totalOrders = 0;
    if (response.length > 0) {
      for (let i = 0; i < response.length; i++) {
        totalSales += +response[i].total;
        totalOrders += +response[i].count;
      }
      this.averageOrderValue = +(totalSales / totalOrders).toFixed(2);
    }
  }
  async getUserCount() {
    try {
      this.userscount = +(await (0, _users.userCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        },
        startDate: this.startDate,
        endDate: this.endDate
      })));
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  async getProductCount() {
    try {
      this.productscount = +(await (0, _products.productCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      })));
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  async getStonesPriceNotEnteredCount() {
    try {
      this.priceNotEntered = +(await (0, _products.priceNotEnteredStonesCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      })));
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  async getStonesOrderCount() {
    try {
      this.stonesOrdered = +(await (0, _order.orderedStonesCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      })));
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  async getStonesInCartCount() {
    try {
      this.stonesInCart = +(await (0, _order.incartStonesCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      })));
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  async getReviewCount() {
    try {
      this.reviewscount = +(await (0, _reviews.reviewCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      })));
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
  async getOrderCount() {
    try {
      const result = await (0, _order.orderCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        },
        startDate: this.startDate,
        endDate: this.endDate
      }));
      this.orderscount = +result;
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error'
      });
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ''
})], default_1.prototype, "dateRange", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PanelGroup',
  components: {
    CountTo: _vueCountTo.default
  }
})], default_1);
var _default = exports.default = default_1;