"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFinish = exports.getFinishes = exports.getFinishById = exports.fileTransferHeaders = exports.deleteFinish = exports.defaultFinishData = exports.createFinish = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultFinishData = exports.defaultFinishData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0,
  startValue: 0,
  endValue: 0
};
const getFinishes = params => (0, _request.default)({
  url: '/finishes',
  method: 'get',
  params
});
exports.getFinishes = getFinishes;
const getFinishById = id => (0, _request.default)({
  url: `/finishes/${id}`,
  method: 'get'
});
exports.getFinishById = getFinishById;
const updateFinish = (id, data) => (0, _request.default)({
  url: `/finishes/${id}`,
  method: 'patch',
  data
});
exports.updateFinish = updateFinish;
const deleteFinish = id => (0, _request.default)({
  url: `/finishes/${id}`,
  method: 'delete'
});
exports.deleteFinish = deleteFinish;
const createFinish = data => (0, _request.default)({
  url: '/finishes/',
  method: 'post',
  data
});
exports.createFinish = createFinish;