"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.symmetriesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const symmetriesSubMenu = exports.symmetriesSubMenu = [{
  path: '/symmetry/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/symmetry/list.vue'))),
  name: 'Symmetries',
  meta: {
    title: 'productManagement.symmetry.title',
    noCache: true,
    hidden: false,
    icon: 'diamond-symmetry'
  }
}, {
  path: '/symmetry/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/symmetry/manage.vue'))),
  name: 'productManagement.symmetry.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.symmetry.manage',
    noCache: true,
    activeMenu: '/symmetry/list',
    hidden: true
  }
}, {
  path: '/symmetry/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/symmetry/manage.vue'))),
  name: 'productManagement.symmetry.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.symmetry.manage',
    noCache: true,
    activeMenu: '/symmetry/list',
    hidden: true
  }
}];