"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block order-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Status",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      name: "status",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.status,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, _vm._l(_vm.orderOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        required: "",
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1), _vm.formData.status === "Order Confirmed" && !_vm.formData.proformaInvoiceDate ? _c("el-form-item", {
    attrs: {
      label: "Proforma Invoice No",
      prop: "proformaInvoiceNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "proformaInvoiceNumber",
      placeholder: "Proforma Invoice Number"
    },
    model: {
      value: _vm.formData.proformaInvoiceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "proformaInvoiceNumber", $$v);
      },
      expression: "formData.proformaInvoiceNumber"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Order Confirmed" && !_vm.formData.proformaInvoiceDate ? _c("el-form-item", {
    attrs: {
      label: "Proforma Invoice File",
      prop: "proformaInvoiceFile"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/common/upload",
      field: "file",
      "file-list": _vm.proformaInvoiceFile,
      accept: ".pdf",
      description: "(Note: PDF size should be less than 1mb)",
      "max-size": "1",
      "button-text": "Upload File",
      "list-type": "text"
    },
    on: {
      change: _vm.proformaInvoiceFileUploaded
    }
  })], 1) : _vm._e(), _vm.formData.status === "Order In Process" && !_vm.formData.inProcessTimestamp ? _c("el-form-item", {
    attrs: {
      label: "Invoice File",
      prop: "invoiceFile"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/common/upload",
      field: "file",
      "file-list": _vm.invoiceFile,
      accept: ".pdf",
      description: "(Note: PDF size should be less than 1mb)",
      "max-size": "1",
      "button-text": "Upload File",
      "list-type": "text"
    },
    on: {
      change: _vm.invoiceFileUploaded
    }
  })], 1) : _vm._e(), _vm.formData.status === "Order In Process" && !_vm.formData.inProcessTimestamp ? _c("el-form-item", {
    attrs: {
      label: "Payment Method",
      prop: "paymentMethod"
    }
  }, [_c("el-select", {
    attrs: {
      name: "paymentMethod",
      placeholder: "Select",
      clearable: ""
    },
    model: {
      value: _vm.paymentMethod,
      callback: function ($$v) {
        _vm.paymentMethod = $$v;
      },
      expression: "paymentMethod"
    }
  }, _vm._l(_vm.paymentMethodOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        required: "",
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.status === "Order In Process" && !_vm.formData.inProcessTimestamp ? _c("el-form-item", {
    attrs: {
      label: "Reference No",
      prop: "referenceNo"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "referenceNo",
      placeholder: "Reference No"
    },
    model: {
      value: _vm.referenceNo,
      callback: function ($$v) {
        _vm.referenceNo = $$v;
      },
      expression: "referenceNo"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Order In Process" && !_vm.formData.inProcessTimestamp ? _c("el-form-item", {
    attrs: {
      label: "Amount Paid",
      prop: "amountPaid"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "amountPaid",
      placeholder: "Amount",
      controls: false
    },
    model: {
      value: _vm.amountPaid,
      callback: function ($$v) {
        _vm.amountPaid = $$v;
      },
      expression: "amountPaid"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Order In Process" && !_vm.formData.inProcessTimestamp ? _c("el-form-item", {
    attrs: {
      label: "Payment Status",
      prop: "paymentStatus"
    }
  }, [_c("el-select", {
    attrs: {
      name: "paymentStatus",
      placeholder: "Select",
      clearable: ""
    },
    model: {
      value: _vm.formData.paymentStatus,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "paymentStatus", $$v);
      },
      expression: "formData.paymentStatus"
    }
  }, _vm._l(_vm.paymentStatusOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        required: "",
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.status === "Out for Delivery" && !_vm.formData.outForDeliveryTimestamp ? _c("el-form-item", {
    attrs: {
      label: "Courier Name",
      prop: "courierName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "courierName",
      placeholder: "Courier Name"
    },
    model: {
      value: _vm.formData.courierName,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "courierName", $$v);
      },
      expression: "formData.courierName"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Out for Delivery" && !_vm.formData.outForDeliveryTimestamp ? _c("el-form-item", {
    attrs: {
      label: "AWB Number",
      prop: "awbNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "awbNumber",
      placeholder: "AWB Number"
    },
    model: {
      value: _vm.formData.awbNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "awbNumber", $$v);
      },
      expression: "formData.awbNumber"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Order Cancelled" ? _c("el-form-item", {
    attrs: {
      label: "Comments",
      prop: "comments"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "max-content"
    },
    attrs: {
      name: "comments",
      placeholder: "Comments"
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1) : _vm._e(), _c("el-form-item", [_vm.formData.status === "Order Cancelled" ? _c("el-popconfirm", {
    staticStyle: {
      "margin-left": "5px",
      "margin-right": "5px"
    },
    attrs: {
      title: "Are you sure, you won't be able to revert this step?"
    },
    on: {
      confirm: _vm.submitForm
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      slot: "reference",
      type: "primary",
      size: "medium"
    },
    slot: "reference"
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1) : _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary",
      size: "medium"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;