"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _products = require("@/api/products");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.showCreatedDate = false;
    this.category = '';
    this.attribute = '';
    this.priceNotEntered = false;
    this.dateRange = '';
    // private startDate:any = '';
    // private endDate:any = '';
    this.listQuery = {
      page: 1,
      limit: 10,
      stoneId: undefined,
      enabled: true,
      isOfflineSold: undefined,
      orderStatus: undefined,
      sort: 'id,ASC',
      caratWeight: undefined,
      sellerId: undefined,
      shapeId: undefined,
      colourId: undefined,
      clarityId: undefined,
      finishId: undefined,
      fluorescenceId: undefined,
      symmetryId: undefined,
      cutId: undefined,
      polishId: undefined,
      'productsVariation.unitPrice': undefined,
      type: undefined,
      createdTimestamp: undefined,
      filter: {
        sellerId: 'eq',
        stoneId: '$contL',
        enabled: 'eq',
        isOfflineSold: 'eq',
        orderStatus: 'eq',
        caratWeight: 'eq',
        shapeId: 'eq',
        colourId: 'eq',
        clarityId: 'eq',
        finishId: 'eq',
        fluorescenceId: 'eq',
        symmetryId: 'eq',
        cutId: 'eq',
        polishId: 'eq',
        'productsVariation.unitPrice': '$gt',
        type: 'eq',
        createdTimestamp: '$between'
      }
    };
    this.value = [];
    this.options = [{
      value: 'createdDate',
      label: 'Created Date'
    }];
    this.columns = {
      createdDate: false
    };
    this.sortOptions = [{
      label: 'Added',
      options: [{
        label: 'Recently',
        key: 'id,DESC'
      }, {
        label: 'Previously',
        key: 'id,ASC'
      }]
    }, {
      label: 'Price',
      options: [{
        label: 'High',
        key: 'productsVariation.unitPrice,DESC',
        icon: 'el-icon-bottom'
      }, {
        label: 'Low',
        key: 'productsVariation.unitPrice,ASC',
        icon: 'el-icon-top'
      }]
    }, {
      label: 'Carat',
      options: [{
        label: 'High',
        key: 'caratWeight,DESC',
        icon: 'el-icon-arrow-up'
      }, {
        label: 'Low',
        key: 'caratWeight,ASC',
        icon: 'el-icon-arrow-down'
      }]
    }, {
      label: 'Stone ID',
      options: [{
        label: 'A-Z',
        key: 'stoneId,ASC',
        icon: 'el-icon-sort-up'
      }, {
        label: 'Z-A',
        key: 'stoneId,DESC',
        icon: 'el-icon-sort-down'
      }]
    }, {
      label: 'Availibility',
      options: [{
        label: 'Available',
        key: 'orderStatus,ASC',
        icon: 'el-icon-check'
      }, {
        label: 'NA',
        key: 'orderStatus,DESC',
        icon: 'el-icon-close'
      }]
    }, {
      label: 'Sold Offline',
      options: [{
        label: 'Available',
        key: 'isOfflineSold,ASC',
        icon: 'el-icon-check'
      }, {
        label: 'Sold Offline',
        key: 'isOfflineSold,DESC',
        icon: 'el-icon-close'
      }]
    }, {
      label: 'Type',
      options: [{
        label: 'Pointer',
        key: 'type,ASC',
        icon: 'el-icon-caret-top'
      }, {
        label: 'Parcel',
        key: 'type,DESC',
        icon: 'el-icon-caret-bottom'
      }]
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
    this.filterOptions = [{
      url: 'sellers',
      key: 'sellerId',
      placeholder: 'Seller',
      style: {
        width: '150px'
      },
      items: [],
      sort: 'companyName,ASC'
    }, {
      key: 'productsVariation.unitPrice',
      placeholder: 'Price',
      style: {
        width: '120px'
      },
      items: [{
        id: 1,
        name: 'Available'
      }, {
        id: 0,
        name: 'NA'
      }]
    }, {
      key: 'orderStatus',
      placeholder: 'Order Status',
      style: {
        width: '120px'
      },
      items: [{
        id: 'Available',
        name: 'Available'
      }, {
        id: 'in cart',
        name: 'In Cart'
      }, {
        id: 'Sold Online',
        name: 'Sold Online'
      }]
    }, {
      key: 'isOfflineSold',
      placeholder: 'Sold Offline',
      style: {
        width: '120px'
      },
      items: [{
        id: false,
        name: 'Available'
      }, {
        id: true,
        name: 'Sold Offline'
      }]
    }, {
      key: 'type',
      placeholder: 'Type',
      style: {
        width: '100px'
      },
      items: [{
        id: 'Pointer',
        name: 'Pointer'
      }, {
        id: 'Parcel',
        name: 'Parcel'
      }]
    }, {
      key: 'shapeId',
      placeholder: 'Shape',
      style: {
        width: '120px'
      },
      items: [],
      url: 'shapes',
      sort: 'priority,DESC'
    }, {
      url: 'colours',
      key: 'colourId',
      placeholder: 'Colour',
      style: {
        width: '120px'
      },
      items: [],
      sort: 'priority,DESC'
    }, {
      key: 'clarityId',
      placeholder: 'Clarity',
      style: {
        width: '120px'
      },
      items: [],
      url: 'clarities',
      sort: 'priority,DESC'
    }, {
      key: 'finishId',
      placeholder: 'Finish',
      style: {
        width: '120px'
      },
      items: [],
      url: 'finishes',
      sort: 'priority,DESC'
    }, {
      url: 'fluorescences',
      key: 'fluorescenceId',
      placeholder: 'Fluorescence',
      style: {
        width: '150px'
      },
      items: [],
      sort: 'priority,DESC'
    }, {
      url: 'cuts',
      key: 'cutId',
      placeholder: 'Cut',
      style: {
        width: '120px'
      },
      items: [],
      sort: 'priority,DESC'
    }, {
      url: 'polishes',
      key: 'polishId',
      placeholder: 'Polish',
      style: {
        width: '120px'
      },
      items: [],
      sort: 'priority,DESC'
    }, {
      url: 'symmetries',
      key: 'symmetryId',
      placeholder: 'Symmetry',
      style: {
        width: '120px'
      },
      items: [],
      sort: 'priority,DESC'
    }];
  }
  created() {
    if (this.$route.query && this.$route.query.price && this.$route.query.price === '0') {
      this.priceNotEntered = true;
    }
    this.populateProductFilterOptions();
    this.doURLQuery();
    this.getList();
  }
  async getList() {
    try {
      // loading flag
      this.listLoading = true;
      if (this.priceNotEntered) {
        this.listQuery['productsVariation.unitPrice'] = 0;
        this.listQuery.filter['productsVariation.unitPrice'] = '$eq';
      }
      // make a copy of listQuery
      const query = JSON.parse(JSON.stringify(this.listQuery));
      // check if isOfflineSold is selected as orderStatus
      // if (query.isOfflineSold === "orderStatus") {
      //   // making isOfflineSold as false as its not sold yet
      //   query.isOfflineSold = false;
      //   // setting orderStatus as Available
      //   query.orderStatus = "Available";
      // } else {
      //   query.orderStatus = undefined;
      // }
      // check if productsVariation.unitPrice is selected as 1
      if (query['productsVariation.unitPrice'] !== undefined) {
        // if its 1, set condition as $gte
        if (query['productsVariation.unitPrice'] === 1) {
          query.filter['productsVariation.unitPrice'] = '$gte';
        } else if (query['productsVariation.unitPrice'] === 0) {
          // if its 0, set condition as $eq, & value as 0
          query.filter['productsVariation.unitPrice'] = '$eq';
          query['productsVariation.unitPrice'] = 0;
        }
      }
      // get data
      const data = await (0, _products.getProducts)((0, _index2.getQuery)(query));
      // add random lifetimeOrderValue & totalOrders
      this.list = data.data.map(item => {
        item.lifetimeSales = this.getRandomOrderValue(10000, 100000, 2);
        item.totalOrders = +this.getRandomOrderValue(1, 100, 2);
        item.totalClicks = this.getRandomOrderValue(100, 1000, 0);
        item.totalImpressions = this.getRandomOrderValue(1000, 50000, 0);
        item.ctr = (+item.totalClicks / item.totalImpressions * 100).toFixed(2);
        return item;
      });
      // this.list = data.data
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.listQuery.createdTimestamp = [this.dateRange.start, this.dateRange.end];
    }
    this.getList();
  }
  handleColumn() {
    for (const c in this.columns) {
      this.columns[c] = this.value.includes(c);
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['Product Id', 'Product Name', 'Product Type', 'Brand', 'Featured', 'Unit of Measurement', 'Sub Category', 'Attributes & Values', 'SKU', 'Tax', 'MRP', 'Sale Price', 'Basic Price', 'Manage Stock', 'Stock Status', 'Stock Quantity', 'Allow backorders', 'Low Stock Threshold', 'HSN Code', 'Min Order Quantity', 'Max Order Quantity', 'Priority', 'Short Description', 'Tags'];
    const subHeader = ['numeric', 'mandatory', 'mandatory', 'optional', 'visible on homepage', 'use code (eg kg,gm)', 'multiple comma seperated', 'only if variable product type is selected \nAttributes & Values separated by ~ \nmultiple separated by , (comma)', 'mandatory', 'optional \nenter percentage value', 'mandatory', 'optional', 'Yes - Y No - N or leave empty', 'Y: System will manage based on available quantity', 'enter only if Manage Stock is N', 'enter only if Manage Stock is Y', 'enter only if Manage Stock is Y \ncan allow order more than available quantity', 'enter only if Manage Stock is Y \nsystem will send notification if quantity goes below this value', 'optional \nenter only if Tax is entered', '- leave blank for 1 - default 1', '- leave blank for no max value - default unlimited', 'numeric', 'optional', 'optional \nmultiple separated by , (comma) \nhelps in search'];
    const filterVal = ['id', 'name', 'productType', 'brand.name', 'featured', 'measurementUnit.code', 'category', 'attribute.name', 'sku', 'tax.rate', 'regularPrice', 'salePrice', 'stockStatus', 'stockQuantity', 'allowBackOrder', 'lowStockThreshold', 'hsnCode', 'minOrderQuantity', 'maxOrderQuantity', 'priority', 'shortDescription', 'tags'
    // "bulk1",
    // "bulk2",
    // "bulk3",
    ];
    const productFilter = Object.assign({}, this.listQuery);
    delete productFilter.limit;
    delete productFilter.page;
    const data2 = await (0, _products.exportProducts)();
    const data = (0, _index2.formatJsonProduct)(filterVal, data2);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.productManagement.product.title'), [subHeader]);
    this.downloadLoading = false;
  }
  async handleDownload_() {
    this.downloadLoading = true;
    const tHeader = ['Product Name', 'Slug'];
    const filterVal = ['name', 'slug'];
    const productFilter = Object.assign({}, this.listQuery);
    delete productFilter.limit;
    delete productFilter.page;
    const data2 = await (0, _products.getProducts)((0, _index2.getQuery)(productFilter));
    const data = (0, _index2.formatJson)(filterVal, data2);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.productManagement.product.title'));
    this.downloadLoading = false;
  }
  isSuperAdmin() {
    return _admin.AdminModule.roles.indexOf('Super Admin') > -1;
  }
  loadImage(image) {
    if (image.indexOf('-sm') !== -1) {
      image = image.replace('-sm', '-md');
    }
    // image = image + '-md'
    return image;
  }
  getRandomOrderValue(min = 10, max = 10000, toFixed = 2) {
    return Math.floor(Math.random() * (max - min + 1) + min).toFixed(toFixed);
  }
  populateProductFilterOptions() {
    // loop over
    this.filterOptions.forEach(async filterOption => {
      if (filterOption.url) {
        const response = await (0, _products.productAttributesRequest)(filterOption.url, (0, _index2.getQuery)({
          sort: filterOption.sort || undefined,
          filter: {}
        }));
        if (response !== null && response !== void 0 && response.data) {
          // add name field for seller
          if (filterOption.key === 'sellerId') {
            response.data.forEach(seller => {
              seller.name = seller.companyName;
            });
          }
          filterOption.items = response === null || response === void 0 ? void 0 : response.data;
        }
      }
    });
  }
  getOfflineStatus(product) {
    if (product) {
      // check if orderStatus is other than Available
      if (product.orderStatus !== 'Available') {
        return '-';
      }
      // check if sold offline
      if (product.isOfflineSold) {
        return 'Sold Offline';
      }
      return 'Available';
    }
    return '-';
  }
  isPriceAvailable(productsVariation = null) {
    if (productsVariation) {
      return +productsVariation.unitPrice > 0;
    }
    return false;
  }
  doURLQuery() {
    const query = this.$route.query;
    if (query) {
      for (const key in query) {
        if (Object.prototype.hasOwnProperty.call(this.listQuery, key)) {
          this.listQuery[key] = query[key];
          // check if value is number, then convert to number
          if (typeof query[key] === 'string' && !isNaN(query[key])) {
            this.listQuery[key] = parseFloat(query[key]);
          }
        }
      }
    }
  }
  getRedirectParms() {
    // from listQuery, get params which are selected/not null
    const params = Object.keys(this.listQuery).filter(key => {
      return this.listQuery[key] !== null && this.listQuery[key] !== undefined;
    });
    // if params are there, return with ? and params
    if (params.length > 0) {
      // remove filter & enabled from params
      params.splice(params.indexOf('filter'), 1);
      params.splice(params.indexOf('enabled'), 1);
      // return with ? and params
      return '?' + params.map(key => key + '=' + this.listQuery[key]).join('&');
    }
    return '';
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;