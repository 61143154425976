"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.breadcrumbs = [];
  }
  onRouteChange(route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return;
    }
    this.getBreadcrumb();
  }
  created() {
    this.getBreadcrumb();
  }
  getBreadcrumb() {
    const matched = this.$route.matched.filter(item => item.meta && item.meta.title);
    const first = matched[0];
    // if (!this.isDashboard(first)) {
    //   matched = [
    //     { path: '/dashboard', meta: { title: 'dashboard' } } as RouteRecord
    //   ].concat(matched)
    // }
    this.breadcrumbs = matched.filter(item => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false;
    });
  }
  isDashboard(route) {
    const name = route && route.name;
    if (!name) {
      return false;
    }
    return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
  }
  pathCompile(path) {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const {
      params
    } = this.$route;
    const toPath = _pathToRegexp.default.compile(path);
    return toPath(params);
  }
  handleLink(item) {
    const {
      redirect,
      path
    } = item;
    if (redirect) {
      this.$router.push(redirect);
      return;
    }
    this.$router.push(this.pathCompile(path));
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route')], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Breadcrumb'
})], default_1);
var _default = exports.default = default_1;