"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateClarity = exports.getClarityById = exports.getClarities = exports.fileTransferHeaders = exports.deleteClarity = exports.defaultClarityData = exports.createClarity = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultClarityData = exports.defaultClarityData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0
};
const getClarities = params => (0, _request.default)({
  url: '/clarities',
  method: 'get',
  params
});
exports.getClarities = getClarities;
const getClarityById = id => (0, _request.default)({
  url: `/clarities/${id}`,
  method: 'get'
});
exports.getClarityById = getClarityById;
const updateClarity = (id, data) => (0, _request.default)({
  url: `/clarities/${id}`,
  method: 'patch',
  data
});
exports.updateClarity = updateClarity;
const deleteClarity = id => (0, _request.default)({
  url: `/clarities/${id}`,
  method: 'delete'
});
exports.deleteClarity = deleteClarity;
const createClarity = data => (0, _request.default)({
  url: '/clarities/',
  method: 'post',
  data
});
exports.createClarity = createClarity;