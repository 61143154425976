"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSeller = exports.getSellers = exports.getSellerById = exports.deleteSeller = exports.defaultSellerData = exports.createSeller = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSellerData = exports.defaultSellerData = {
  id: 0,
  enabled: true,
  status: 'Pending',
  isKYCVerified: false,
  panNo: undefined,
  gstNo: undefined,
  panFile: undefined,
  gstCertificate: undefined,
  companyName: '',
  brandName: '',
  companyNumber: '',
  companyNumberVerified: false,
  line1: '',
  line2: '',
  landmark: '',
  countryId: undefined,
  stateId: undefined,
  cityId: undefined,
  pincodeId: undefined,
  bankAccountNumber: '',
  bankBeneficiaryName: '',
  bankName: '',
  ifscCode: '',
  financialYearOrdersCount: undefined,
  financialYearOrdersValue: undefined,
  monthlyOrdersCount: undefined,
  monthlyOrdersValue: undefined,
  operationStatus: false,
  lifetimeOrdersCount: undefined,
  lifetimeOrdersAcceptedCount: undefined,
  lifetimeOrdersRejectedCount: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
  pincode: undefined,
  markup: 0,
  sglId: '',
  pinCode: undefined
};
const getSellers = params => (0, _request.default)({
  url: '/sellers',
  method: 'get',
  params
});
exports.getSellers = getSellers;
const getSellerById = id => (0, _request.default)({
  url: `/sellers/${id}`,
  method: 'get'
});
exports.getSellerById = getSellerById;
const updateSeller = (id, data) => (0, _request.default)({
  url: `/sellers/${id}`,
  method: 'patch',
  data
});
exports.updateSeller = updateSeller;
const deleteSeller = id => (0, _request.default)({
  url: `/sellers/${id}`,
  method: 'delete'
});
exports.deleteSeller = deleteSeller;
const createSeller = data => (0, _request.default)({
  url: '/sellers/',
  method: 'post',
  data
});
exports.createSeller = createSeller;