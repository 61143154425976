"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _address = require("@/api/address");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _contacts = require("@/api/contacts");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.address = '';
    this.contactQuery = {
      userId: 0,
      filter: {
        userId: 'eq'
      }
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      name: undefined,
      userId: 0,
      contactId: 0,
      enabled: undefined,
      sort: 'id,ASC',
      filter: {
        name: '$startsL',
        enabled: 'eq',
        userId: 'eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    if (this.$route.params) {
      if (this.$route.params.type) {
        switch (this.$route.params.type) {
          case 'Customer':
            this.contactQuery.userId = parseInt(this.$route.params.id);
            this.getContact();
            // this.listQuery.contactId = 0
            // jeff fetch contact for this user and set its id in listquery
            break;
          default:
            break;
        }
      } else {
        this.listQuery.userId = parseInt(this.$route.params.id);
      }
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _address.getAddresses)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getContact() {
    try {
      const contactData = await (0, _contacts.getContacts)((0, _index2.getQuery)(this.contactQuery));
      this.listQuery.contactId = contactData[0].id;
      this.getList();
    } catch (error) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'fullname', 'email', 'contact', 'role'];
    const filterVal = ['id', 'fullName'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AddressList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;