"use strict";

require("./404");
require("./active-stone-in-cart");
require("./admin-new");
require("./admin");
require("./agent");
require("./almond");
require("./auction");
require("./back-top");
require("./banner");
require("./battery");
require("./bug");
require("./building");
require("./chart");
require("./clarity");
require("./clipboard");
require("./color");
require("./component");
require("./coupon");
require("./customer");
require("./dashboard-new");
require("./dashboard");
require("./diamond  fluorescence");
require("./diamond-cut");
require("./diamond-fluorescence");
require("./diamond-polish");
require("./diamond-products");
require("./diamond-symmetry");
require("./diamondSize");
require("./documentation");
require("./dollar");
require("./drag");
require("./edit");
require("./education");
require("./email");
require("./example");
require("./excel");
require("./exit-fullscreen");
require("./eye-off");
require("./eye-on");
require("./faq-new");
require("./faq");
require("./finish");
require("./form");
require("./fullscreen");
require("./globe");
require("./guide-2");
require("./guide");
require("./hamburger");
require("./hp");
require("./icon");
require("./inr");
require("./international");
require("./language");
require("./like");
require("./link");
require("./list");
require("./listed-stones");
require("./location");
require("./lock");
require("./marketing-new");
require("./marketing");
require("./master");
require("./message");
require("./money");
require("./nested");
require("./Order");
require("./orders-number");
require("./password");
require("./pdf");
require("./people");
require("./peoples");
require("./Price-not-enterted");
require("./product-management");
require("./product-new");
require("./product");
require("./qq");
require("./riyal");
require("./sack");
require("./scale");
require("./search");
require("./seller-management");
require("./series");
require("./setting");
require("./shape");
require("./shopping");
require("./size");
require("./skill");
require("./star");
require("./stone-in-cart");
require("./stone-ordered");
require("./tab");
require("./table");
require("./tag");
require("./tax");
require("./theme");
require("./tree-table");
require("./tree");
require("./trend-down");
require("./trend-up");
require("./user");
require("./wechat");
require("./zip");