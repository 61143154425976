"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "dashboard-executive-container"
  }, [_c("div", [_c("panel-group", {
    key: _vm.dateRange,
    attrs: {
      "date-range": _vm.dateRange
    }
  })], 1), _c("el-row", {
    attrs: {
      gutter: 32
    }
  }, [_c("el-col", {
    attrs: {
      xs: 14,
      sm: 14,
      lg: 14
    }
  }, [_c("sales-chart", {
    key: _vm.dateRange,
    attrs: {
      "date-range": _vm.dateRange
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;