"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _taxSubMenu = require("./subMenus/masters/tax-sub-menu");
var _locationSubMenu = require("./subMenus/masters/location-sub-menu");
var _settingSubMenu = _interopRequireDefault(require("./subMenus/masters/setting-sub-menu"));
var _bannerSubMenu = _interopRequireDefault(require("./subMenus/masters/banner-sub-menu"));
var _menusSubMenu = require("./subMenus/masters/menus-sub-menu");
var _staticPagesSubMenu = require("./subMenus/masters/static-pages-sub-menu");
const masterRouter = {
  path: "/masters",
  component: _index.default,
  redirect: "noredirect",
  name: "Masters",
  meta: {
    title: "master.title",
    icon: "master"
  },
  children: [..._bannerSubMenu.default, ..._settingSubMenu.default, ..._taxSubMenu.taxSubMenu, ..._locationSubMenu.locationSubMenu, ..._menusSubMenu.menusRouter,
  // ...mediasRouter,
  // ...homePagesRouter,
  // ...homeSections1Router,
  // ...homeSections2Router,
  // ...homeSections3Router,
  // ...trendingOffers1Router,
  ..._staticPagesSubMenu.staticPagesRouter]
};
var _default = exports.default = masterRouter;